import React, { CSSProperties, FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';

interface PLoader {
  transparent?: boolean;
  white?: boolean;
  grey?: boolean;
  style?: CSSProperties;
  width?: number;
  height?: number;
  radius?: number;
  stroke?: number;
}

const Loader: FC<PLoader> = ({
  transparent,
  white,
  style,
  grey,
  width = 34,
  height = 34,
  radius = 10.5,
  stroke = 3,
}) => (
  <div
    className={cn(css.loader, 'Loader', {
      _isTransparent: transparent,
      _isWhite: white,
      _isGrey: grey,
    })}
    style={{ ...style, width: `${width}px`, height: `${height}px` }}
  >
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r={radius}
        transform="matrix(0.798509 0.601982 0.601982 -0.798509 16.8549 16.8549)"
        strokeWidth={stroke}
      />
    </svg>
  </div>
);

export default Loader;
