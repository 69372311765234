import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import { FiSearch, FiX } from 'react-icons/fi';
import AccessUserRight from 'components/AccessUserRight';
import Button from 'components/UI/Buttons/buttons';
import ElementsNone from 'components/ElementsNone';
import TableFilter from 'components/UI/TableFilter';
import { RouteComponentProps } from 'react-router';
import PreloaderList from 'components/Preloaders/List';
import Filter from 'components/Filter';
import { useTranslation } from 'react-i18next';
import NavigationHeader from 'components/NavigationHeader';
import { Element, scroller } from 'react-scroll';
import css from './styles.module.scss';
import { ReactComponent as FilterSvg } from './icons/filters.svg';

import { useHighlights } from '../../../utils/useLastIdHelpers/useLastIdContract';
import { useContractsData } from '../model/hooks/useContractsData';
import Item from './Item';

type Props = RouteComponentProps;

const Contracts: FC<Props> = ({ ...params }) => {
  const { t } = useTranslation();
  const { isThemeExtended, isThemeFull } = useUserTheme();

  const {
    currentTab,
    contractsView,
    searchRef,
    searchWrapperRef,
    applyContractFilterHandler,
    clearContractFilter,
    currentTabHandler,
    toggleSearch,
    onOpenSearch,
    onChangeSearch,
    onCloseSearch,
    isLoadingContractsList,
    contractsFilters,
    setContractsFilters,
    search,
  } = useContractsData();

  const lastId = useHighlights();

  useEffect(() => {
    setTimeout(() => {
      scroller.scrollTo(lastId, {
        duration: 0,
        delay: 0,
        smooth: false,
        offset: -400,
      });
    }, 1000);
  }, [lastId, currentTab]);

  return (
    <div className={css.contractors}>
      <Helmet>
        <title>{t(`ord.contracts_title`)}</title>
      </Helmet>
      <NavigationHeader>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'ACTIVE',
          })}
          onClick={() => currentTabHandler('ACTIVE')}
          disabled={false}
        >
          {t(`agencies_page.agencies_active`)}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'ON_APPROVAL',
          })}
          onClick={() => currentTabHandler('ON_APPROVAL')}
          disabled={false}
        >
          {t(`ord.contracts.in_moderation`)}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'DELETED',
          })}
          onClick={() => currentTabHandler('DELETED')}
          disabled={false}
        >
          {t(`campaigns_page.campaigns_black_header_main.del`)}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'ALL',
          })}
          onClick={() => currentTabHandler('ALL')}
          disabled={false}
        >
          {t(`targetings.all`)}
        </button>
      </NavigationHeader>
      <div
        className={cn(css.header, {
          isToggleSearch: toggleSearch,
        })}
      >
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div className={css.status}>
            <h1 className={css.title}>{t(`ord.contracts_title`)}</h1>
            <div className={css.actions}>
              <div
                ref={searchWrapperRef}
                className={cn(css.search, {
                  isActive: toggleSearch,
                })}
              >
                <div className={css.icon} onClick={onOpenSearch}>
                  <FiSearch size={24} />
                </div>
                <div className={css.input}>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t(`ord.contract_search`)}
                    value={search}
                    onChange={onChangeSearch}
                    ref={searchRef}
                  />
                </div>

                <div className={css.close} onClick={onCloseSearch}>
                  <FiX size={24} />
                </div>
              </div>
              <Filter
                filtersLength={
                  Object.values(contractsFilters).filter((item) =>
                    Boolean(item),
                  ).length - 1
                }
                setContractsFilters={setContractsFilters}
                applyContructorFilters={applyContractFilterHandler}
                clearContractFilter={clearContractFilter}
                contractsFilters={contractsFilters}
              />
              <AccessUserRight>
                {({ isDisable }) => (
                  <Button
                    title={t(`ord.contract_create`)}
                    buttonAttributes={{
                      disabled: isDisable,
                      style: {
                        height: 40,
                      },
                      onClick: () =>
                        params.history.push({
                          pathname: `contract/create`,
                        }),
                    }}
                  />
                )}
              </AccessUserRight>
            </div>
          </div>
        </div>
      </div>
      {contractsView?.length === 0 ? (
        <ElementsNone
          type="contracts"
          search={
            !!(search || contractsFilters.executor || contractsFilters.customer)
          }
          status={currentTab}
        />
      ) : (
        <div className={css.content}>
          <div
            className={cn('container resized', {
              extend: isThemeExtended,
              full: isThemeFull,
            })}
          >
            <div className={css.table}>
              <div className={css.table_header}>
                <div className={css.filter_header}>
                  <FilterSvg />
                </div>
                <TableFilter
                  data=""
                  title={t(`containers_page.name`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title={t(`ord.date`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title={t(`ord.customer`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title={t(`ord.contractor`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title={t(`ord.title`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title="статус ЕРИР"
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <div className={css.dropdown} />
              </div>

              {isLoadingContractsList && <PreloaderList type="contracts" />}
              {!isLoadingContractsList && (
                <div className={css.table_body}>
                  {contractsView?.map((contract) => (
                    <Element name={contract.uuid} key={contract.uuid}>
                      <Item contractData={contract} isLastEdited={lastId === contract.uuid}/>
                    </Element>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contracts;
