import copy from 'copy-to-clipboard';
import React, { FC, useState } from 'react';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import cn from 'classnames';
import i18n from '../../i18n';

interface InternalIdProp {
  internal_id: string;
}

const InternalId: FC<InternalIdProp> = ({ internal_id }) => {
  const [copied, setCopied] = useState(false);
  const handlerCopy = () => {
    copy(internal_id);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <span
      className={cn({
        copied,
      })}
      onClick={handlerCopy}
    >
      {!copied && `[${internal_id}]`}
      {copied && (
        <>
          <TooltipPoratal hint={i18n.t('alerts.copied')} showConstantly>
            {`[${internal_id}]`}
          </TooltipPoratal>
        </>
      )}
    </span>
  );
};

export default InternalId;
