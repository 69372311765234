import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'components/UI/Card';
import StatiscticFields from 'components/StatisticFields';
import CollapseCard from 'components/UI/CollapseCard';
import css from './styles.module.scss';

const Statistics: FC = () => {
  const { t } = useTranslation();

  return (
    <Card className={css.card} noHover isUseAccess>
      <CollapseCard
        noUpdateHeight
        title={
          <h3>
            {t('campaigns_page.campaign_settings.additional.statistics.title')}
          </h3>
        }
      >
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <StatiscticFields
            descriptionText={t(
              'campaigns_page.campaign_settings.additional.statistics.show_columns',
            )}
            mode="campaign"
          />
        </div>
      </CollapseCard>
    </Card>
  );
};

export default Statistics;
