import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import RSelect from 'react-select';
import ErrorText from 'components/UI/ErrorText';
import './styles.scss';
import LabelField from 'components/UI/LabelField';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import css from './styles.module.scss';

interface OptionTypeBase {
  label: string;
  value: any;
}

interface Props {
  label: string;
  isError?: boolean;
  errorText?: string;
  hideIcon?: boolean;
  isDisabled?: boolean;
  allData?: {
    label: string;
    additional: string;
    value: string;
  }[];
  onChangeHandler: (val: OptionTypeBase | null) => void;
  value: string;
  placeholder: string;
  selectOptions: {
        label: string;
        additional: string;
        value: string;
      }[]
    | undefined;
}

export default function Autocomplete({
  label,
  value,
  isError,
  allData,
  errorText,
  isDisabled,
  selectOptions,
  hideIcon = true,
  onChangeHandler,
  placeholder,
}: Props): JSX.Element {
  const { t } = useTranslation();

  const inputVal = useRef('');

  const [filteredOptions, setFilteredOptions] = useState<
    typeof selectOptions
  >([]);

  const handleInputChange = useCallback(
    debounce((input: string) => {
      if (!selectOptions) return;
      if (input) {
        const filtered = selectOptions.filter(
          (option) =>
            option.label.toLowerCase().includes(input.toLowerCase()) ||
            option.additional?.toLowerCase().includes(input.toLowerCase()),
        );
        setFilteredOptions(filtered);
      } else {
        setFilteredOptions([]);
      }
    }, 1000),
    [selectOptions],
  );

  const componentsSelect: {
    IndicatorSeparator: FC;
    DropdownIndicator: FC;
    Menu?: FC;
  } = useMemo(() => {
    if ((filteredOptions?.length && filteredOptions?.length > 0) || inputVal.current) {
      return {
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
      };
    }
    return {
      IndicatorSeparator: () => null,
      DropdownIndicator: () => null,
      Menu: () => null,
    };
  }, [filteredOptions?.length, inputVal.current]);

  return (
    <div className={css.autocomplete}>
      <LabelField label={label}>
        <RSelect
          placeholder={placeholder}
          classNamePrefix="autocomplete_select"
          isDisabled={isDisabled}
          value={(allData?.length ? allData : filteredOptions || []).find(
            (opt) => opt.value === value,
          )}
          onChange={onChangeHandler}
          isSearchable
          isClearable
          options={filteredOptions}
          filterOption={null}
          onInputChange={(val) => {
            handleInputChange(val);
            inputVal.current = val;
          }}
          noOptionsMessage={() => t(`no_result`)}
          components={componentsSelect}
        />
      </LabelField>

      {isError && (
        <div className={css.autocomplete_error}>
          <ErrorText hideIcon={hideIcon} text={errorText} />
        </div>
      )}
    </div>
  );
}
