import React, { FC } from 'react';
import Card from 'components/UI/Card';
import { FiAlertCircle } from 'react-icons/fi';
import Tooltip from 'components/UI/Tooltip';
import ErirInfo from 'components/ErirInfo';
import { FormikProps } from 'formik';
import { ContractFormikProps } from 'domains/contract/model/types';
import { useContractData } from 'domains/contract/model/selectors';
import { useTranslation } from 'react-i18next';
import { OrdList } from 'domains/counteragents/model/types';
import css from '../styles.module.scss';

interface Props {
  mode: 'create' | 'edit';
  ordList: OrdList | null;
  formik?: FormikProps<ContractFormikProps>;
  externalId?: string;
}

const Info: FC<Props> = ({ mode, formik, externalId, ordList }) => {
  const { t } = useTranslation();
  const { data: contractData } = useContractData();
  return (
    <Card isUseAccess className={css.card} noHover>
      <div className={css.info}>
        {mode === 'edit' ? (
          <div className={css.info_block}>
            <div className={css.info_title}>{t(`ord.contact_info`)}</div>
            <div className={css.info_subtitle}>{externalId}</div>
            <div className={css.info_erir}>
              <ErirInfo
                erirStatus={contractData?.erirStatus || null}
                erirExportedOn={contractData?.erirExportedOn}
              />
            </div>
          </div>
        ) : (
          <div className={css.info_title}>{t(`ord.contract_info`)}</div>
        )}

        <div className={css.info_target}>
          <div className={css.string_wrapper}>
            <strong>{t(`ord.registered`)}</strong>
            <span>
              {(formik?.values.ord && ordList?.find(({ id }) => id === formik.values.ord)?.title) ||
                t(`ord.unknow_ord`)}
            </span>
          </div>
        </div>
        {mode === 'edit' && contractData?.finalContract && (
          <div className={css.info_block}>
            <div className={css.info_income}>
              <div className={css.string_wrapper}>
                <div className={css.string}>
                  <Tooltip title={<FiAlertCircle size={16} />} white>
                    {t(`ord.financial_closure`)}
                  </Tooltip>
                  <strong>{t(`ord.revenue_contract`)}</strong>
                </div>
                <span>{contractData?.finalContract}</span>
              </div>
            </div>
          </div>
        )}
        <div className={css.info_block}>
          {mode === 'edit' && contractData?.parent && (
            <div className={css.info_parent}>
              <div className={css.string_wrapper}>
                <div className={css.string}>
                  <Tooltip
                    title={<FiAlertCircle size={16} />}
                    white
                    hintWidth={250}
                  >
                    {t(`ord.original_agreement`)}
                  </Tooltip>
                  <strong>{t(`ord.parental_contract`)}</strong>
                </div>
                <span>{contractData?.parent}</span>
              </div>
            </div>
          )}

          <div className={css.info_check}>
            <div className={css.checkbox}>
              <span>{t(`ord.original_contract`)}</span>
            </div>
          </div>
        </div>
      </div>
    </Card> 
  );
};

export default Info;
