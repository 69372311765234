import { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { setIsShowAllComments } from 'domains/comment/model/reducer';
import { TComment, TCommentComponent } from 'domains/comment/model/types';
import {
  useCommentDeletedInfo,
  useCommentEditedInfo,
  useCommentIsAllCommentInfo,
} from 'domains/comment/model/selectors';
import { useDispatchApp } from 'redux/rootSelectors';
import { useOuterElementClick } from 'hooks/useOuterElementClick';
import { useIsGroup } from 'domains/user/hooks';
import {
  helperCommentPosition,
  helperCommentTransform,
  helperHeightText,
} from '../helper';
import { delayUpdateComment } from '../../../campaign/model/actions';

type TFUseComments = (props: {
  comments: TComment[];
  LTU: number;
  handleDeleteComment: (comment_id: number, callback: () => void) => void;
  email: string;
}) => {
  isModal: boolean;
  typeModal: 'add' | 'edit';
  addComment: () => void;
  editComment: (id: number) => void;
  onCloseModal: () => void;
  commentID: RefObject<number>;
  editedCommentID: ReturnType<typeof useCommentEditedInfo>;
  commentList: TCommentComponent[];
  commentCollapse: (isCollapse: boolean, id: number) => void;
  isModalDelete: boolean;
  onCloseModalDelete: () => void;
  onOpenModalDelete: () => void;
  search: string;
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  onOpenSearch: () => void;
  onCloseSearch: () => void;
  toggleSearch: boolean;
  isEdited: (created_time: string, edited_time: string) => boolean;
  timeGen: (created_time: string) => string;
  deletedCommentID: ReturnType<typeof useCommentDeletedInfo>;
  handlerIsAllComments: (v: boolean) => void;
  isShowAllComments: ReturnType<typeof useCommentIsAllCommentInfo>;
  searchInputElement: RefObject<HTMLInputElement>;
  updateTimeoutCommentList: (id: number, type: 'edited' | 'deleted') => void;
  onHandleDeleteComment: () => void;
  isShowBtnAllComment: () => boolean;
  heightBOX: () => number;
  countSearch: RefObject<number>;
  wrapperRef: RefObject<HTMLDivElement>;
  /** Блокировка если нет доступа */
  isLocked?: boolean;
};

const TIME_FORMAT = 'DD.MM.YYYY, HH:mm';

export const useComments: TFUseComments = ({
  comments,
  LTU,
  handleDeleteComment,
  email,
}) => {
  /* Сколько по умолчанию видно строк */
  const BASIC_LIMIT_ROW = 5;
  /* Максимальная высота виджета */
  const MAX_SIZE_BOX = 662;

  const { isLocked } = useIsGroup();
  const dispatch = useDispatchApp();
  const editedCommentID = useCommentEditedInfo();
  const deletedCommentID = useCommentDeletedInfo();
  const isShowAllComments = useCommentIsAllCommentInfo();

  const [commentList, setCommentList] = useState<TCommentComponent[]>(() =>
    helperCommentPosition(
      helperCommentTransform(comments, deletedCommentID, email),
    ),
  );

  useEffect(() => {
    setCommentList(() =>
      helperCommentPosition(
        helperCommentTransform(comments, deletedCommentID, email),
      ),
    );
  }, [LTU]);

  const commentID = useRef<number | null>(null);
  const [isModal, setIsModal] = useState(false);
  const [typeModal, setTypeModal] =
    useState<ReturnType<TFUseComments>['typeModal']>('add');
  const [isModalDelete, setModalDelete] = useState(false);
  const searchInputElement = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>('');
  const [toggleSearch, setToggleSearch] = useState(false);
  const countSearch = useRef<number>(0);

  const onCloseModalDelete: ReturnType<TFUseComments>['onCloseModalDelete'] =
    () => {
      setModalDelete(false);
    };
  const onOpenModalDelete: ReturnType<TFUseComments>['onOpenModalDelete'] =
    () => {
      setModalDelete(true);
    };

  const onSearch: ReturnType<TFUseComments>['onSearch'] = ({ target }) => {
    setSearch(target.value);
  };

  const onOpenSearch = () => {
    setToggleSearch(true);
    searchInputElement.current?.focus();
  };

  const onCloseSearch = () => {
    setToggleSearch(false);
    setSearch('');
  };

  const addComment: ReturnType<TFUseComments>['addComment'] = () => {
    setIsModal(true);
    setTypeModal('add');
  };

  const editComment: ReturnType<TFUseComments>['editComment'] = (id) => {
    setIsModal(true);
    setTypeModal('edit');
    commentID.current = id;
  };

  const onCloseModal: ReturnType<TFUseComments>['onCloseModal'] = () => {
    setIsModal(false);
  };

  const commentCollapse: ReturnType<TFUseComments>['commentCollapse'] = (
    isCollapse,
    id,
  ) => {
    setCommentList((d) => {
      const copy = [...d];
      const index = d.findIndex((el) => el.id === id);
      const temp = d[index];
      let newHeight = 73;
      if (!isCollapse) {
        newHeight += helperHeightText(temp.text);
      } else {
        newHeight += 66;
      }
      copy[index] = { ...temp, isCollapse: !isCollapse, height: newHeight };
      return helperCommentPosition(copy);
    });
  };

  const isEdited: ReturnType<TFUseComments>['isEdited'] = (
    created_time,
    edited_time,
  ) =>
    moment(created_time).format(TIME_FORMAT) !==
    moment(edited_time).format(TIME_FORMAT);

  const timeGen: ReturnType<TFUseComments>['timeGen'] = (created_time) =>
    moment(created_time).format(TIME_FORMAT);

  useEffect(() => {
    if (search) {
      setCommentList(() =>
        helperCommentPosition(
          helperCommentTransform(
            comments.filter(
              ({ text, user_email }) =>
                text.toLowerCase().includes(search.toLowerCase()) ||
                user_email.toLowerCase().includes(search.toLowerCase()),
            ),
            deletedCommentID,
            email,
          ),
        ),
      );
      countSearch.current = comments.filter(
        ({ text, user_email }) =>
          text.toLowerCase().includes(search.toLowerCase()) ||
          user_email.toLowerCase().includes(search.toLowerCase()),
      ).length;
    } else {
      setCommentList(() =>
        helperCommentPosition(
          helperCommentTransform(comments, deletedCommentID, email),
        ),
      );
      countSearch.current = 0;
    }
  }, [search]);
  const handlerIsAllComments: ReturnType<TFUseComments>['handlerIsAllComments'] =
    (v) => {
      dispatch(setIsShowAllComments(v));
    };

  const updateTimeoutCommentList: ReturnType<TFUseComments>['updateTimeoutCommentList'] =
    (id, type) => {
      dispatch(delayUpdateComment({ comment_id: id, type }));
    };

  const onHandleDeleteComment = () => {
    if (commentID.current) {
      handleDeleteComment(commentID.current, () => {
        onCloseModalDelete();
        onCloseModal();
        updateTimeoutCommentList(Number(commentID.current), 'deleted');
      });
    }
  };
  const heightBOX: ReturnType<TFUseComments>['heightBOX'] = () => {
    if (comments.length > BASIC_LIMIT_ROW) {
      if (isShowAllComments) {
        return MAX_SIZE_BOX;
      }
      return commentList.reduce(
        (sum, el, index) => (index < BASIC_LIMIT_ROW ? sum + el.height : sum),
        0,
      ) > MAX_SIZE_BOX
        ? MAX_SIZE_BOX
        : commentList.reduce(
          (sum, el, index) => (index < BASIC_LIMIT_ROW ? sum + el.height : sum),
          0,
        );
    }

    return commentList.reduce((sum, el) => sum + el.height, 0) > MAX_SIZE_BOX
      ? MAX_SIZE_BOX
      : commentList.reduce((sum, el) => sum + el.height, 0);
  };

  const isShowBtnAllComment = () => {
    if (!comments.length) {
      return true;
    }
    if (search && commentList.length <= BASIC_LIMIT_ROW && comments.length) {
      return false;
    }
    return comments.length > BASIC_LIMIT_ROW;
  };

  useEffect(() => {
    setCommentList(() =>
      helperCommentPosition(
        helperCommentTransform(comments, deletedCommentID, email),
      ),
    );
  }, [deletedCommentID]);

  const { wrapperRef } = useOuterElementClick({
    isShow: toggleSearch,
    closeModal: () => {
      onCloseSearch();
    },
  });

  return {
    isModal,
    typeModal,
    editComment,
    addComment,
    onCloseModal,
    commentID,
    commentList: isShowAllComments
      ? commentList
      : commentList.filter((v, index) => index < BASIC_LIMIT_ROW),
    commentCollapse,
    isModalDelete,
    onCloseModalDelete,
    onOpenModalDelete,
    search,
    onSearch,
    onCloseSearch,
    onOpenSearch,
    toggleSearch,
    isEdited,
    timeGen,
    editedCommentID,
    deletedCommentID,
    handlerIsAllComments,
    isShowAllComments,
    searchInputElement,
    updateTimeoutCommentList,
    onHandleDeleteComment,
    isShowBtnAllComment,
    heightBOX,
    countSearch,
    wrapperRef,
    isLocked,
  };
};
