import Code from 'components/UI/Code';
import { getSizes } from 'domains/uploads/helpers';
import UploadImage from 'domains/uploads/UploadImage';
import { Field, FieldProps } from 'formik';
import React, { FC } from 'react';
import UploadZip from 'domains/uploads/UploadZip';
import { checkSizes } from 'domains/creative/creatives/creativeAdd/validate';
import { animated } from 'react-spring';
import PriceInput from 'components/UI/PriceInput';
import { TTypeHookProps } from 'domains/creative/types';
import { SelectField } from 'types/general';
import Input from 'components/UI/Input/input';
import EridWarning from 'components/EridWarning';
import NewSelect from 'components/UI/NewSelect';
import { Trans } from 'react-i18next';
import i18n from '../../../../../../../../i18n';
import css from '../../../styles.module.scss';
import { IUseTypeProp } from '../../../../hooks/useType';

type THtmlProp = IUseTypeProp &
  TTypeHookProps & {
    handlerCopyHtml: () => void;
    sizeOption: SelectField[];
    setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
    ord_registration?: boolean;
  };

const index: FC<THtmlProp> = ({
  formik: { values, setFieldValue, errors, validateForm, handleChange },
  defaultSizeOption,
  handlerCopyHtml,
  setIsDisableSaveButton,
  sizeOption,
  showBlock,
  erid,
  setToggle,
  ord_registration,
}) => (
  <div className={css.form}>
    <UploadImage
      keyUploadFile="templateImage"
      preview={getSizes(values.data.imgMask, values.data.imgFormats)}
      name="imgMask"
      setFieldValue={setFieldValue}
      validateForm={validateForm}
      label={i18n.t('creative.creasmall_set.image_word')}
      customLinkTitle={i18n.t('creative.creasmall_set.linktoimage_word')}
      hintText={
        <span>
          {i18n.t('creative.creasmall_set.html_format1')}
          <br />
          {i18n.t('creative.creasmall_set.html_format2')}
        </span>
      }
      hasEdit
      edit
      isData
      minResolution={10}
      file_ext={['.png', '.jpeg', '.jpg']}
      typeSection="templateImage"
      addTabLabel={i18n.t(`creative.create_creative.Add_archive`)}
      addTabContent={
        <UploadZip
          isData
          name="zip"
          hintText={i18n.t('creative.create_creative.Supported_format')}
          label={i18n.t('creative.create_creative.File')}
          sizes={[
            '160x600',
            '240x400',
            '300x250',
            '300x600',
            '320x480',
            '320x50',
            '336x280',
            '480x320',
            '580x400',
            '728x90',
            '970x250',
            '970x90',
            i18n.t('creative.create_creative.Set_sizes'),
          ]}
          setFieldValue={setFieldValue}
          validateForm={validateForm}
          inputAttributes={{
            name: 'zip',
          }}
          type="zip"
        />
      }
    />
    <Field name="data.html_code">
      {({ field, form, meta }: FieldProps) => (
        <Code
          handlerCopy={handlerCopyHtml}
          label={i18n.t('creative.create_creative.HTML_Code')}
          error={errors.data && !!errors.data.html_code}
          maxCount={4096}
          valueCount={values.data.html_code?.length}
          errorText={errors.data && errors.data.html_code}
          field={field}
          form={form}
          meta={meta}
        />
      )}
    </Field>
    {ord_registration && (
      <Input
        label={i18n.t(`creative.general_description_label`)}
        isTextarea
        tooltip={{
          text: (
            <Trans
              i18nKey="creative.general_description_hint"
              components={{ br: <br /> }}
            />
          ),
          colorIcon: '#222222',
          sizeIcon: 20,
        }}
        showValueCount
        error={!!errors.data?.description}
        errortext={errors.data?.description}
        inputAttributes={{
          name: 'data.description',
          placeholder: i18n.t(`creative.general_description_place`),
          maxLength: 1024,
          value: values.data.description,
          onChange: handleChange
        }}
      />
    )}
    <Field name="size">
      {({ form, field }: FieldProps) => (
        <NewSelect
          label={i18n.t('creative.create_creative.Size_px')}
          form={form}
          field={field}
          selectAttributes={{
            name: 'size',
            placeholder: i18n.t('creative.create_creative.Please_select'),
            options: sizeOption,
            value: defaultSizeOption(`${values.size}`),
            onChange: () => {
              setToggle(checkSizes(values.size));
              setFieldValue('size', values.size);
            },
          }}
          viewStyle="Vertical"
        />
      )}
    </Field>
    <animated.div style={showBlock} className={css.sizes}>
      <PriceInput
        classname={css.sizes_col}
        hasZero
        decimalScale={0}
        label={`${i18n.t('creative.create_creative.Width')}, PX`}
        placeholder={i18n.t('creative.create_creative.Width')}
        name="width"
        error={errors.width}
        errortext={errors.width}
        value={+values.width}
        onValueChange={({ floatValue }) => {
          if (!floatValue) {
            setFieldValue('width', 1);
            return;
          }
          setFieldValue('width', floatValue);
        }}
      />
      <PriceInput
        hasZero
        classname={css.sizes_col}
        decimalScale={0}
        label={`${i18n.t('creative.create_creative.Height')}, PX`}
        placeholder={i18n.t('creative.create_creative.Height')}
        name="height"
        error={errors.height}
        errortext={errors.height}
        value={+values.height}
        onValueChange={({ floatValue }) => {
          if (!floatValue) {
            setFieldValue('height', 1);
            return;
          }
          setFieldValue('height', floatValue);
        }}
      />
    </animated.div>
    {erid && (
      <>
        <Input
          label={erid.title}
          showValueCount
          error={!!errors.erid}
          errortext={errors.erid}
          inputAttributes={{
            name: 'erid',
            placeholder: erid.title,
            maxLength: 80,
            value: values.erid,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('erid', e.target.value.trim()),
          }}
        />
        {values.erid && (
          <EridWarning
            withAccept
            isChangeErid
            setIsDisableSaveButton={setIsDisableSaveButton}
          />
        )}
      </>
    )}
  </div>
);

export default index;
