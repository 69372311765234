import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import CardsList from 'components/UI/CardList';
import Card from 'components/UI/Card';
import Help from 'components/Help';
import { useDispatchApp } from 'redux/rootSelectors';
import { featchAllCreativeTypesAction } from 'domains/creative/reduser';
import {
  useCreativeDetailInfo,
  useCreativeTypes,
} from 'domains/creative/model/selectors';
import ExperimentalInputField from 'components/ExperimentalInputField';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';

const Experimental: FC = () => {
  const { t } = useTranslation();
  const { data: typesData } = useCreativeTypes();
  const { data: creativeDetail } = useCreativeDetailInfo();

  const experimentalInfo = typesData?.find(
    (typeData) => typeData.key === creativeDetail?.type,
  )?.config_fields;

  const dispatch = useDispatchApp();

  useEffect(() => {
    dispatch(featchAllCreativeTypesAction());
  }, []);

  const fieldsDataArr =
    experimentalInfo &&
    Object.values(experimentalInfo).filter(
      (fieldData) => fieldData.page === 'experimental',
    );

  return (
    <>
      <div className={cn(css.container, 'container')}>
        <CardsList>
          <Card noHover isUseAccess>
            {fieldsDataArr?.length ? (
              fieldsDataArr.map(
                (field) =>
                  field.type !== 'select' && (
                    <div
                      className={cn(css.item, {
                        _isPriceHorisontal: field.type === 'float',
                      })}
                      key={field.id}
                    >
                      <ExperimentalInputField
                        item={field}
                        mode="creo"
                        config_values={creativeDetail?.config_values}
                        xxhash={creativeDetail?.xxhash}
                      />
                      {field.description !== '' && (
                        <Help
                          title=""
                          helpattributes={{
                            text: field.description,
                          }}
                        />
                      )}
                    </div>
                  ),
              )
            ) : (
              <p className={css.text}>
                {t(`campaigns_page.emptyArrayAdvancedSettings`)}
              </p>
            )}
          </Card>
        </CardsList>
        <CardsList>
          <Card noHover isUseAccess>
            <p className={css.text}>
              Для пользователей с правами администратора доступен раздел с
              широким спектром настроек с целью проведения тестов.
            </p>
          </Card>
        </CardsList>
      </div>
    </>
  );
};

export default Experimental;
