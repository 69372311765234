/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useMemo } from 'react';
import Card from 'components/UI/Card';
import LabelField from 'components/UI/LabelField';
import MultiSelectCheckbox from 'components/UI/MultiSelectCheckbox';
import { useCounteragentAllList } from 'domains/counteragents/model/selectors';
import { FormikProps } from 'formik';
import { ContractFormikProps } from 'domains/contract/model/types';
import Autocomplete from 'components/Autocomplete';
import { useClientsInfo } from 'domains/clients/model/selectors';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import cn from 'classnames';
import css from '../styles.module.scss';
import optioncss from "../../../../../components/UI/MultiSelectCheckbox/Option/styles.module.scss";

interface ICounteragentsProps {
  formik: FormikProps<ContractFormikProps>;
  handleChange: (
    name: string,
    value: string | number | boolean | undefined,
  ) => void;
  mode?: 'edit' | 'create';
}

const Counteragents: FC<ICounteragentsProps> = ({
  formik,
  mode,
  handleChange,
}) => {
  const { data: counteragentsList } = useCounteragentAllList();
  const { t } = useTranslation();

  const counteragentOptions = useMemo(
    () =>
      counteragentsList
        ?.filter(({ ord }) => ord === formik.values.ord)
        .map(({ name, inn, uuid }) => ({
          label: `${name}${inn ? ` · ${inn}` : ''}`,
          additional: inn,
          value: uuid,
        })),
    [counteragentsList],
  );

  const { data: clientsList } = useClientsInfo();
  const clientsOptions = useMemo(
    () => clientsList?.map(({ title, id }) => ({ label: title, value: id })),
    [clientsList],
  );

  const clientOption: FC = (props: any) => (
    <components.Option {...props}>
      <input
        className={optioncss.input}
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
      />
      <label className={cn(optioncss.label, optioncss.double)}>
        <span className={optioncss.main} title={props.label}>{props.label}</span>
        <span className={optioncss.hash}>{props.value}</span>
      </label>
    </components.Option>
  );

  return (
    <Card isUseAccess className={css.card} noHover>
      <h3 className={css.title}>{t(`ord.сounterparties_title`)}</h3>
      <LabelField label={t(`ord.clients`)}>
        <MultiSelectCheckbox
          fieldName="client_xxhash_list"
          customHandleChange={handleChange}
          formik={formik}
          propsValues={clientsOptions || []}
          placeholder={t(`project_page.forms.not_selected`)}
          isError={!!formik.errors.client_xxhash_list}
          errorText={formik.errors.client_xxhash_list}
          customOption={clientOption}
        />
      </LabelField>
      <Autocomplete
        label={`${t(`ord.customer`)} *`}
        allData={counteragentOptions || []}
        value={formik.values.customer}
        isDisabled={mode === 'edit'}
        onChangeHandler={(val) => handleChange('customer', val?.value)}
        isError={!!formik.errors.customer}
        errorText={formik.errors.customer}
        placeholder={t(`ord.inn_name`)}
        selectOptions={counteragentOptions}
      />
      <Autocomplete
        label={`${t(`ord.contractor`)}*`}
        allData={counteragentOptions}
        value={formik.values.executor}
        isDisabled={mode === 'edit'}
        onChangeHandler={(val) => handleChange('executor', val?.value)}
        isError={!!formik.errors.executor}
        errorText={formik.errors.executor}
        placeholder={t(`ord.inn_name`)}
        selectOptions={counteragentOptions}
      />
    </Card>
  );
};

export default Counteragents;
