import React, { FC } from 'react';
import cn from 'classnames';
import Card from 'components/UI/Card';
import Load from 'components/UI/Load';
import { useUserTheme } from 'domains/user/model/selectors';
import { ScrollSyncNode } from 'scroll-sync-react';
import { IFilterColumns } from 'domains/campaigns/model/hooks/useGetFilterForCampaigns';
import Param from './Params';
import css from './styles.module.scss';

type Props = {
  header?: boolean;
  filterColumns?: IFilterColumns[];
  type:
    | 'campaign'
    | 'agency'
    | 'client'
    | 'creative'
    | 'partner'
    | 'user'
    | 'usersApi'
    | 'offer'
    | 'contracts'
    | 'counteragents'
    | 'history';
};

const PreloaderString: FC<Props> = ({ header, type, filterColumns }) => {
  const { getValueFromSettingForKey } = useUserTheme();
  const COL_PARTS = {
    campaign:
      getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
      !getValueFromSettingForKey('ShowBroadcastStatus')
        ? 8
        : 7,
    creative: 7,
    partner: 3,
    user: 4,
    usersApi: 5,
    agency: 0,
    client: 0,
    offer: 2,
    contracts: 8,
    counteragents: 5,
  };

  return (
    <Card
      noHover
      className={cn(css.item, {
        _isHeader: header,
        _isCampaign: type === 'campaign',
        _isHistory: type === 'history',
        _isCreative: type === 'creative',
      })}
    >
      {type === 'campaign' && (
        <>
          <div className={css.campaign_left}>
            <Load
              style={{
                width: `100%`,
                height: '20px',
              }}
            />
          </div>
          <ScrollSyncNode group="a">
            <div
              className={cn(
                css.campaign_center,
                `length-${filterColumns && filterColumns.length - 1}`,
              )}
            >
              {filterColumns &&
                filterColumns.map(
                  (item, index) =>
                    index > 0 && (
                      <div
                        className={cn(
                          css.loadingItem,
                          `size-${item.lengthTitle}`,
                        )}
                        key={item.data}
                      >
                        <Load
                          style={{
                            height: '20px',
                          }}
                        />
                      </div>
                    ),
                )}
            </div>
          </ScrollSyncNode>
          <div className={css.campaign_right}>
            {getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
              !getValueFromSettingForKey('ShowBroadcastStatus') && (
                <Load
                  style={{
                    width: `100%`,
                    height: '20px',
                  }}
                />
              )}
          </div>
        </>
      )}
      {type === 'creative' && (
        <div className={cn(css.content, 'creative')}>
          <Load
            style={{
              width: `40px`,
              height: '20px',
            }}
          />
          <Load
            style={{
              width: '32px',
              height: '32px',
            }}
          />
          <Load
            style={{
              width: '20px',
              height: '24px',
            }}
          />
          <Load
            style={{
              width: `230px`,
              height: '15px',
            }}
          />
          {COL_PARTS[type] !== 0 && <Param count={COL_PARTS[type]} />}
          <Load
            style={{
              width: `40px`,
            }}
          />
        </div>
      )}
      {type !== 'campaign' && type !== 'creative' && type !== 'history' && (
        <div
          className={cn(css.content, {
            agency: type === 'agency',
            user: type === 'user',
            usersApi: type === 'usersApi',
            partner: type === 'partner',
            client: type === 'client',
            offer: type === 'offer',
            contracts: type === 'contracts',
            counteragents: type === 'counteragents',
          })}
        >
          <div className={css.header}>
            <div
              className={cn(css.logo)}
              style={{ backgroundColor: `#F4F4F4` }}
            />
            <div className={css.text}>
              <div className={css.title}>
                <Load
                  style={{
                    width: `180px`,
                  }}
                />
              </div>
              {type !== 'user' && (
                <div className={css.item__subtitle}>
                  <Load />
                </div>
              )}
            </div>
          </div>
          {COL_PARTS[type] !== 0 && <Param count={COL_PARTS[type]} />}
        </div>
      )}
      {type === 'history' && (
        <div className={cn(css.content, 'history')}>
          <Load
            style={{
              width: `100%`,
              height: '20px',
            }}
          />
          <div className={css.double}>
            <Load
              style={{
                width: `100%`,
                height: '20px',
              }}
            />
            <Load
              style={{
                width: `80%`,
                height: '20px',
              }}
            />
          </div>
          <div className={css.double}>
            <Load
              style={{
                width: `100%`,
                height: '20px',
              }}
            />
          </div>
          <div className={css.double}>
            <Load
              style={{
                width: `80%`,
                height: '20px',
              }}
            />
            <Load
              style={{
                width: `100%`,
                height: '20px',
              }}
            />
          </div>
          <div className={css.double}>
            <Load
              style={{
                width: `100%`,
                height: '20px',
              }}
            />
            <Load
              style={{
                width: `80%`,
                height: '20px',
              }}
            />
          </div>
          <Load
            style={{
              width: `100%`,
              height: '20px',
            }}
          />
          <div className={css.double}>
            <Load
              style={{
                width: `100%`,
                height: '20px',
              }}
            />
            <Load
              style={{
                width: `80%`,
                height: '20px',
              }}
            />
          </div>
        </div>
      )}
    </Card>
  );
};

export default PreloaderString;
