import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import cn from 'classnames';
import { FiCheck, FiX } from 'react-icons/fi';
import { ToastUtilsSuccessParams } from 'types/general';
import Loader from 'components/UI/Loader';
import css from './styles.module.scss';

type ToastUtilsSuccess = (
  str: string | ToastUtilsSuccessParams | undefined,
) => void;

type ToastUtilsLoading = (str: {
  pendingStr: string;
  errorStr: string;
  successStr: string;
}) => void;

type ToastUtilsPending = (str: string) => string | number | undefined;

type ToastUtilsError = (str: string) => void;

export const toastSuccess: ToastUtilsSuccess = (str) => {
  toast(
    str ? (
      <div className={css.toastText}>
        {typeof str === 'string' ? (
          <span>{str}</span>
        ) : (
          <>
            <span>{str.before}</span>
            <strong>{str.title}</strong>
            <span>{str.after}</span>
          </>
        )}
      </div>
    ) : (
      <div className={css.toastText}>Неизвестное оповещение</div>
    ),
    {
      icon: (
        <div className={cn(css.icon, '_isSuccess')}>
          <FiCheck size={16} color="#fff" />
        </div>
      ),
      bodyClassName: css.toast__body,
      position: toast.POSITION.TOP_RIGHT,
      className: 'Toastify__toast--success',
    },
  );
};

export const toastPending: ToastUtilsPending = (
  str,
) =>
  toast(
    str ? (
      <div className={css.toastText}>
        <span>{str}</span>
      </div>
    ) : (
      <div className={css.toastText}>Неизвестное оповещение</div>
    ),
    {
      icon: <Loader />,
      className: 'Toastify__toast--warning',
      bodyClassName: css.toast__body,
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions,
  );

export const toastLoading: ToastUtilsLoading = ({
  pendingStr,
  errorStr,
  successStr,
}) => {
  const myPromise = new Promise((resolve) =>
    fetch('https://jsonplaceholder.typicode.com/posts/1')
      .then((response) => response.json())
      .then((json) => setTimeout(() => resolve(json), 3000)),
  );

  toast.promise(
    myPromise,
    {
      pending: {
        render() {
          return pendingStr ? (
            <div className={css.toastText}>
              <span>{pendingStr}</span>
            </div>
          ) : (
            <div className={css.toastText}>Неизвестное оповещение</div>
          );
        },
        icon: <Loader />,
        className: 'Toastify__toast--warning',
      },
      error: {
        render() {
          return errorStr ? (
            <div className={css.toastText}>
              <span>{errorStr}</span>
            </div>
          ) : (
            <div className={css.toastText}>Неизвестное оповещение</div>
          );
        },
        icon: (
          <div className={cn(css.icon, '_isError')}>
            <FiX size={16} color="#fff" />
          </div>
        ),
        className: 'Toastify__toast--error',
      },
      success: {
        render() {
          return successStr ? (
            <div className={css.toastText}>
              <span>{successStr}</span>
            </div>
          ) : (
            <div className={css.toastText}>Неизвестное оповещение</div>
          );
        },
        icon: (
          <div className={cn(css.icon, '_isSuccess')}>
            <FiCheck size={16} color="#fff" />
          </div>
        ),
        className: 'Toastify__toast--success',
      },
    },
    {
      bodyClassName: css.toast__body,
      position: toast.POSITION.TOP_RIGHT,
    },
  );
};

export const toastError: ToastUtilsError = (str) => {
  toast(str, {
    icon: (
      <div className={cn(css.icon, '_isError')}>
        <FiX size={16} color="#fff" />
      </div>
    ),
    bodyClassName: css.toast__body,
    position: toast.POSITION.TOP_RIGHT,
    className: 'Toastify__toast--error',
  });
};
