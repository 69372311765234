import { RGetPersonal, groupData } from 'domains/campaign/types';
import { useCurrency } from 'domains/project/hooks';
import { useDispatchApp } from 'redux/rootSelectors';
import { fetchHistoryData } from 'domains/historyChanges/actions';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ResponseUser } from 'domains/user/types';
import { useHistoryDataSelector } from 'domains/historyChanges/selectors';
import {
  IUpdatedHistory,
  campaignHistoryWithTextData,
  changesArray,
} from 'domains/historyChanges/model/hooks/useTransformHistoryData';
import { THistoryResponse } from 'domains/historyChanges/types';
import { getLastTwoMonthsDate } from 'utils/genTimePeriod';
import { useCampaignInfo } from '../selectors';

interface TUseCampaignGroupReturn {
  groupInfo?: groupData | null;
  currency: string;
  updatedCampaignHistory: IUpdatedHistory<string>[] | null;
  getCampaignHistory: () => void;
  isLoading: boolean;
}

interface IUseCampaignGroupWidgetData {
  campaign?: RGetPersonal | null;
  user: ResponseUser | null;
}

export const useCampaignGroupWidgetData = ({
  campaign,
  user,
}: IUseCampaignGroupWidgetData): TUseCampaignGroupReturn => {
  const { data: campaignInfo } = useCampaignInfo();
  const prevCampaignInfo = useRef<RGetPersonal | null>(null);

  const dispatch = useDispatchApp();
  const { data: currentCampaignHistory, isLoading } = useHistoryDataSelector();

  const [updatedCampaignHistory, setUpdatedCampaignHistory] = useState<
    IUpdatedHistory<string>[] | null
  >([]);

  const getCampaignHistory = (): void => {
    dispatch(
      fetchHistoryData({
        partner_xxhash: user?.partner.xxhash,
        filter: {
          period: getLastTwoMonthsDate(),
          search: campaign?.internal_id,
          limit: { from: 0, to: 6 },
        },
      }),
    );
  };

  const updatedCampaignHistoryCallback = useCallback(
    (currHistory: THistoryResponse[]) => {
      const changes = changesArray(currHistory);
      setUpdatedCampaignHistory(
        campaignHistoryWithTextData(currHistory, changes),
      );
    },
    [currentCampaignHistory],
  );

  useEffect(() => {
    if (prevCampaignInfo.current !== campaignInfo && user?.group.includes('owner')) {
      getCampaignHistory();
    }
    prevCampaignInfo.current = campaignInfo;
  }, [campaignInfo]);

  useEffect(() => {
    if (currentCampaignHistory) {
      updatedCampaignHistoryCallback(currentCampaignHistory);
    }
  }, [currentCampaignHistory]);

  const groupInfo = campaignInfo?.group;
  const { currency } = useCurrency();

  return {
    groupInfo,
    currency,
    updatedCampaignHistory,
    getCampaignHistory,
    isLoading,
  };
};
