import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/UI/Checkbox/checkbox';
import { FiSearch } from 'react-icons/fi';
import cn from 'classnames';
import { Form, FormikProvider } from 'formik';
import { useStatisticsFields } from 'components/StatisticFields/hooks';
import Card from 'components/UI/Card';
import Loading from 'domains/client/Additional/ui/Loading';
import css from './styles.module.scss';

interface IStatisticFieldsProps {
  descriptionText: string;
  mode: 'organization' | 'client' | 'campaign';
}

const StatiscticFields: FC<IStatisticFieldsProps> = ({
  descriptionText,
  mode,
}) => {
  const { t } = useTranslation();

  const {
    isLoading,
    localTitle,
    formik,
    statisticFieldsData,
    search,
    onSearch,
    counterChecked,
    handlerToggleCheckbox,
    debounceHandleSubmit,
    isLocked,
  } = useStatisticsFields({
    mode,
  });

  return (
    <>
      {isLoading ? (
        <Card noHover isUseAccess>
          <Loading />
        </Card>
      ) : (
        <FormikProvider value={formik}>
          <Form>
            {descriptionText && (
              <div className={css.description}>{descriptionText}</div>
            )}
            <div className={css.creatives_header}>
              <div className={css.total}>
                <Checkbox
                  label={`${t(`analysis_page.selected`)} ${
                    counterChecked && counterChecked()
                  } ${t(`analysis_page.of`)} ${
                    statisticFieldsData && statisticFieldsData.length
                  }`}
                  isPartiallyChecked={
                    counterChecked() !== statisticFieldsData?.length &&
                    !!counterChecked()
                  }
                  inputAttributes={{
                    type: 'checkbox',
                    checked: counterChecked() === statisticFieldsData?.length,
                    onChange: () => handlerToggleCheckbox(),
                    disabled: isLocked,
                  }}
                />
              </div>
              <div className={css.search}>
                <div className={css.input}>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t(`analysis_page.search`)}
                    value={search}
                    onChange={(e) => onSearch(e)}
                  />
                </div>
                <div className={css.icon}>
                  <FiSearch size={24} />
                </div>
              </div>
            </div>
            <div
              className={cn(css.creatives_body, {
                isLoading: false,
                isEmpty: false,
              })}
            >
              {statisticFieldsData &&
                statisticFieldsData.length > 0 &&
                statisticFieldsData.map((item) => (
                  <div className={css.creatives_item} key={item.key}>
                    <Checkbox
                      label={localTitle ? localTitle(item) : ''}
                      disabled={item.default}
                      inputAttributes={{
                        type: 'checkbox',
                        name: `${item.id}`,
                        checked: formik?.values[item.id],
                        onChange: () => {
                          formik?.setFieldValue(
                            `${item.id}`,
                            !formik.values[item.id],
                          );
                          debounceHandleSubmit();
                        },
                        disabled: isLocked,
                      }}
                    />
                  </div>
                ))}
              {false && (
                <div className={css.empty}>
                  <FiSearch size={24} color="#828282" />
                  <span>{t(`search_none.none_1`)}</span>
                </div>
              )}
            </div>
          </Form>
        </FormikProvider>
      )}
    </>
  );
};

export default StatiscticFields;
