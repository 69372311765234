import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { genFetchedData } from 'redux/fetchedData';
import {
  toastError,
  toastPending,
  toastSuccess,
} from 'utils/ToastUtils/toastUtils';
import { toast } from 'react-toastify';
import i18n from '../../../i18n';
import { updateDSP } from '../api';
import { setUploadDspData, SideMenuState } from '../reducer';

export function* workerUpdateDspSaga(): SagaIterator {
  let fetchedData = genFetchedData<SideMenuState['uploadDSPData']>(null).set(
    'isLoading',
    true,
  );
  yield put(setUploadDspData(fetchedData));

  let toastId: string | number | undefined;

  try {
    toastId = toastPending(i18n.t(`translations.dsp_precess`));
    const resp = yield call(updateDSP);
    if (resp) {
      if (toastId) {
        toast.dismiss(toastId);
      }
      toastSuccess(i18n.t(`translations.dsp_updated`));
    }
  } catch (e) {
    console.log({ e });
    if (toastId) {
      toast.dismiss(toastId);
    }
    toastError(i18n.t(`translations.dsp_error`));
  } finally {
    fetchedData = fetchedData.set('isLoading', false).set('LTU', Date.now());
    yield put(setUploadDspData(fetchedData));
  }
}
