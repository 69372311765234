import React, { FC, RefObject, useMemo } from 'react';
import cn from 'classnames';
import { FiRefreshCcw } from 'react-icons/fi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Filter from 'components/Filter';
import Tooltip from 'components/UI/Tooltip';
import MainSearch from 'components/MainSearch';
import { useClientsInfo } from 'domains/clients/model/selectors';
import DateRangeFilter from '../../../components/DateRangeFilter';
import { parsingDate } from '../../../utils/momentParse';
import AccessUserRight from '../../../components/AccessUserRight';
import Button from '../../../components/UI/Buttons/buttons';
import css from '../styles.module.scss';
import { useCampaignsPeriodInfo } from '../model/selectors';
import { TOnSetDateRange } from '../../../types/general';
import { TCampaignsFilters } from '../types';
import { useCampaignsDownloadStatistics } from '../model/hooks/useCampaignsDownloadStatistics';
import DownloadButton from '../../../components/UI/Buttons/DownloadButton';
import { ReactComponent as RaportSvg } from '../icons/raport.svg';

type Props = {
  toggleSearch: boolean;
  onOpenSearch: () => void;
  onCloseSearch: () => void;
  searchRef: RefObject<HTMLInputElement>;
  search: string;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdateStatistic: TOnSetDateRange;
  params: RouteComponentProps;
  filters: TCampaignsFilters;
  handleApplyFilter: (v: Props['filters']) => void;
  settings: any;
  filtersLabel: Array<{ name: string; data: string }>;
  showReport: boolean;
  searchMatch: number;
  isShowMatch: boolean;
  setShowReport: React.Dispatch<React.SetStateAction<boolean>>;
};

const TableHeader: FC<Props> = ({
  toggleSearch,
  onOpenSearch,
  searchRef,
  search,
  onCloseSearch,
  onChangeSearch,
  settings,
  onUpdateStatistic,
  params,
  searchMatch,
  handleApplyFilter,
  filters,
  filtersLabel,
  showReport,
  isShowMatch,
  setShowReport,
}) => {
  const { t } = useTranslation();

  const campaignsPeriod = useCampaignsPeriodInfo();

  const { data: clientsList } = useClientsInfo();

  const period = useMemo(
    () => ({
      from: parsingDate(settings.period.from).format('YYYY-MM-DD'),
      to: parsingDate(settings.period.to).format('YYYY-MM-DD'),
    }),
    [settings.period],
  );

  const { isDisabled, handleDownload } = useCampaignsDownloadStatistics({
    period,
    periodType: settings.type,
    filtersLabel,
    search,
  });

  return (
    <div className={css.status}>
      <h1 className={css.title}>{t('campaigns_page.campaigns')}</h1>
      <div className={css.actions}>
        <MainSearch
          toggleSearch={toggleSearch}
          match={searchMatch}
          onOpenSearch={onOpenSearch}
          value={search}
          onChangeSearch={onChangeSearch}
          searchRef={searchRef}
          onCloseSearch={onCloseSearch}
          placeholder={t('campaigns_page.camp_search')}
          isShowMatch={isShowMatch}
          className={css.search}
        />
        <div
          className={css.update}
          onClick={() => onUpdateStatistic(settings.period, settings.type)}
        >
          <Tooltip bottom title={<FiRefreshCcw size={22} />}>
            {t(`update_btn`)}
          </Tooltip>
        </div>
        <div
          className={cn(css.report_btn, {
            _isActive: showReport,
          })}
          onClick={() => setShowReport(!showReport)}
        >
          <Tooltip bottom title={<RaportSvg />}>
            {showReport
              ? t('campaigns_page.chart.hide')
              : t('campaigns_page.chart.show')}
          </Tooltip>
        </div>
        <DownloadButton
          onClick={handleDownload}
          disabled={isDisabled}
          classname={css.download}
        />
        <Filter
          data={filters}
          handleApplyFilter={handleApplyFilter}
          classname={css.filter}
          mode="campaign"
        />
        <div className={css.range}>
          <DateRangeFilter
            range={{
              from: parsingDate(settings.period.from).format('YYYY-MM-DD'),
              to: parsingDate(settings.period.to).format('YYYY-MM-DD'),
            }}
            dateStart={parsingDate(
              campaignsPeriod.from || moment().subtract(365, 'days'),
            ).toDate()}
            dateEnd={parsingDate(campaignsPeriod.to || new Date()).toDate()}
            onSetDateRange={onUpdateStatistic}
            initialTitleDay={settings.type}
          />
        </div>

        <AccessUserRight>
          {(props) => (
            <Tooltip
              bottom
              hideHint={clientsList?.length !== 0}
              title={
                <Button
                  height={40}
                  title={t('campaigns_page.create_btn')}
                  buttonAttributes={{
                    onClick: () =>
                      params.history.push({
                        pathname: `${params.location.pathname}/create-campaign`,
                        search: params.location.search.includes('highlights')
                          ? params.location.search.split('&highlights')[0]
                          : params.location.search,
                      }),
                    disabled: props.isDisable || clientsList?.length === 0,
                  }}
                />
              }
            >
              Для создания кампании необходимо создать клиента
            </Tooltip>
          )}
        </AccessUserRight>
      </div>
    </div>
  );
};
export default TableHeader;
