import Button from 'components/UI/Buttons/buttons';
import React, { FC } from 'react';
import {
  FiSearch,
  FiRefreshCcw,
  FiX,
  FiEdit,
  FiChevronDown,
} from 'react-icons/fi';
import {
  VariableSizeList as List,
  ListChildComponentProps,
} from 'react-window';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import Tooltip from 'components/UI/Tooltip';
import PreloaderFormList from 'components/Preloaders/FormList';
import { TCommentList } from 'domains/comment/model/types';
import ModalWithQuestion from 'domains/modals/modalWithQuestion';
import FormScroll from 'components/Preloaders/FormScroll';
import { drawSearchText } from '../../model/helper/drawSearchText';
import addSVG from './icons/add.svg';
import editSVG from './icons/edit.svg';
import checkSVG from './icons/check.svg';
import ModalComment from '../modal';
import { useComments } from '../../model/hook/useComments';
import i18next from '../../../../i18n';
import css from './styles.module.scss';
import { ReactComponent as EmptySvg } from './icons/empty.svg';

type CommentsProps = {
  comments: TCommentList;
  isLoading: boolean;
  handlerAddComment: (v: string) => void;
  handleEditComment: (
    comment_id: number,
    text: string,
    callback: () => void,
  ) => void;
  handleDeleteComment: (comment_id: number, callback: () => void) => void;
  handleFetchComments?: () => void;
  LTU: number;
  email: string;
};

const Comments: FC<CommentsProps> = ({
  comments,
  isLoading,
  handlerAddComment,
  handleEditComment,
  handleDeleteComment,
  handleFetchComments,
  LTU,
  email,
}) => {
  const {
    isModal,
    typeModal,
    onCloseModal,
    commentID,
    addComment,
    editComment,
    commentList,
    commentCollapse,
    isModalDelete,
    onCloseModalDelete,
    onOpenModalDelete,
    search,
    onSearch,
    onOpenSearch,
    onCloseSearch,
    toggleSearch,
    isEdited,
    timeGen,
    editedCommentID,
    deletedCommentID,
    handlerIsAllComments,
    isShowAllComments,
    searchInputElement,
    updateTimeoutCommentList,
    onHandleDeleteComment,
    isShowBtnAllComment,
    heightBOX,
    countSearch,
    wrapperRef,
    isLocked,
  } = useComments({ comments, LTU, handleDeleteComment, email });

  const InnerElement: FC = ({ children }) => (
    <div
      style={{
        height: `${heightBOX()}px`,
        width: `100%`,
      }}
    >
      {children}
    </div>
  );

  const Row: FC<
    ListChildComponentProps<ReturnType<typeof useComments>['commentList']>
  > = React.useCallback(
    ({ index, data, style, isScrolling }) => {
      const row = data[index];
      if (isScrolling) {
        return (
          <div
            style={{
              ...style,
              height: `${row.height}px`,
              top: `${row.top}px`,
            }}
          >
            <FormScroll />
          </div>
        );
      }

      return (
        <div
          className={cn(css.comment, {
            _isOwn: email === row.user_email,
            _isEdited: editedCommentID.includes(row.id),
            _isDeleted: deletedCommentID.includes(row.id),
          })}
          style={{
            ...style,
            height: `${row.height}px`,
            top: `${row.top}px`,
          }}
        >
          {deletedCommentID.includes(row.id) ? (
            <div className={css.comment__deleted}>
              {i18next.t(`campaigns_page.campaigns_comments.comment_deleted`)}
            </div>
          ) : (
            <>
              <div className={css.comment__header}>
                <span
                  className={css.comment__header_email}
                  title={row.user_email}
                >
                  {drawSearchText(row.user_email, search)}
                  <span className={css.comment__header_email_hint}>
                    {drawSearchText(row.user_email, search)}
                  </span>
                </span>
                {!editedCommentID.includes(row.id) && (
                  <span className={css.comment__header_date}>
                    {isEdited(row.date_create.date, row.date_edit.date) && (
                      <ReactSVG src={editSVG} size={16} />
                    )}
                    {timeGen(row.date_create.date)}
                  </span>
                )}

                {editedCommentID.includes(row.id) && (
                  <span className={css.comment__header_saved}>
                    <ReactSVG
                      className={css.comment__header_saved_icon}
                      src={checkSVG}
                      size={16}
                    />
                    {i18next.t(`campaigns_page.campaigns_comments.saved`)}
                  </span>
                )}
              </div>
              <div
                className={cn(css.comment__text, {
                  _isFullText: row.isCollapse,
                })}
              >
                {drawSearchText(row.text, search)}
              </div>
              <div
                className={cn(css.comment__actions, {
                  _noExpend:
                    email === row.user_email &&
                    typeof row.isCollapse !== 'boolean',
                })}
              >
                {typeof row.isCollapse === 'boolean' && (
                  <span
                    className={css.comment__actions_expand}
                    onClick={() => {
                      if (typeof row.isCollapse === 'boolean') {
                        commentCollapse(row.isCollapse, row.id);
                      }
                    }}
                  >
                    <FiChevronDown
                      size={24}
                      className={cn(css.comment__actions_expand_icon, {
                        _isCollapse: row.isCollapse,
                      })}
                    />
                    {row.isCollapse
                      ? i18next.t(`campaigns_page.campaigns_comments.roll_up`)
                      : i18next.t(`campaigns_page.campaigns_comments.expand`)}
                  </span>
                )}
                {email === row.user_email && (
                  <span
                    className={css.comment__actions_edit}
                    onClick={() => editComment(row.id)}
                  >
                    <FiEdit size={20} />
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      );
    },
    [commentList, deletedCommentID, editedCommentID],
  );

  return (
    <div className={css.comments}>
      <div
        className={cn(css.comments__header, {
          _isActiveSearch: toggleSearch,
          _isLocked: isLocked,
        })}
      >
        <div className={css.comments__header_actions}>
          {handleFetchComments && !!comments.length && (
            <Tooltip
              title={
                <div
                  className={css.comments__header_update}
                  onClick={handleFetchComments}
                >
                  <FiRefreshCcw size={24} />
                  {(!!editedCommentID.length || !!deletedCommentID.length) && (
                    <div className={css.comments__header_update_status} />
                  )}
                </div>
              }
            >
              {i18next.t(`update_btn`)}
            </Tooltip>
          )}

          <Tooltip
            isDisabled={isLocked}
            hideHint={isLocked}
            title={
              <ReactSVG
                className={css.comments__header_add_comment}
                src={addSVG}
              />
            }
            onClick={addComment}
          >
            {i18next.t(`add_btn`)}
          </Tooltip>

          {!!comments.length && (
            <div className={css.comments__header_search} ref={wrapperRef}>
              <Tooltip
                title={
                  <FiSearch
                    className={css.comments__header_search_icon}
                    size={24}
                    onClick={onOpenSearch}
                  />
                }
              >
                {i18next.t(`search_btn`)}
              </Tooltip>

              <input
                className={css.comments__header_search_input}
                type="text"
                autoComplete="off"
                ref={searchInputElement}
                value={search}
                onChange={onSearch}
                placeholder={i18next.t(
                  `campaigns_page.campaigns_comments.search_placeholder`,
                )}
              />
              <FiX
                className={css.comments__header_search_close}
                size={24}
                onClick={onCloseSearch}
              />
            </div>
          )}
        </div>
      </div>
      <div className={css.comments__body}>
        <div
          className={cn(css.comments__content, {
            _isFewComment: !isShowAllComments,
          })}
        >
          {search && !commentList.length && (
            <div className={css.comments__content_noSearch}>
              {i18next.t(`campaigns_page.campaigns_comments.no_search`)}
            </div>
          )}
          {isLoading && <PreloaderFormList />}
          {!!commentList.length && (
            <List
              height={heightBOX()}
              itemCount={commentList.length}
              itemSize={(index) => commentList[index].height}
              width="100%"
              itemData={commentList}
              innerElementType={InnerElement}
              style={{
                overflowY: 'scroll',
                willChange: 'initial',
              }}
            >
              {Row}
            </List>
          )}
        </div>
        {!comments.length && (
          <div className={css.comments__empty}>
            <EmptySvg />
            <span>{i18next.t(`campaigns_page.campaigns_comments.empty`)}</span>
          </div>
        )}
        {isShowBtnAllComment() && !!comments.length && (
          <div
            className={cn(css.comments__content_btn, {
              noBorder: !commentList.length,
            })}
          >
            <Button
              title={
                isShowAllComments
                  ? `${i18next.t(
                      `campaigns_page.campaigns_comments.hide_comments`,
                    )}`
                  : `${i18next.t(
                      `campaigns_page.campaigns_comments.show_comments_part`,
                    )} (${search ? countSearch.current : comments.length})`
              }
              height={28}
              lightblue
              buttonAttributes={{
                onClick: isShowAllComments
                  ? () => handlerIsAllComments(false)
                  : () => handlerIsAllComments(true),
                style: {
                  width: '100%',
                },
                role: 'button',
              }}
            />
          </div>
        )}
      </div>

      {isModal && (
        <ModalComment
          updateTimeoutCommentList={updateTimeoutCommentList}
          onOpenModalDelete={onOpenModalDelete}
          isOpen={isModal}
          onCloseModal={onCloseModal}
          type={typeModal}
          comments={comments}
          comment_id={commentID}
          handlerAddComment={handlerAddComment}
          handleEditComment={handleEditComment}
        />
      )}
      {isModalDelete && (
        <ModalWithQuestion
          onClose={onCloseModalDelete}
          onClick={onHandleDeleteComment}
          subText={i18next.t(`campaigns_page.campaigns_comments.confirm_del`)}
          modalName={i18next.t(`campaigns_page.campaigns_comments.del_comment`)}
        />
      )}
    </div>
  );
};

export default Comments;
