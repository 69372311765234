import React, { FC } from 'react';
import Card from 'components/UI/Card';
import CollapseCard from 'components/UI/CollapseCard';
import { useCampaignExperimental } from 'domains/campaign/Experimental/hooks';
import ExperimentalInputField from 'components/ExperimentalInputField';
import cn from 'classnames';
import Help from 'components/Help';
import { useTranslation } from 'react-i18next';
import css from '../styles.module.scss';
import excss from '../../Experimental/styles.module.scss';

const AdvancedSettings: FC = () => {
  const { t } = useTranslation();
  const { campaign, experimentalInfo } = useCampaignExperimental();

  const showFields = ['hidden_uniqueimps', 'hidden_quartile', 'repeat_click'];

  const fieldsDataArr = experimentalInfo?.filter(
    (fieldData) =>
      fieldData.page === 'experimental' && showFields.includes(fieldData.field),
  );

  if (fieldsDataArr?.length === 0) {
    return null;
  }

  return (
    <Card noHover className={css.card} isUseAccess>
      <CollapseCard
        closedStart
        noUpdateHeight
        title={
          <h3 className={css.title}>{t(`campaigns_page.advancedSettings`)}</h3>
        }
      >
        <div className={css.form}>
          {fieldsDataArr?.length ? (
            fieldsDataArr.map(
              (field) =>
                field.type !== 'select' && (
                  <div
                    className={cn(excss.item, {
                      _isPriceHorisontal: field.type === 'float',
                    })}
                    key={field.id}
                  >
                    <ExperimentalInputField
                      item={field}
                      mode="campaign"
                      config_values={campaign?.config_values}
                      xxhash={campaign?.xxhash}
                    />
                    {field.description !== '' && (
                      <Help
                        title=""
                        helpattributes={{
                          text: field.description,
                        }}
                      />
                    )}
                  </div>
                ),
            )
          ) : (
            <p className={css.text}>{t(`campaigns_page.emptyArrayAdvancedSettings`)}</p>
          )}
        </div>
      </CollapseCard>
    </Card>
  );
};

export default AdvancedSettings;
