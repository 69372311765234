import { FormikProps, useFormik } from 'formik';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import deepEqual from 'deep-equal';
import { useTranslation } from 'react-i18next';
import { RStatisticsField, TStatisticsFields } from 'domains/campaign/types';
import debounce from 'lodash/debounce';
import { usePartnerStatisticFieldList } from 'domains/campaigns/model/selectors';
import {
  fetchStatisticFieldList,
  setPartnerStatisticsFields,
} from 'domains/campaigns/model/actions';
import { useParams } from 'react-router';
import { resetStatisticFieldList } from 'domains/campaigns/reducer';
import { useProjectInfo } from 'domains/project/hooks';
import { useIsGroup } from 'domains/user/hooks';
import { useCampaignInfo } from '../../domains/campaign/model/selectors';
import { useDispatchApp } from '../../redux/rootSelectors';

export type TValue = Record<string, boolean>;

type TUseStatisticsFieldsArgs = {
  mode: 'organization' | 'client' | 'campaign';
};

type TFUseStatisticsFields = (args: TUseStatisticsFieldsArgs) => {
  counterChecked: () => number;
  formik: FormikProps<TValue>;
  isLoading: boolean;
  handlerToggleCheckbox: () => void;
  localTitle: ({ key, title }: RStatisticsField) => string;
  debounceHandleSubmit: () => void;
  search: string;
  statisticFieldsData: TStatisticsFields | null;
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  /** Заблокировать из-за отсутствия прав */
  isLocked?: boolean
};

export const useStatisticsFields: TFUseStatisticsFields = ({ mode }) => {
  const { isLocked } = useIsGroup();
  const { t } = useTranslation();
  const dispatch = useDispatchApp();

  const { data: campaign } = useCampaignInfo();
  const { data: project } = useProjectInfo();
  const { id } = useParams<{ id: string }>();

  const { data: statisticFieldList, isLoading } =
    usePartnerStatisticFieldList();

  const [currentData, setCurrentData] = useState(statisticFieldList);

  const [search, setSearch] = useState<string>('');
  const [statisticFieldsData, setStatisticFieldsData] = useState(currentData);

  /** функция локализует title */
  const localTitle: ReturnType<TFUseStatisticsFields>['localTitle'] = ({
    key,
    title,
  }) => {
    if (key === 'show') {
      return t('creative.Impressions');
    }
    if (key === 'click') {
      return t('creative.Clicks');
    }
    if (key === 'spent') {
      return t('creative.Amount_spent');
    }
    return title;
  };

  const searchInDataArr = (arr: TStatisticsFields | null, searchVal: string) =>
    arr?.filter((field) =>
      localTitle(field).toLowerCase().includes(searchVal.toLocaleLowerCase()),
    ) || [];

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchVal = e.target.value;
    setSearch(searchVal);
    if (currentData) {
      const searchDataArr = searchInDataArr(currentData, searchVal);
      setStatisticFieldsData(searchDataArr);
    }
  };

  const getCurrentXxhash = () => {
    if (mode === 'organization') return project?.partner_xxhash;
    if (mode === 'campaign') return campaign?.xxhash;
    return id;
  };

  useEffect(() => {
    dispatch(resetStatisticFieldList());
    const currentXxhash = getCurrentXxhash();
    if (currentXxhash) {
      dispatch(
        /* Обязательно очищать стейт при размонтировании списка */
        fetchStatisticFieldList({
          xxhash: currentXxhash,
          types: ['Campaign', 'Creative', 'Target', 'All', 'Report'],
        }),
      );
    }
  }, []);

  useEffect(
    () => () => {
      dispatch(resetStatisticFieldList());
    },
    [],
  );

  useEffect(() => {
    if (currentData) {
      setStatisticFieldsData(
        search ? searchInDataArr(currentData, search) : currentData,
      );
    }
  }, [currentData]);

  const defaultState = () => {
    let initialState: TValue = {};
    if (currentData) {
      initialState = currentData.reduce((acc: TValue, b) => {
        // eslint-disable-next-line no-param-reassign
        acc[`${b.id}`] = b.checked;
        return acc;
      }, {});
    }
    return initialState;
  };

  useEffect(() => {
    setCurrentData(statisticFieldList);
  }, [statisticFieldList]);

  useEffect(() => {
    if (!Object.keys(formik.values).length && currentData) {
      formik.setValues(defaultState());
    }
  }, [currentData]);

  const formik = useFormik<TValue>({
    initialValues: defaultState(),
    onSubmit: (values) => {
      if (!deepEqual(defaultState(), values)) {
        let paramKey: number[] = [];
        const currentXxhash = getCurrentXxhash();
        Object.keys(values).forEach((key) => {
          if (values[key]) {
            paramKey = [...paramKey, +key];
          }
        });
        if (currentXxhash) {
          dispatch(
            setPartnerStatisticsFields({
              xxhash: currentXxhash,
              field_list: paramKey,
              types: [],
            }),
          );
        }
      }
    },
  });

  const debounceSubmit = debounce(formik.handleSubmit, 1000);

  const debounceHandleSubmit = useCallback(() => debounceSubmit(), []);

  /** Считает количество активных чекбоксов */
  const counterChecked = () => {
    let counter = 0;
    if (statisticFieldsData) {
      statisticFieldsData
        .map((item) => item.id)
        .forEach((key) => {
          if (formik.values[key]) counter += 1;
        });
    }

    return counter;
  };

  /** включает или выключает все чекбоксы */
  const handlerToggleCheckbox = () => {
    if (statisticFieldsData) {
      if (counterChecked() !== statisticFieldsData.length) {
        statisticFieldsData.forEach((item) => {
          if (!item.default) {
            formik.setFieldValue(`${item.id}`, true);
          }
        });
      } else {
        statisticFieldsData.forEach((item) => {
          if (!item.default) {
            formik.setFieldValue(`${item.id}`, false);
          }
        });
      }
      debounceHandleSubmit();
    }
  };

  return {
    counterChecked,
    formik,
    isLoading,
    statisticFieldsData,
    handlerToggleCheckbox,
    debounceHandleSubmit,
    localTitle,
    search,
    onSearch,
    isLocked,
  };
};
