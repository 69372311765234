// Core
import { all, fork, call } from 'redux-saga/effects';
// Watchers
import watchAnalysis from 'domains/Analysis/model/sagas/watchers';
import watchHistory from 'domains/historyChanges/sagas/watchers';
import watchContainers from 'domains/containers/sagas/watchers';
import watchContainerDetail from 'domains/containerDetail/sagas/watchers';
import watchLinkToImage from 'domains/modals/linkToImage/sagas/watchers';
import watchCampaign from 'domains/campaign/sagas/watchers';
import watchClient from 'domains/client/sagas/watchers';
import watchTarget from 'domains/target/sagas/watchers';
import watchCreative from 'domains/creative/sagas/watchers';
import watchUser from 'domains/user/sagas/watchers';
import watchManagementUsers from 'domains/users/model/sagas/watchers';
import watchPartners from 'domains/partners/sagas/watchers';
import watchCampaigns from 'domains/campaigns/sagas/watchers';
import watchProject from 'domains/project/sagas/watchers';
import watchAgencies from 'domains/agencies/sagas/watchers';
import watchAgency from 'domains/agency/sagas/watchers';
import watchClients from 'domains/clients/sagas/watchers';
import watchBwListUpdate from 'domains/bw_lists_update/sagas/watchers';
import watchNotification from 'domains/notification/sagas/watchers';
import searchWatcher from 'domains/search/model/sagas/watchers';
import watcherUploads from 'domains/uploads/sagas/watchers';
import watcherWs from 'domains/wss/model/sagas/channelWss';
import watchStorage from 'domains/storage/model/sagas/watchers';
import watchAPIUsers from 'domains/usersAPI/model/sagas/watchers';
import watchOffers from 'domains/offers/model/sagas/watchers';
import watchOffer from 'domains/offer/model/sagas/watchers';
import watchCounteragents from 'domains/counteragents/model/sagas/watchers';
import watchCounteragent from 'domains/counteragent/model/sagas/watchers';
import watchContracts from 'domains/contracts/model/sagas/watchers';
import watchContract from 'domains/contract/model/sagas/watchers';
import watchBlFiles from 'domains/blackLists/sagas/watchers';
import watchBlDomains from 'domains/blackList/sagas/watchers';
import watchSideMenu from 'components/SideMenu/sagas/watchers';

export function* rootSaga(): Generator {
  yield all([
    fork(watchStorage),
    call(watcherWs),
    fork(watchLinkToImage),
    fork(watchContainers),
    fork(watchCampaign),
    fork(watchUser),
    fork(watchTarget),
    fork(watchCreative),
    fork(watchClient),
    fork(watchContainerDetail),
    fork(watchManagementUsers),
    fork(watchPartners),
    fork(watchProject),
    fork(watchCampaigns),
    fork(watchAgencies),
    fork(watchClients),
    fork(watchAgency),
    fork(watchBwListUpdate),
    fork(watchNotification),
    fork(searchWatcher),
    fork(watcherUploads),
    fork(watchHistory),
    fork(watchAnalysis),
    fork(watchAPIUsers),
    fork(watchOffers),
    fork(watchOffer),
    fork(watchCounteragents),
    fork(watchCounteragent),
    fork(watchContracts),
    fork(watchContract),
    fork(watchBlFiles),
    fork(watchBlDomains),
    fork(watchSideMenu)
  ]);
}
