import { fetchAgencies } from 'domains/agencies/reducer';
import { useUserInfo } from 'domains/user/model/selectors';
import { FormikProps, useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useDispatchApp } from 'redux/rootSelectors';
import * as Yup from 'yup';
import { ResponseUser } from 'domains/user/types';
import deepEqual from 'deep-equal';
import i18next from 'i18next';
import { getOrdList } from 'domains/counteragents/model/actions';
import { CounteragentFormikProps, CounteragentItem } from '../types';
import { getCounteragent, updateCounteragent } from '../actions';
import { useCounteragentData } from '../selectors';

interface UseCreateCounteragentReturn {
  links: {
    title: string;
    url: string;
    type: string;
  }[],
  formik: FormikProps<CounteragentFormikProps>,
  userData: ResponseUser | null,
  isLoading: boolean,
  isLoadingCounteragentData: boolean,
  isPrompt: boolean,
  error: ReturnType<typeof useCounteragentData>['error'],
  counteragentData: CounteragentItem | null,
  goBack: () => void,
  handleChangeInput: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  externalId: string | undefined,
  onGetCounteragentData: () => void;
}

export const useUpdateCounteragent = (): UseCreateCounteragentReturn => {
  const { i18n } = useTranslation();

  const { id: uuid }: { id: string } = useParams();

  const { data: userData } = useUserInfo();

  const [isLoading, setIsLoading] = useState(false);
  const [disablePrompt, setDisablePrompt] = useState(false);

  const dispatch = useDispatchApp();

  const { data: counteragentData, isLoading: isLoadingCounteragentData, error } =
    useCounteragentData();

  const onGetCounteragentData = () => {
    dispatch(getCounteragent({ uuid }));
  };

  useEffect(() => {
    onGetCounteragentData();
    dispatch(getOrdList());
  }, []);

  useEffect(() => {
    if (userData?.partner.type === 'ORGANIZATION') {
      dispatch(fetchAgencies({}))
    }
  }, [userData])

  const history = useHistory()

  const goBack = () => {
    history.push('/main/counteragents');
  };

  const goBackSubmit = () => {
    setDisablePrompt(true);
    goBack();
  };

  const formik = useFormik<CounteragentFormikProps>({
    enableReinitialize: true,
    initialValues: {
      agency_xxhash_list: counteragentData?.partners ? counteragentData.partners.map(item => ({ label: item.title, value: item.xxhash })) : [],
      name: counteragentData?.name || '',
      aliases: counteragentData?.aliases || '',
      exportName: counteragentData?.exportName || '',
      inn: counteragentData?.inn || '',
      type: counteragentData?.type || '',
      ord: counteragentData?.ord || 4,
      mobilePhone: counteragentData?.mobilePhone || '',
      epayNumber: counteragentData?.epayNumber || '',
      regNumber: counteragentData?.regNumber || '',
      alternativeInn: counteragentData?.alternativeInn || '',
      oksmNumber: counteragentData?.oksmNumber || '',
      directClient: counteragentData?.directClient || false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(i18next.t(`ord.errors.required_field`))
        .trim()
        .min(1, i18next.t(`ord.errors.min_1_character`))
        .max(255, i18next.t(`ord.errors.max_255_characters`)),
      aliases: Yup.string().max(1000, i18next.t(`ord.errors.max_1000_characters`)),
      exportName: Yup.string(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: (values) => {
      const { name, exportName, aliases } = values;

      const params = {
        uuid,
        objectCuster: {
          name,
          exportName,
          aliases,
        },
        callbacks: {
          setIsLoading,
          goBack: goBackSubmit,
        },
      };

      if (params) {
        dispatch(updateCounteragent(params));
      }
    },
  });

  const handleChangeInput = (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => {
    formik
      .setFieldValue(field, value, shouldValidate)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .then(() => formik.validateField(field));
  };

  const links = useMemo(
    () => [
      {
        title: i18n.t(`ord.сounterparties_title`),
        url: `/main/counteragents`,
        type: 'home',
      },
      {
        title: counteragentData?.name || '',
        url: `заглушка`,
        type: 'end',
      },
    ],
    [i18n.language, counteragentData?.name],
  );

  const isPrompt = !disablePrompt && !deepEqual(formik.values, formik.initialValues);

  return {
    isPrompt,
    links,
    formik,
    isLoading,
    isLoadingCounteragentData,
    userData,
    counteragentData,
    error,
    goBack,
    handleChangeInput,
    externalId: counteragentData?.externalId,
    onGetCounteragentData,
  }
};