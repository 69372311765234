import { ResponseUser } from 'domains/user/types';

/**
 * Функция позвращает boolean значение, принадлежит ли пользователь агенству
 * @param user Данные пользователя
 * @param id id из Params
 * @returns Boolean
 */
export const checkIsAgencyNotOwn = (
  user: ResponseUser,
  id: string,
): boolean => {
  if (user?.partner.type === 'AGENCY') {
    return id === user.partner.xxhash;
  }
  return true;
};
