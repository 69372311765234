import UploadImage from 'domains/uploads/UploadImage';
import React, { ChangeEvent, FC } from 'react';
import Input from 'components/UI/Input/input';
import { IUseTypeProp } from 'domains/creative/creatives/creativeAdd/hooks/useType';
import i18n from '../../../../../../../../i18n';
import css from '../../../styles.module.scss';

interface IYandexProp {
  formik: IUseTypeProp['formik']
}

const index: FC<IYandexProp> = ({
  formik: { values, setFieldValue, errors, handleChange, validateForm }
}) => (
  <div className={css.card__form}>
    <UploadImage
      keyUploadFile="zip_yandex_html"
      preview={{
        file_url: values.data.zip_yandex_html,
        client_name: values.data.zip_yandex_html,
      }}
      name="zip_yandex_html"
      setFieldValue={setFieldValue}
      validateForm={validateForm}
      label={i18n.t('creative.creasmall_set.zip_arr')}
      customLinkTitle={i18n.t('creative.creasmall_set.zip_link')}
      hintText={<span>{i18n.t('creative.creasmall_set.yand_format')}</span>}
      fieldType="zip"
      maxSizeFile={0.15}
      sizeForError={i18n.t('creative.yandex_html_text.t6')}
      hasEdit
      edit
      isData
      file_ext={['.zip']}
      tooltip={{
        text: i18n.t('creative.creasmall_set.zip_arrrull'),
        link: 'https://yandex.by/adv/requirements/html5',
      }}
    />

    <UploadImage
      keyUploadFile="img_yandex_html"
      preview={{
        file_url: values.data.img_yandex_html,
        client_name: values.data.img_yandex_html,
      }}
      name="img_yandex_html"
      setFieldValue={setFieldValue}
      validateForm={validateForm}
      label={i18n.t('creative.creasmall_set.yand_stub')}
      customLinkTitle={i18n.t('creative.creasmall_set.yand_link_img')}
      hintText={
        <span>
          {i18n.t('creative.creasmall_set.yand_formats1')}
          <br />
          {i18n.t('creative.creasmall_set.yand_formats2')}
        </span>
      }
      maxSizeFile={0.15}
      sizeForError={i18n.t('creative.yandex_html_text.t6')}
      hasEdit
      edit
      isData
      file_ext={['.jpeg', '.jpg', '.png', '.gif', '.svg']}
    />

    <Input
      label={i18n.t('creative.creasmall_set.yand_alt_text')}
      showValueCount
      isTextarea
      inputAttributes={{
        name: 'data.alt_text',
        placeholder: '',
        maxLength: 255,
        style: { height: 128 },
        value: values.data.alt_text,
        onChange: handleChange,
      }}
    />

    <Input
      label={i18n.t('creative.creasmall_set.yand_brand')}
      error={errors.data && !!errors.data.yandex_brand}
      errortext={i18n.t('errors.err47')}
      showValueCount
      inputAttributes={{
        name: 'data.yandex_brand',
        value: values.data.yandex_brand,
        placeholder: '',
        style: { height: 48 },
        maxLength: 80,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          const reg = /^[0-9,]+$/gi;

          if (value === '') {
            setFieldValue('data.yandex_brand', value);
            return;
          }

          if (reg.test(value)) {
            setFieldValue('data.yandex_brand', value);
          }
        },
      }}
    />

    <Input
      label={i18n.t('creative.creasmall_set.yand_cat')}
      showValueCount
      error={errors.data && !!errors.data.yandex_category}
      errortext={i18n.t('errors.err47')}
      inputAttributes={{
        name: 'data.yandex_category',
        placeholder: '',
        style: { height: 48 },
        maxLength: 80,
        value: values.data.yandex_category,
        onChange: (event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.target;
          const reg = /^[-0-9,]+$/gi;

          if (value === '') {
            setFieldValue('data.yandex_category', value);
            return;
          }

          if (reg.test(value)) {
            setFieldValue('data.yandex_category', value);
          }
        },
      }}
    />
  </div>
);

export default index;
