import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import css from '../../styles.module.scss';
import { ReactComponent as EmptySvg } from '../../icons/empty.svg';

interface IEmptyProps {
  handleCreateCampaign: () => void;
}  

const Empty: FC<IEmptyProps> = ({ handleCreateCampaign }) => {
    const { t } = useTranslation();
    return (
    <div className={css.empty}>
      <EmptySvg />
      <div className={css.empty_text}>{t(`ord.contracts.campagn_not_created`)}</div>
      <Button
        title={t(`campaigns_page.create_btn`)}
        buttonAttributes={{
          onClick: handleCreateCampaign,
        }}
      />
    </div>
  );
};

export default Empty;
