import CollapseWrapper from 'components/CollapseWrapper';
import React, { FC, useEffect, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import {
  createEmptyCampaign,
  getEmptyCampaign,
} from 'domains/contract/model/actions';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  useContractData,
  useEmptyCampaignData,
} from 'domains/contract/model/selectors';
import Created from './components/Created';
import Process from './components/Process';
import Empty from './components/Empty';
import Actions from './components/Actions';

interface IGridCompaignProps {
  isLoadingContractData: boolean;
}

const GridCompaign: FC<IGridCompaignProps> = ({ isLoadingContractData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { id: uuid }: { id: string } = useParams();
  const { data: emptyCampaignData } = useEmptyCampaignData();
  const { data: contractData } = useContractData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ isCampaignCreating, setIsCampaignCreating ] = useState<boolean>(false);

  const handleCreateCampaign = () => {
    setIsCampaignCreating(true);
    const params = {
      uuid,
      callbacks: {
        setIsLoading,
      },
    };
    dispatch(createEmptyCampaign(params));
  };

  const handleGetEmptyCampaign = () => {
    setIsCampaignCreating(false);
    const params = {
      uuid,
      callbacks: {
        setIsLoading,
      },
    };
    dispatch(getEmptyCampaign(params));
  };

  useEffect(() => {
    if (contractData) {
      const params = {
        uuid,
        callbacks: {
          setIsLoading,
        },
      };
      dispatch(getEmptyCampaign(params));
    }
  }, [contractData]);

  return (
    <CollapseWrapper
      title={t(`ord.contracts.campagn_grid`)}
      nameWrapper="CAMPAIDNS_GRID"
      isLoading={isLoadingContractData}
    >
      {emptyCampaignData && emptyCampaignData.status !== 'NOT_CREATED' && (
        <Actions onClickAction={handleGetEmptyCampaign} />
      )}
      {emptyCampaignData &&
        emptyCampaignData.status === 'NOT_CREATED' &&
        !isLoading && (
          <Empty handleCreateCampaign={() => handleCreateCampaign()} />
        )}
      {isLoading && <Process isCampaignCreating={isCampaignCreating}/>}
      {!isLoading &&
        emptyCampaignData &&
        emptyCampaignData.status !== 'NOT_CREATED' && (
          <Created data={emptyCampaignData} />
        )}
    </CollapseWrapper>
  );
};

export default GridCompaign;
