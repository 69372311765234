import { isProductive } from 'utils/isProductive';
import { IDspLinksReturn, TGetLinkProps } from './types';

export const useDspLinks = (): IDspLinksReturn => {
  const convertInternalId = (id: string | undefined): string =>
    id ? id.replace('#', '%23') : '';

  const getJsonDspLink = (
    data: TGetLinkProps['data'],
    type: TGetLinkProps['type'],
  ) =>
    isProductive()
      ? `http://192.168.13.8:1200/api/${
          process.env.REACT_APP_DEV_API === 'true' ? 'v1dev' : 'v1'
        }/${type}/${convertInternalId(data?.internal_id)}`
      : `https://ads.bumlam.com/api/${
          process.env.REACT_APP_DEV_API === 'true' ? 'v1dev' : 'v1'
        }/${type}/${convertInternalId(data?.internal_id)}`;

  const getStatusJsonDspLink = (
    data: TGetLinkProps['data'],
    type: TGetLinkProps['type'],
  ) =>
    isProductive()
      ? `http://192.168.13.8:1200/api/${
          process.env.REACT_APP_DEV_API === 'true' ? 'v1dev' : 'v1'
        }/${type}/${convertInternalId(data?.internal_id)}/status`
      : `https://ads.bumlam.com/api/${
          process.env.REACT_APP_DEV_API === 'true' ? 'v1dev' : 'v1'
        }/${type}/${convertInternalId(data?.internal_id)}/status`;

  return {
    getJsonDspLink,
    getStatusJsonDspLink,
  };
};
