import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import Select, { components, MultiValue, OptionProps, StylesConfig, GroupBase } from 'react-select';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import cn from 'classnames';
import { TResult } from 'utils/removeTopLevelsOfJson/removeTopLevelsOfJson';
import LimitedChipsContainer from './LimitedChipsContainer';
import Option from './Option';
import MenuList from './MenuList';
import './styles.scss';
import i18n from '../../../i18n';
import ErrorText from '../ErrorText';
import DropdownIndicator from './DropdownIndicator';

interface Props<T> {
  propsValues: TResult[] | Record<string, string>[] | null;
  isError: boolean;
  errorText?: string;
  placeholder: string;
  /* customValue?: GOptions[]; */
  formik: FormikProps<T>;
  fieldName: string;
  hideIcon?: boolean;
  isDisabled?: boolean;
  customHandleChange?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  customContainer?: boolean;
  chipsLimit?: number;
  customOption?: React.ComponentType<
    OptionProps<any, true, GroupBase<IOption2>>
  >;
}

interface ISelectedOption {
  label: string;
  value: string;
  isAdditional?: boolean;
}

interface IOption2 {
  label: string;
  value: string;
  isFixed?: boolean;
}

function MultiSelect<N>({
  propsValues,
  placeholder,
  formik,
  fieldName,
  isError,
  errorText,
  isDisabled,
  customHandleChange,
  hideIcon = true,
  customContainer = false,
  chipsLimit = 5,
  customOption,
}: Props<N>): JSX.Element {
  const styles: StylesConfig<IOption2, true> = {
    multiValue: (base, state) =>
      state.data.isFixed
        ? { ...base, backgroundColor: 'gray' }
        : { ...base, backgroundColor: '#e3ecff' },
    multiValueLabel: (base, state) =>
      state.data.isFixed ? { ...base, color: 'white' } : base,
    multiValueRemove: (base, state) =>
      state.data.isFixed ? { ...base, display: 'none' } : base,
  };

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const ValueContainer = (props: any) => customContainer ? (
      <LimitedChipsContainer {...props} chipsLimit={chipsLimit} />
    ) : (
      <components.ValueContainer {...props} chipsLimit={chipsLimit} />
    );

  const selectWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        selectWrapperRef.current &&
        !selectWrapperRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectWrapperRef]);

  return (
    <div
      ref={selectWrapperRef}
      onClick={(e: BaseSyntheticEvent) => {
        e.stopPropagation();
        if (!e.target.closest('.multi_select__menu')) {
          setIsMenuOpen(!isMenuOpen)
        }
      }}
      className={cn('selectWrapper', {
        isError,
        isNotAllowed: isDisabled,
      })}
    >
      <Select
        isMulti
        menuIsOpen={isMenuOpen && !isDisabled}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: customOption || Option,
          MenuList,
          ValueContainer: (props) => ValueContainer(props),
          DropdownIndicator,
        }}
        classNamePrefix="multi_select"
        noOptionsMessage={() => i18n.t(`no_result`)}
        onChange={(
          selectedOptions: MultiValue<ISelectedOption>,
        ) => {
          if (customHandleChange) {
            customHandleChange(fieldName, selectedOptions);
            return;
          }
          formik.setFieldValue(fieldName, selectedOptions);
        }}
        isClearable={false}
        isDisabled={isDisabled}
        value={get(formik.values, fieldName)}
        options={propsValues || undefined}
        styles={styles}
        placeholder={placeholder}
      />
      {isError && (
        <div className="errorText">
          <ErrorText hideIcon={hideIcon} text={errorText} />
        </div>
      )}
    </div>
  );
}

export default MultiSelect;
