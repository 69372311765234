import Checkbox from 'components/UI/Checkbox/checkbox';
import { TExperimentalInfoField } from 'domains/campaign/types';
import { Form, FormikProvider } from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldValues } from './hooks';
import css from './styles.module.scss';
import BoolInput from './boolValField';
import NumberInput from './numberValField';
import StringInput from './stringValField';

interface Props {
  item: TExperimentalInfoField;
  mode: 'creo' | 'campaign';
  config_values?: Record<number, number>;
  xxhash?: string | undefined;
}

const ExperimentalInputField: FC<Props> = ({ item, xxhash, config_values, mode }) => {
  const [check, setCheck] = useState(false);
  const { t } = useTranslation();
  const { formik, onKeyHandler, setField, success } = useFieldValues(
    item,
    check,
    setCheck,
    mode,
    xxhash || '',
    config_values,
  );

  return (
    <FormikProvider value={formik}>
      <Form onKeyDown={onKeyHandler} className={css.form}>
        {item.type === 'string' && (
          <StringInput item={item} formik={formik} success={success} data={config_values}/>
        )}
        {item.type === 'bool' && <BoolInput item={item} formik={formik} />}
        {item.type === 'int' && (
          <NumberInput
            item={item}
            formik={formik}
            success={success}
            error={!!formik.errors.value}
            errortext={`${t('errors.limitsym')}`}
            decimalScale={0}
            setField={setField}
          />
        )}
        {item.type === 'float' && (
          <NumberInput
            item={item}
            formik={formik}
            success={success}
            error={!!formik.errors.value}
            errortext={`${t('errors.limitsym')}`}
            decimalScale={6}
            setField={setField}
          />
        )}
        {item.default_value !== '' && (
          <Checkbox
            label={t(`campaigns_page.experimental.by_default`)}
            inputAttributes={{
              type: 'checkbox',
              checked: check,
              onChange: () => setCheck((prev) => !prev),
              onClick: () => {
                if (!check) {
                  formik.handleSubmit();
                }
              },
            }}
          />
        )}
      </Form>
    </FormikProvider>
  );
};

export default ExperimentalInputField;
