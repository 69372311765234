import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import {
  workerCreateContract,
  workerCreateEmptyCampaign,
  workerGetContract,
  workerGetEmptyCampaign,
  workerSendErir,
  workerUpdateContract,
} from './workers';
import {
  createContract,
  createEmptyCampaign,
  getContract,
  getEmptyCampaign,
  sendErir,
  updateContract,
} from '../actions';

export default function* watchContract(): SagaIterator {
  yield takeLatest(getContract, workerGetContract);
  yield takeLatest(createContract, workerCreateContract);
  yield takeLatest(updateContract, workerUpdateContract);
  yield takeLatest(sendErir, workerSendErir);
  yield takeLatest(createEmptyCampaign, workerCreateEmptyCampaign);
  yield takeLatest(getEmptyCampaign, workerGetEmptyCampaign);
}
