import React, { FC } from "react";
import InputAutoSave from "components/UI/InputAutoSave";
import { TExperimentalInfoField , PExperimental } from "domains/campaign/types";
import { FormikProps } from "formik";



interface Props {
    item: TExperimentalInfoField,
    formik: FormikProps<PExperimental>,
    success: boolean,
    data?: Record<number, number>,
}

const StringInput: FC<Props> = ({item, formik, success, data}) => {
  const value = data ? data[item.id] : '';
      return <InputAutoSave
        value={String(formik.values.value)}
        defaultValue={String(value || item.default_value)}
        name="value"
        maxLength={120}
        setFieldValue={formik.setFieldValue}
        isSaved={success}
        isError={false}
        label={item.title}
      />
}

export default StringInput;