import { useEffect, useRef, useState } from 'react';
import useSearchField from 'hooks/useSearchField';
import { useUserTheme } from 'domains/user/model/selectors';
import { useDispatchApp } from 'redux/rootSelectors';
import { CounteragentItem } from 'domains/counteragent/model/types';
import { setCounteragentsTabs } from 'domains/storage/model/reducer';
import { useStorageCounteragentsTabs } from 'domains/storage/model/selectors';
import { TCounteragentStatus } from '../types';
import { useCounteragentsList } from '../selectors';
import { getCounteragentsList, getOrdList } from '../actions';

type TReturnProps = {
  currentTab: TCounteragentStatus | 'ALL';
  search: string;
  toggleSearch: boolean;
  onOpenSearch: () => void;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseSearch: () => void;
  isThemeExtended: boolean;
  isThemeFull: boolean;
  isLoading: boolean;
  couneragentView: CounteragentItem[] | undefined;
  handleChangeTab: (tab: TCounteragentStatus | 'ALL') => void;
  searchRef: React.RefObject<HTMLInputElement>;
  searchWrapperRef: React.RefObject<HTMLDivElement>;
};

export const useCounteragentsData = (): TReturnProps => {
  const searchRef = useRef<HTMLInputElement>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const status = useStorageCounteragentsTabs();

  const dispatch = useDispatchApp();

  const [currentTab, setCurrentTab] = useState<TCounteragentStatus | 'ALL'>(status);

  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef, searchWrapperRef);

  const { isThemeExtended, isThemeFull } = useUserTheme();

  const { data: counteragentsList, isLoading } = useCounteragentsList();

  const couneragentView = counteragentsList?.filter(
    (counteragent) =>
      counteragent.name.toLowerCase().includes(search.toLowerCase()) ||
      counteragent.inn?.toLowerCase().includes(search.toLowerCase()),
  );

  const handleChangeTab = (tab: TCounteragentStatus | 'ALL') => {
    if (tab === 'ALL') {
      setCurrentTab(tab);
      dispatch(setCounteragentsTabs(tab));
      dispatch(
        getCounteragentsList({
          filter: {
            status: ['ACTIVE', 'DELETED', 'ON_APPROVAL'],
          },
        }),
      );
    } else {
      setCurrentTab(tab);
      dispatch(setCounteragentsTabs(tab));
      dispatch(
        getCounteragentsList({
          filter: {
            status: [tab],
          },
        }),
      );
    }
  };
  
  useEffect (() => {
    dispatch(
      getCounteragentsList({
        filter: {
          status:
            status === 'ALL' ? ['ACTIVE', 'DELETED', 'ON_APPROVAL'] : [status],
        },
      }),
    );
    dispatch(getOrdList());
  }, []);

  return {
    currentTab,
    search,
    toggleSearch,
    onOpenSearch,
    onChangeSearch,
    onCloseSearch,
    isThemeExtended,
    isThemeFull,
    isLoading,
    couneragentView,
    handleChangeTab,
    searchRef,
    searchWrapperRef
  };
};
