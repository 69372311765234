import React, { FC, useState } from 'react';
import { Form, FormikProvider } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import equal from 'deep-equal';
import EditCard from 'components/UI/EditCard';
import Input from 'components/UI/Input/input';
import { useGetFieldConfigValue } from 'domains/creative/creatives/hooks/useGetFieldConfigValue';
import { useCheckGridEridInfo } from 'domains/creative/creatives/hooks/useCheckGridEridInfo';
import EridWarning from 'components/EridWarning';
import { useProjectInfo } from 'domains/project/hooks';
import { getSizes } from '../../../../../../uploads/helpers';
import css from '../styles.module.scss';
import { CreativeDetailTeaser, CreativeDetail } from '../../../../../types';
import { useFormTeaser } from './hooks';
import UploadImage from '../../../../../../uploads/UploadImage';

interface Props {
  xxhash: CreativeDetail['xxhash'];
  data: CreativeDetailTeaser['data'];
  config_values: CreativeDetail['config_values'];
}

const FormType: FC<Props> = ({ data, xxhash, config_values }) => {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const { data: project } = useProjectInfo();
  const erid = useGetFieldConfigValue('erid', 'TEASER');

  const { formik, handlerFormikReset, handlerFormikSubmit } = useFormTeaser({
    xxhash,
    data,
    setEdit,
    erid: (erid && config_values[erid.id]) || '',
  });

  const { isDisableSaveButton, setIsDisableSaveButton } = useCheckGridEridInfo({
    eridData: erid,
    eridVal: formik.values.erid,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          noHover
          noShadow
          hasErrors={
            Object.keys(formik.errors).length > 0 || isDisableSaveButton
          }
          onResetForm={handlerFormikReset}
          onSave={handlerFormikSubmit}
          notEqual={!equal(formik.values, data)}
          setEdit={setEdit}
        >
          <div className={css.form}>
            <UploadImage
              keyUploadFile="teaser"
              name="iconMask"
              label={t('creative.creasmall_set.icon_word')}
              hintText={
                <span>
                  {t('creative.creasmall_set.teaser_formats3')}
                  <br />
                  {t('creative.creasmall_set.teaser_formats2')}
                </span>
              }
              preview={getSizes(
                formik.values.iconMask,
                formik.values.iconFormats,
              )}
              setFieldValue={formik.setFieldValue}
              validateForm={formik.validateForm}
              edit={edit}
              hasEdit
              maxSizeFile={6}
              file_ext={['.png', '.jpeg', '.jpg']}
              typeSection="teaser"
            />
            <UploadImage
              keyUploadFile="teaser"
              name="imgMask"
              label={t('creative.creasmall_set.image_word')}
              hintText={
                <span>
                  {t('creative.creasmall_set.teaser_formats1')}
                  <br />
                  {t('creative.creasmall_set.teaser_formats4')}
                </span>
              }
              preview={getSizes(
                formik.values.imgMask,
                formik.values.imgFormats,
              )}
              setFieldValue={formik.setFieldValue}
              validateForm={formik.validateForm}
              edit={edit}
              minResolution={100}
              hasEdit
              customLinkTitle={t('creative.push_text.t9')}
              file_ext={['.png', '.jpeg', '.jpg']}
              typeSection="teaser"
            />
            {project && project?.ord_registration && (
              <Input
                notField={edit}
                label={t(`creative.general_description_label`)}
                isTextarea
                tooltip={{
                  text: (
                    <Trans
                      i18nKey="creative.general_description_hint"
                      components={{ br: <br /> }}
                    />
                  ),
                  colorIcon: '#222222',
                  sizeIcon: 20,
                }}
                showValueCount
                error={!!formik.errors.description}
                errortext={formik.errors.description}
                inputAttributes={{
                  name: 'description',
                  placeholder: t(`creative.general_description_place`),
                  maxLength: 1024,
                  value: formik.values.description,
                  onChange: formik.handleChange,
                }}
              />
            )}
            <Input
              notField={edit}
              label={t('creative.creasmall_set.title_word')}
              showValueCount
              inputAttributes={{
                name: 'title',
                placeholder: '',
                maxLength: 100,
                value: formik.values.title,
                onChange: formik.handleChange,
              }}
            />
            <Input
              notField={edit}
              isTextarea
              showValueCount
              label={t('creative.creasmall_set.text_word')}
              inputAttributes={{
                name: 'text',
                type: 'textarea',
                placeholder: '',
                maxLength: 200,
                value: formik.values.text,
                onChange: formik.handleChange,
              }}
            />
            {erid && (
              <>
                <Input
                  label={erid.title}
                  notField={edit}
                  showValueCount
                  error={!!formik.errors.erid}
                  errortext={formik.errors.erid}
                  inputAttributes={{
                    name: 'erid',
                    disabled: !edit,
                    maxLength: 80,
                    value: formik.values.erid,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      formik.setFieldValue('erid', e.target.value.trim()),
                  }}
                />
                {formik.values.erid && (
                  <EridWarning
                    withAccept={edit}
                    setIsDisableSaveButton={setIsDisableSaveButton}
                    isChangeErid={config_values[erid.id] !== formik.values.erid}
                  />
                )}
              </>
            )}
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default FormType;
