import React, { FC } from 'react';
import LoginLanguage from 'components/LoginLanguage';
import Loader from 'components/UI/Loader';
import { getValueByName } from 'domains/project/model/utils/getbyLinkFile';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import cn from 'classnames';
import { useProjectTheme } from 'domains/project/model/selectors';
import css from '../styles.module.scss';
import { useProjectInfo } from '../../project/hooks';
import ChristmasCat from '../../../assets/christmasCat.png';

const Container: FC = ({ children }) => {
  const { data: project, isLoading } = useProjectInfo();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const isNewYear = useProjectTheme();
  
  return (
    <div
      className={cn(css.container, {
        _isNewYear: isNewYear,
      })}
    >
      <div
        className={css.left}
        style={{
          backgroundImage: `url(${project ? project.background : ''})`,
        }}
      >
        {project && project.logo_medium && (
          <div className={css.logo}>
            <img src={project.logo_medium} alt="logo" />
          </div>
        )}

        {project && project.slogan && (
          <div className={css.slogan}>
            <span>{project.slogan}</span>
          </div>
        )}
      </div>
      <div className={css.right}>
        {isLoading && (
          <div className={css.loading}>
            <Loader />
          </div>
        )}
        {project && children}

        {project && !isLoading && <LoginLanguage />}

        {project &&
          getValueByName(project.files, 'offer') &&
          location.pathname === '/auth/sign-in' && (
            <div className={css.offer}>
              <a
                href={
                  i18n.language === 'ru'
                    ? getValueByName(project.files, 'offer')
                    : getValueByName(project.files, 'offer_en') ||
                      getValueByName(project.files, 'offer')
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('form.common.offer__link__auth')}
              </a>
            </div>
          )}
        {true && (
          <img
            src={ChristmasCat}
            alt="ChristmasCat"
            className={css.christmasCat}
          />
        )}
      </div>
    </div>
  );
};

export default Container;
