import React, { FC, useMemo } from 'react';
import { Field, FieldProps } from 'formik';
import { Element } from 'react-scroll';
import Card from 'components/UI/Card';
import InputAutoSave from 'components/UI/InputAutoSave';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import TagAutoSave from 'components/UI/TagAutoSave';
import getErrorText from 'utils/getErrorText';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import { getCurrentVariableField } from 'utils/getCurrentVariableField';
import { createOptionsForConfigSelect } from 'utils/createOptionsForConfigSelect';
import { checkedLocalization } from 'utils/checkedLocalization';
import { FieldLength } from 'shared/consts';
import { someGroups } from 'utils/statics/everyGroups';
import Button from 'components/UI/Buttons/buttons';
import BaseModal from 'components/UI/BaseModal';
import NewSelect from 'components/UI/NewSelect';
import { useProjectInfo } from 'domains/project/hooks';
import { useDetectBlockScroll } from 'utils/useDetectBlockScroll';
import { CreatableCustomSelect } from 'components/UI/NewSelect/CreatableCustomSelect';
import { useLocation } from 'react-router';
import {
  CampaignGeneralComponentProps,
  RGetPersonal,
  TExperimentalInfoField,
} from 'domains/campaign/types';
import { useClientInfo } from 'domains/client/model/selectors';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import i18n from '../../../../../../i18n';
import LabelField from '../../../../../../components/UI/LabelField';
import Help from '../../../../../../components/Help';
import css from '../styles.module.scss';
import { selectDefault } from '../../../../../../utils/selectDefault';
import { isFieldInObject } from '../../../../../../utils/isFieldInObject';
import { SelectField } from '../../../../../../types/general';
import { FetchedDataObj } from '../../../../../../redux/fetchedData';
import PartnerField from './CampaignFields/PartnerField';
import ContractField from './CampaignFields/ContractField';
import OfferField from './CampaignFields/OfferField';

type CampaignProps = Pick<
  CampaignGeneralComponentProps,
  'formik' | 'autoSubmit' | 'savedField' | 'setField' | 'handlerInput'
> & {
  campaign: RGetPersonal | null;
  clients: SelectField[];
  handlerUpdateTags: (value: string[]) => void;
  tags: string[] | null;
  handlerRevertTags?: () => void;
  isSavedTags?: string[];
  LTUPastTags?: number;
  errorTags?: FetchedDataObj<string[]>['error'];
  clientDisable: boolean;
  titleAutoFocus?: boolean;
  locked?: boolean;
  experimentalInfo?: TExperimentalInfoField[] | null;
  offersOptions?: SelectField[];
  contractsOptions?: SelectField[];
  type: 'EDIT' | 'CREATE';
};

const Campaign: FC<CampaignProps> = ({
  autoSubmit,
  formik,
  campaign,
  handlerRevertTags,
  isSavedTags,
  errorTags,
  handlerUpdateTags,
  tags,
  handlerInput,
  LTUPastTags,
  savedField,
  clients,
  setField,
  clientDisable,
  titleAutoFocus,
  locked,
  experimentalInfo,
  offersOptions,
  contractsOptions,
  type,
}) => {
  const { data: user } = useUserInfo();
  const { getValueFromSettingForKey } = useUserTheme();
  const { data: projectData } = useProjectInfo();
  const location = useLocation();
  const { data: partner } = useClientInfo();
  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();

  const showContractField = (): boolean => {
    if (projectData && partner && breadcrumbs?.Client) {
      return (
        user?.partner.type !== 'CLIENT' &&
        someGroups(user, 'self_user', 'owner') &&
        projectData?.ord_registration &&
        !!partner.ord_registration_creatives
      );
    }
    return false;
  };

  const showOrdNameField = (): boolean => {
    if (projectData && partner && breadcrumbs?.Client) {
      return !!(
        user?.partner.type !== 'CLIENT' &&
        someGroups(user, 'owner') &&
        projectData?.ord_registration &&
        partner.ord_registration_creatives
      );
    }
    return false;
  };

  const categoriesCampaign = useMemo(
    () => getCurrentVariableField(experimentalInfo, 'categories_campaign'),
    [experimentalInfo],
  );

  const categoriesOptions = useMemo(
    () => createOptionsForConfigSelect(categoriesCampaign),
    [categoriesCampaign],
  );

  const { endScrollingBlock } = useDetectBlockScroll(true);

  const nameCampaignInOrd = [
    {
      label: 'Тест РК №1',
      value: 'Тест РК №1',
    },
    {
      label: 'Тест РК №2',
      value: 'Тест РК №2',
    },
    {
      label: 'Тест РК №3',
      value: 'Тест РК №3',
    },
  ];

  return (
    <Card
      noHover
      style={{ padding: '28px 24px' }}
      className={css.card}
      isUseAccess
    >
      <h3>
        {i18n.t('campaigns_page.campaign_settings.Campaign_name')}{' '}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={200}
            title={i18n.t('campaigns_page.campaign_settings.Campaign_name')}
            helpattributes={{
              link: i18n.t(
                'campaigns_page.campaign_settings.campaign_prompt.main.link',
              ),
              sizeIcon: 18,
            }}
          />
        )}
      </h3>
      <div className={css.form}>
        <PartnerField
          formik={formik}
          clients={clients}
          clientDisable={clientDisable}
          autoSubmit={autoSubmit}
        />
        {showContractField() && (
          <ContractField
            formik={formik}
            contractsOptions={contractsOptions}
            type={type}
          />
        )}
        <OfferField
          formik={formik}
          offersOptions={offersOptions}
          autoSubmit={autoSubmit}
        />
        <InputAutoSave
          label={i18n.t('campaigns_page.campaign_settings.Advertiser_name')}
          defaultValue={campaign?.advertiser_name}
          value={formik.values.advertiser_name}
          name="advertiser_name"
          placeholder={i18n.t(
            'campaigns_page.campaign_settings.Advertiser_name',
          )}
          maxLength={FieldLength.advertiserName}
          setFieldValue={formik.setFieldValue}
          handlerInputEnter={handlerInput}
          isError={!!formik.errors.advertiser_name}
          errorText={formik.errors.advertiser_name}
          isSaved={isFieldInObject(savedField, 'advertiser_name')}
          isDisable={locked}
        />

        <Element name="title">
          <InputAutoSave
            name="title"
            label={i18n.t('campaigns_page.create_campaign.name')}
            defaultValue={campaign?.title}
            value={formik.values.title}
            maxLength={FieldLength.campaignTitle}
            handlerInputEnter={handlerInput}
            setFieldValue={formik.setFieldValue}
            isError={!!formik.errors.title}
            errorText={formik.errors.title}
            isSaved={isFieldInObject(savedField, 'title')}
            autoFocus={titleAutoFocus}
            isDisable={locked}
          />
        </Element>
        {showOrdNameField() && (
          <LabelField label={i18n.t(`campaigns_page.create_campaign.ord_name`)}>
            <Field name="ord_name">
              {({ form, field }: FieldProps) => (
                <CreatableCustomSelect
                  field={field}
                  form={form}
                  autoSubmit={!location.pathname.includes('create-campaign')}
                  selectAttributes={{
                    isClearable: true,
                    options: nameCampaignInOrd,
                    defaultValue:
                      formik.values.ord_name !== null
                        ? {
                            label: formik.values.ord_name,
                            value: formik.values.ord_name,
                          }
                        : null,
                    onInputChange: (val) =>
                      val.length <= 150 ? val : val.substring(0, 150),
                  }}
                  viewStyle="Horizontal"
                />
              )}
            </Field>
          </LabelField>
        )}
        {categoriesCampaign && (
          <LabelField
            label={checkedLocalization(
              `campaigns_page.create_campaign.${categoriesCampaign.field}`,
              categoriesCampaign.title,
            )}
          >
            <Field name={`config_values.${categoriesCampaign.id}`}>
              {({ field, form }: FieldProps) => (
                <NewSelect
                  autoSubmit={autoSubmit}
                  form={form}
                  field={field}
                  isClearable
                  selectAttributes={{
                    options: categoriesOptions,
                    placeholder: i18n.t(`project_page.forms.not_selected`),
                    value:
                      formik.values.config_values &&
                      categoriesOptions &&
                      selectDefault(
                        String(
                          formik.values.config_values[
                            String(categoriesCampaign.id)
                          ],
                        ),
                        categoriesOptions,
                      ),
                    isClearable: true,
                    closeMenuOnScroll:
                      !endScrollingBlock && endScrollingBlock !== null
                        ? () => true
                        : () => false,
                  }}
                  heightCustom={32}
                  viewStyle="Horizontal"
                />
              )}
            </Field>
          </LabelField>
        )}

        {campaign && (
          <LabelField label={i18n.t('campaigns_page.create_campaign.status')}>
            {campaign?.status === 'ARCHIVE' ? (
              <ToggleSwitch
                hideToggleBgActive
                activateText={i18n.t('clients_page.archive_clients')}
                onChange={() => {
                  setField(
                    'status',
                    formik.values.status !== 'STOPPED' ? 'STOPPED' : 'ARCHIVE',
                  );
                }}
                checked={formik.values.status !== 'STOPPED'}
                disabled={locked}
              />
            ) : (
              <ToggleSwitch
                onChange={() => {
                  setField(
                    'status',
                    formik.values.status !== 'STOPPED' ? 'STOPPED' : 'LAUNCHED',
                  );
                }}
                checked={formik.values.status !== 'STOPPED'}
                disabled={locked}
              />
            )}
          </LabelField>
        )}

        {tags && (
          <TagAutoSave
            tagsData={tags}
            handlerData={handlerUpdateTags}
            handleRevert={handlerRevertTags}
            LTUPast={LTUPastTags}
            isSaved={isSavedTags}
            isError={errorTags?.isError}
            isErrorTags={errorTags?.fields}
            errorText={getErrorText(errorTags?.code)}
            theme="horizontal"
            isDisable={locked}
          />
        )}
      </div>
      {false && (
        <BaseModal
          onClose={() => null}
          isOpen
          actions={
            <>
              <Button
                transparent
                title={i18n.t('just_words.exit_page_no')}
                height={40}
                autoWidth
              />
              <Button
                title={i18n.t('just_words.exit_page_yes')}
                height={40}
                autoWidth
              />
            </>
          }
          title={i18n.t('just_words.exit_page')}
        >
          {i18n.t(`just_words.exit_page_currect`)}
        </BaseModal>
      )}
    </Card>
  );
};

export default React.memo(Campaign);
