import React from 'react';
import cn from 'classnames';
import css from './styles.module.scss';
import Loader from '../../../components/UI/Loader';
import EmptyList from '../../../components/Target/Item/EmptyList';
import DataGrid from '../grid';
import { PDataGrid, TargetKey, TLeftD } from '../model/types';
import { ILimits } from '../types';

type PBodyItem = {
  isLoading: boolean;
  isSourceLikeLogic: boolean;
  leftD: TLeftD[];
  use_settings: boolean;
  type: TargetKey;
  view?: number;
  onlyChecked: boolean;
  read_only?: boolean;
  /** открыть модал */
  openModal: () => void;
  /** Показать - скрыть статистику */
  showStatistic?: boolean;
  setLeftD: React.Dispatch<React.SetStateAction<TLeftD[]>>;
  checkItemLimits: (limitsObj: ILimits | undefined) => boolean;
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  showHintEvent: () => void;
  setTouchedItemsToSave: React.Dispatch<React.SetStateAction<string[]>>;
  /** Заблокировать возможности добавлени/изменения */
  isLocked?: boolean;
};
export function BodyItem({
  use_settings,
  isLoading,
  leftD,
  type,
  view,
  columns,
  total,
  data,
  search,
  isSourceLikeLogic,
  customFilter,
  isEmpty,
  onlyChecked,
  onChekedLD,
  onRemoveItem,
  openModal,
  read_only,
  onChangeContextBox,
  onShowContextBox,
  statisticsIsGroup,
  isError,
  downloadState,
  fetchStatistics,
  showStatistic,
  showPageContextRow,
  showPageAppRow,
  setLeftD,
  checkItemLimits,
  setShowSaveButton,
  showHintEvent,
  onChangeCellData,
  setTouchedItemsToSave,
  isLocked,
}: PBodyItem & PDataGrid): JSX.Element | null {
  if (isLoading) {
    return (
      <div className={css.loader}>
        <Loader />
      </div>
    );
  }

  if (!isLoading && !isError && leftD.length === 0) {
    return (
      <div
        className={cn(css.empty_wrapper, {
          isDisabled: !use_settings || view === 1 || isLocked,
        })}
        onClick={showHintEvent}
      >
        <div
          className={css.empty}
          onClick={() => {
            if (view !== 1 && type !== 'source' && type !== 'network') {
              openModal();
            }
          }}
        >
          <EmptyList
            editing
            type={type}
            view={view}
            showStatistic={showStatistic}
          />
        </div>
      </div>
    );
  }

  return (
    <DataGrid
      showPageContextRow={showPageContextRow}
      showPageAppRow={showPageAppRow}
      downloadState={downloadState}
      statisticsIsGroup={statisticsIsGroup}
      use_settings={use_settings}
      columns={columns}
      total={total}
      data={data}
      view={view}
      leftD={leftD}
      search={search}
      customFilter={onlyChecked ? customFilter : undefined}
      isSourceLikeLogic={isSourceLikeLogic}
      onlyChecked={onlyChecked}
      isEmpty={isEmpty}
      type={type}
      onChekedLD={onChekedLD}
      onRemoveItem={onRemoveItem}
      read_only={read_only}
      onChangeContextBox={onChangeContextBox}
      onShowContextBox={onShowContextBox}
      showStatistic={showStatistic}
      isError={isError}
      fetchStatistics={fetchStatistics}
      setLeftD={setLeftD}
      checkItemLimits={checkItemLimits}
      setShowSaveButton={setShowSaveButton}
      showHintEvent={showHintEvent}
      onChangeCellData={onChangeCellData}
      setTouchedItemsToSave={setTouchedItemsToSave}
    />
  );
}
