import { SelectField } from '../../types/general';

export type TSelectDefault = (
  value: string,
  options: SelectField[],
) => SelectField[];

type TSelectLabel = (value: string | undefined, options: SelectField[]) => string | null;

export const selectDefault: TSelectDefault = (value, options) =>
  options?.filter((v) => v.value === value);

export const selectLabel: TSelectLabel = (value, options) => {
  if(!value) return null;
  const element = options.find((field) => field.value === value);
  if (element) {
    return element.label;
  }
  return value;
};
