import { useLocation, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment';
import { useDispatchApp } from 'redux/rootSelectors';
import { setCampaignsTabs } from 'domains/storage/model/reducer';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import { fetchPersonalData } from '../model/actions';
import { useIsGroup } from '../../user/hooks';
import { KeysSettings, useStateValue } from '../../../utils/context';
import { PeriodEnum } from '../../../types/containers';
import { useDelLastIdCampaigns, useSetLastIdCampaigns } from '../../../utils/useLastIdHelpers/useLastIdCampaign';
import { useUserInfo } from '../../user/model/selectors';
import { useCampaignInfo } from '../model/selectors';
import { TID } from '../../../types/general';

type TFUseDataCampaignMain = () => {
  id: string;
  location: ReturnType<typeof useLocation>;
  user: ReturnType<typeof useUserInfo>['data'];
  isOwner: ReturnType<typeof useIsGroup>['isOwner'];
  isEperimentalOwner: ReturnType<typeof useIsGroup>['isEperimentalOwner'];
  isWithCreatives: ReturnType<typeof useIsGroup>['isWithCreatives'];
  isWithTargets: ReturnType<typeof useIsGroup>['isWithTargets'];
  isSelfUser: ReturnType<typeof useIsGroup>['isSelfUser'];
  campaign: ReturnType<typeof useCampaignInfo>['data'];
  goBack: () => string;
  hasNavigation: () => boolean;
  path: string;
  isUserLoading: ReturnType<typeof useUserInfo>['isLoading'];
  isCampaignError: boolean;
};

export const useDataCampaignMain: TFUseDataCampaignMain = () => {
  const dispatch = useDispatchApp();
  const location = useLocation();
  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();
  const { params, path } = useRouteMatch<TID>();

  const { data: user, isLoading: isUserLoading } = useUserInfo();

  const { data: campaign, error } = useCampaignInfo();

  useSetLastIdCampaigns(campaign?.status);

  const { isOwner, isWithCreatives, isWithTargets, isSelfUser, isEperimentalOwner } = useIsGroup();

  useEffect(() => {
    dispatch(fetchPersonalData(params.id));
  }, [params.id]);

  useEffect(() => {
    if (campaign?.status) {
      dispatch(setCampaignsTabs(campaign?.status))
    }
  }, [campaign?.status])
  
  const onDelLastIdCampaigns = useDelLastIdCampaigns();

  useEffect(() => () => onDelLastIdCampaigns(), []);

  const goBack: ReturnType<TFUseDataCampaignMain>['goBack'] = () => {
    if (!campaign) {
      return '/main/campaigns';
    }

    return `/main/campaigns?client_id=${breadcrumbs?.Client?.xxhash}`;
  };
  const hasNavigation: ReturnType<TFUseDataCampaignMain>['hasNavigation'] =
    () => {
      if (location.pathname.includes('creatives/')) {
        return false;
      }

      if (location.pathname.includes('general-reports')) {
        return false;
      }

      return !location.pathname.includes('creative-add');
    };

  const { state, dispatch: dispatchLocalStore } = useStateValue();

  useEffect(() => {
    if (campaign) {
      if (state.settings[KeysSettings.CAMPAIGN].type === 'all_time') {
        dispatchLocalStore({
          type: 'setSettings',
          payload: {
            item: {
              type: PeriodEnum.ALL_TIME,
              period: {
                from: moment(campaign.date_start.date).format('YYYY-MM-DD'),
                to: moment(campaign.date_end.date).format('YYYY-MM-DD'),
              },
            },
            key: KeysSettings.CAMPAIGN,
          },
        });
      }
      if (state.settings[KeysSettings.CAMPAIGN].type === 'all_time') {
        dispatchLocalStore({
          type: 'setSettings',
          payload: {
            item: {
              type: PeriodEnum.ALL_TIME,
              period: {
                from: moment(moment().subtract(365, 'days')).format(
                  'YYYY-MM-DD',
                ),
                to: moment(new Date()).format('YYYY-MM-DD'),
              },
            },
            key: KeysSettings.CAMPAIGN,
          },
        });
      }
      if (state.settings[KeysSettings.CAMPAIGN].type === 'all_time') {
        dispatchLocalStore({
          type: 'setSettings',
          payload: {
            item: {
              type: PeriodEnum.ALL_TIME,
              period: {
                from: moment(campaign.date_start.date).format('YYYY-MM-DD'),
                to: moment(campaign.date_end.date).format('YYYY-MM-DD'),
              },
            },
            key: KeysSettings.CAMPAIGN,
          },
        });
      }
    }
  }, [campaign?.xxhash]);

  return {
    id: params.id,
    location,
    user,
    isOwner,
    isEperimentalOwner,
    isSelfUser,
    isWithCreatives,
    isWithTargets,
    campaign,
    goBack,
    hasNavigation,
    path,
    isUserLoading,
    isCampaignError: error.isError,
  };
};
