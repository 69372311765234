import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import css from './styles.module.scss';
import { RNavigation } from '../../../types/general';
import SwitchItemLoading from './loadingSwitchItem';

const SwitchItem: FC<RNavigation> = ({
  showNavigation,
  prevLink,
  nextLink,
  isLoading,
  count,
  isDisableLink,
  onCloseSearch,
}) => {
  if (isLoading || (!count && !showNavigation)) {
    return <SwitchItemLoading />;
  }

  if (count || showNavigation) {
    return (
      <div className={css.switch}>
        <Link
          onClick={() => {
            localStorage.removeItem('toMain');
            if (onCloseSearch) {
              onCloseSearch();
            }
          }}
          to={prevLink}
          className={cn(css.button, {
            isDisabled: (isLoading && !prevLink) || isDisableLink.prev || count <= 1,
          })}
        >
          <FiChevronLeft size={20} />
        </Link>

        <Link
          onClick={() => {
            localStorage.removeItem('toMain');
            if (onCloseSearch) {
              onCloseSearch();
            }
          }}
          to={nextLink}
          className={cn(css.button, {
            isDisabled: (isLoading && !nextLink) || isDisableLink.next || count <= 1,
          })}
        >
          <FiChevronRight size={20} />
        </Link>
      </div>
    );
  }

  return <></>;
};

export default SwitchItem;
