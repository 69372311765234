import i18next from 'i18next';
import { FormikErrors } from 'formik';
import { CreativeDetail, TCreativeHTML } from '../../../../types';

export type TFormHTML = {
  html_code: TCreativeHTML['html_code'];
  size: CreativeDetail['size'];
  erid?: string;
  width?: number;
  height?: number;
  imgMask?: string;
  imgFormats?: string[];
  description: string;
};

type TFValidate = (v: TFormHTML) => FormikErrors<TFormHTML>;

const checkSizes = (size: string) => size && !size.includes('0x0');

export const validate: TFValidate = (values) => {
  const errors: FormikErrors<TFormHTML> = {};

  if (values.erid) {
    const regExp = /^[a-zA-Z0-9]+$/
    if (!regExp.test(values.erid)) {
      errors.erid = i18next.t('errors.err_value')
    }
  }

  if (values.description) {
    if (values.description.length > 1024) {
      errors.description = i18next.t('Больше 1024 символов');
    }
  }

  if (!checkSizes(values.size)) {
    if (Number(values.width) > 10000) {
      errors.width = `${i18next.t('errors.limit_symbol.start')}10000${i18next.t(
        'errors.limit_symbol.pixel',
      )}`;
    }

    if (Number(values.height) > 10000) {
      errors.height = `${i18next.t(
        'errors.limit_symbol.start',
      )}10000${i18next.t('errors.limit_symbol.pixel')}`;
    }

  } else {
    const size = values.size.split('x');

    if (Number(size[0]) > 10000 || Number(size[1]) > 10000) {
      errors.size = `${i18next.t('errors.limit_symbol.start')}10000${i18next.t(
        'errors.limit_symbol.pixel',
      )}`;
    }
  }

  return errors;
};