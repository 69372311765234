import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-use';
import { StylesConfig } from 'react-select';
import { ClientsList, RClients } from '../../../domains/clients/types';
import { AppDispatch, AppState } from '../../../redux/rootReducer';
import { ClientsState, fetchClients } from '../../../domains/clients/reducer';
import { UserState } from '../../../domains/user/reduser';
import { AgenciesList } from '../../../domains/agencies/api';
import {
  AgenciesState,
  fetchAgencies,
} from '../../../domains/agencies/reducer';

interface OptionTypeBase {
  label: string;
  value: any;
}

export type IsMulti = false;

type IUseDataMultiSelect = {
  autoDisable: boolean;
  data: MultiSelectType[];
  isLoading: boolean;
  defaultValue: MultiSelectType;
  parent_url_xxhash: string | null;
  selectStyles: StylesConfig<OptionTypeBase, IsMulti>;
};
export type MultiSelectType = RClients & {
  value: string;
  label: string;
};
export type ParamTypeMyltiSelect = {
  type: 'CLIENTS' | 'AGENCIES';
  parent_id?: string;
  isArchive: boolean;
  theme?: string;
};
export const useDataMultiSelect = ({
  type,
  parent_id,
  isArchive,
  theme,
}: ParamTypeMyltiSelect): IUseDataMultiSelect => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const urlSearchParams = new URLSearchParams(location.search);
  const tempClients = useSelector<AppState, ClientsState['tempClients']>(
    (state) => state.clientsReducer.tempClients,
  );
  const tempAgencies = useSelector<AppState, AgenciesState['tempAgencies']>(
    (state) => state.agenciesReducer.tempAgencies,
  );
  const tempPersonal = useSelector<AppState, UserState['personalInfo']>(
    (state) => state.userReducer.personalInfo,
  );
  const user = tempPersonal.get('data')?.partner;

  const getLoading = (paramType: ParamTypeMyltiSelect['type']): boolean => {
    let isLoading = false;
    if (paramType === 'AGENCIES') {
      isLoading = tempAgencies.get('isLoading');
    }
    if (paramType === 'CLIENTS') {
      isLoading = tempClients.get('isLoading');
    }
    return isLoading;
  };

  const getData = (
    types: ParamTypeMyltiSelect['type'],
  ): ClientsList | AgenciesList => {
    if (types === 'CLIENTS') {
      return tempClients.get('data') || [];
    }
    return tempAgencies.get('data') || [];
  };

  const data = getData(type).filter((item) =>
    isArchive
      ? item.status !== 'DELETED'
      : item.status === 'STOPPED' || item.status === 'LAUNCHED',
  );

  const dataNew: MultiSelectType[] = [
    ...data.map((item): MultiSelectType => ({ ...item, label: item.title, value: item.title })),
  ];

  const defaultValue = useMemo(() => {
    if (parent_id) {
      const item = dataNew.filter(
        (selectType) => selectType.id === parent_id,
      ) as MultiSelectType[];
      return { ...item[0] };
    }
    if (location.search && dataNew.length > 0) {
      const clientId = urlSearchParams.get(
        type === 'AGENCIES' ? 'agency_id' : 'client_id',
      );
      const item = dataNew.filter(
        (selectType) => selectType.id === clientId,
      ) as MultiSelectType[];
      return { ...item[0] };
    }
    return { ...dataNew[0] };
  }, [parent_id, dataNew]);

  const autoDisable = (): boolean => {
    if (
      parent_id ||
      location.search ||
      (user?.type === 'AGENCY' && type === 'AGENCIES')
    ) {
      return true;
    }
    return false;
  };

  const clientIdUrl = urlSearchParams.get(
    type === 'AGENCIES' ? 'agency_id' : 'client_id',
  );

  useEffect(() => {
    if (type === 'CLIENTS') {
      dispatch(fetchClients({}));
    }
    if (user?.type !== 'CLIENT' && type === 'AGENCIES') {
      dispatch(fetchAgencies({}));
    }
  }, []);

  const selectStyles: StylesConfig<OptionTypeBase, IsMulti> = {
    control: (provided, state) => ({
      ...provided,
      minWidth: '100%',
      position: 'relative',
      margin: 0,
      padding: theme === 'horizontal' ? '0px' : '0px 5px',
      borderRadius: 0,
      backgroundColor:
        state.isDisabled && theme !== 'horizontal' ? '#f2f2f2' : '#fff',
      // eslint-disable-next-line no-nested-ternary
      border: state.isFocused
        ? '1px solid #025DFF'
        : theme === 'horizontal'
        ? 'none'
        : '1px solid #DDDDDD',
      boxShadow: state.isFocused ? 'none' : '',
      minHeight: theme === 'horizontal' ? '32px' : '48px',
      height: theme === 'horizontal' ? '32px' : 'auto',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#025DFF',
      },
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '22px',
      minHeight: '40px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
      color: '#222222',
      '&:hover': {
        backgroundColor: '#F6F6F6',
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: theme === 'horizontal' ? 'none' : 'flex',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme === 'horizontal' ? '#222' : '#999999',
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: '0px 2px 25px rgba(0, 0, 0, 0.05);',
      marginTop: 0,
      position: 'absolute',
      zIndex: 5,
    }),
    menuList: (provided) => ({
      ...provided,
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(0,0,0,0)',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#dddddd',
        borderRadius: '15px',
      },
    }),
  };

  return {
    parent_url_xxhash: clientIdUrl,
    data: dataNew,
    isLoading: getLoading(type),
    defaultValue,
    autoDisable: autoDisable(),
    selectStyles,
  };
};
