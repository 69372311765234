import EditCard from 'components/UI/EditCard';
import React, { FC } from 'react';
import UploadImage from 'domains/uploads/UploadImage';
import { Form, FormikProvider } from 'formik';
import css from './styles.module.scss';
import i18n from '../../../../../../i18n';
import { useProjectOffer } from './hook';

const Offer: FC = () => {
  const {
    formikContext,
    edit,
    setEdit,
    handleFormikReset,
    handleFormikSubmit,
    errors,
    values,
    setFieldValue,
    validateForm,
  } = useProjectOffer();
  return (
    <FormikProvider value={formikContext}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          hasErrors={Object.keys(errors).length > 0}
          noHover
          onResetForm={handleFormikReset}
          onSave={handleFormikSubmit}
          setEdit={setEdit}
        >
          <h3 className={css.card__title}>
            {i18n.t(`project_page.forms.documents_title`)}
          </h3>
          <UploadImage
            preview={
              typeof values.offer === 'string'
                ? {
                    file_url: values.offer,
                    client_name: values.offer,
                  }
                : values.offer
            }
            fieldType="zip"
            customLinkTitle={i18n.t(`project_page.forms.offerCustomLinkTitle`)}
            label={i18n.t(`project_page.forms.offer_label`)}
            name="offer"
            hasEdit
            edit={edit}
            setFieldValue={setFieldValue}
            validateForm={validateForm}
            keyUploadFile="project"
            file_ext={['.pdf']}
            hintText={<span>{i18n.t(`project_page.forms.offer_hint`)}</span>}
          />
          <UploadImage
            preview={
              typeof values.offer_en === 'string'
                ? {
                    file_url: values.offer_en,
                    client_name: values.offer_en,
                  }
                : values.offer_en
            }
            fieldType="zip"
            customLinkTitle={i18n.t(`project_page.forms.offerCustomLinkTitle`)}
            label={i18n.t(`project_page.forms.offer_eng`)}
            name="offer_en"
            hasEdit
            edit={edit}
            setFieldValue={setFieldValue}
            validateForm={validateForm}
            keyUploadFile="project"
            file_ext={['.pdf']}
            hintText={<span>{i18n.t(`project_page.forms.offer_hint`)}</span>}
          />
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default Offer;
