import React, { FC } from 'react';
import CollapseWrapper from 'components/CollapseWrapper';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';
import { FiAlertCircle, FiRefreshCcw, FiXCircle } from 'react-icons/fi';
import { ReactComponent as CheckCircleSvg } from 'assets/check-circle.svg';
import { TContractStatus } from 'domains/contract/model/types';
import css from '../styles.module.scss';

interface IModerationProps {
  status: TContractStatus
  getContractData: () => void;
}

const Moderation: FC<IModerationProps> = ({ status, getContractData }) => {
  const { t } = useTranslation();

  return (
    <CollapseWrapper
      title={t(`creative.Status`)}
      nameWrapper="CONTRACT_MODERATION"
      isLoading={false}
      classname={css.translation}
    >
      <div className={css.moderation}>
        <div className={css.actions}>
          <Tooltip
            title={
              <div className={css.update}>
                <FiRefreshCcw size={18} />
              </div>
            }
            onClick={getContractData}
          >
            {t(`update_btn`)}
          </Tooltip>
        </div>
        <div className={css.content}>
          <div className={css.item}>
            {status === 'ACTIVE' && (
              <>
                <CheckCircleSvg
                  style={{
                    color: '#149C08',
                    width: '20px',
                    height: 'auto',
                  }}
                /> 
                <span>{t(`ord.active`)}</span>
              </>
            )}
            {status === 'ON_APPROVAL' && (
              <>
                <FiAlertCircle size={20} color="#FF9C08" />
                <span>{t(`ord.moderation`)}</span>
              </>
            )}
            {status === 'DELETED' && (
              <>
                <FiXCircle
                  size={20}
                  color="#FF3636"
                  className={css.title__icon}
                />
                <span>{t(`ord.deleted`)}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </CollapseWrapper>
  );
};

export default Moderation;
