import Loader from 'components/UI/Loader';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import css from '../../styles.module.scss';

interface IProps {
  isCampaignCreating: boolean;
}

const Process: FC<IProps> = ({ isCampaignCreating }) => {
  const { t } = useTranslation();

  return (
    <div className={css.creating}>
      <div className={css.creating_icon}>
        <Loader />
      </div>
      {isCampaignCreating && <div>{t(`ord.contracts.creating_in_progress`)}</div>}
    </div>
  );
};

export default Process;
