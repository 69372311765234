import React, { FC, useState } from 'react';
import { Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import EditCard from 'components/UI/EditCard';
import Input from 'components/UI/Input/input';
import css from '../styles.module.scss';
import { CreativeDetail, TCreativePush } from '../../../../../types';
import { useEditPush } from './hooks';
import { getSizes } from '../../../../../../uploads/helpers';
import UploadImage from '../../../../../../uploads/UploadImage';

type Props = {
  data: TCreativePush;
  creative: CreativeDetail;
};

const FormType: FC<Props> = ({ data, creative: { xxhash } }) => {
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();

  const { formik, handlerFormikReset, handlerFormikSubmit } = useEditPush({
    setEdit,
    data,
    xxhash,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          noHover
          noShadow
          hasErrors={Object.keys(formik.errors).length > 0}
          onResetForm={handlerFormikReset}
          onSave={handlerFormikSubmit}
          setEdit={setEdit}
        >
          <div className={css.form}>
            <UploadImage
              keyUploadFile="push"
              preview={getSizes(
                formik.values.iconMask,
                formik.values.iconFormats,
              )}
              name="iconMask"
              setFieldValue={formik.setFieldValue}
              validateForm={formik.validateForm}
              label={t('creative.push_text.t1')}
              customLinkTitle={t('creative.creasmall_set.linktoicon_word')}
              hintText={
                <span>
                  {t('creative.push_text.t6')}
                  <br />
                  {t('creative.push_text.t7')}
                  <br />
                  {t('creative.push_text.t8')}
                </span>
              }
              edit={edit}
              hasEdit
              maxSizeFile={200}
              minResolution={80}
              maxResolution={4096}
              file_ext={['.png', '.jpeg', '.jpg']}
              typeSection="push"
            />

            <UploadImage
              keyUploadFile="push"
              preview={getSizes(
                formik.values.imgMask,
                formik.values.imgFormats,
              )}
              name="imgMask"
              setFieldValue={formik.setFieldValue}
              validateForm={formik.validateForm}
              edit={edit}
              hasEdit
              maxSizeFile={200}
              label={t('creative.push_text.t2')}
              customLinkTitle={t('creative.push_text.t9')}
              minResolution={239}
              hintText={
                <span>
                  {t('creative.push_text.t6')}
                  <br />
                  {t('creative.push_text.t10')}
                  <br />
                  {t('creative.push_text.t11')}
                </span>
              }
              file_ext={['.png', '.jpeg', '.jpg']}
              typeSection="push"
            />

            <Input
              label={t('creative.push_text.t3')}
              notField={edit}
              showValueCount
              inputAttributes={{
                name: 'title',
                placeholder: '',
                value: formik.values.title,
                onChange: formik.handleChange,
                maxLength: 65,
                style: { height: 48 },
              }}
            />

            <Input
              notField={edit}
              label={t('creative.push_text.t4')}
              isTextarea
              showValueCount
              inputAttributes={{
                name: 'text',
                value: formik.values.text,
                onChange: formik.handleChange,
                maxLength: 240,
                placeholder: '',
                style: { height: 128 },
              }}
            />
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default FormType;
