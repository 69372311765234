import { createAction } from '@reduxjs/toolkit';
import {
  CreateContractAction,
  CreateEmptyCampaign,
  GetContractParams,
  GetEmptyCampaign,
  UpdateContractAction,
} from './types';

export const getContract = createAction<GetContractParams>(
  'contract/getContract',
);

export const createContract = createAction<CreateContractAction>(
  'contract/createContract',
);

export const updateContract = createAction<UpdateContractAction>(
  'contract/updateContract',
);

export const sendErir = createAction<GetContractParams>(
  'contract/sendErir',
);

export const createEmptyCampaign = createAction<CreateEmptyCampaign>(
  'contract/createEmptyCampaign',
);

export const getEmptyCampaign = createAction<GetEmptyCampaign>(
  'contract/getEmptyCampaign',
);
