import React, { FC } from 'react';
import Tooltip from 'components/UI/Tooltip';
import { FiRefreshCcw } from 'react-icons/fi';
import { everyGroups } from 'utils/statics/everyGroups';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { useUserInfo } from 'domains/user/model/selectors';
import { useDspLinks } from 'utils/useDspLinks/useDspLinks';
import { useCreativeDetailInfo } from 'domains/creative/model/selectors';
import { useCampaignInfo } from 'domains/campaign/model/selectors';
import css from '../styles.module.scss';
import i18n from '../../../../i18n';
import { ReactComponent as FileCodeSvg } from '../../../../assets/file-code.svg';

interface Props {
  onClickHandler?: () => void;
  children: JSX.Element;
  type?: 'CAMPAIGN' | 'CREATIVE'
}

const TranslationInfoWrapper: FC<Props> = ({ children, onClickHandler, type }) => {
  const { data: user } = useUserInfo();
  const creative = useCreativeDetailInfo().data;
  const campaign = useCampaignInfo().data;

  const {
    getStatusJsonDspLink
  } = useDspLinks();

  const getCurrentLinkByType = () => {
    switch (type) {
      case 'CAMPAIGN':
        return getStatusJsonDspLink(campaign, 'camp');
      case 'CREATIVE':
        return getStatusJsonDspLink(creative, 'creative');
      default:
        return '';
    }
  };

  return (
    <div className={css.translations}>
      <div className={css.actions}>
        {everyGroups(user, 'test') && (
          <a href={getCurrentLinkByType()} target="_blank" rel="noreferrer">
            <TooltipPoratal hint="get status json dsp">
              <FileCodeSvg />
            </TooltipPoratal>
          </a>
        )}
        <Tooltip
          onClick={onClickHandler}
          title={
            <div className={css.update}>
              <FiRefreshCcw size={18} />
            </div>
          }
        >
          {i18n.t(`update_btn`)}
        </Tooltip>
      </div>
      <div className={css.content}>{children}</div>
    </div>
  );
};

export default TranslationInfoWrapper;
