import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CardList from 'components/UI/CardList';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import Button from 'components/UI/Buttons/buttons';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import ErrorReport from 'components/Errors/Reports';
import { useUpdateCounteragent } from 'domains/counteragent/model/hooks/useUpdateCounteragent';
import { useOrdList } from 'domains/counteragents/model/selectors';
import { useSetLastIdCounteragent } from 'utils/useLastIdHelpers/useLastIdCounteragent';
import Parameters from '../components/Parameters';
import css from '../styles.module.scss';
import Info from '../components/Info';
import UploadImg from '../components/UploadImg';
import LoadParameters from '../components/Parameters/LoadParameters';
import LoaderInfo from '../components/Info/LoaderInfo';
import Moderation from '../components/Moderation';

const EditContractor: FC = () => {
  const { t } = useTranslation();

  const {
    formik,
    isLoading,
    isLoadingCounteragentData,
    counteragentData,
    links,
    userData,
    isPrompt,
    error,
    goBack,
    handleChangeInput,
    externalId,
    onGetCounteragentData,
  } = useUpdateCounteragent();

  const { data: ordList, isLoading: isLoadingOrdList } = useOrdList();

  useSetLastIdCounteragent(counteragentData?.status);

  useEffect(() => {
    const url = new URL(window.location);

    url.searchParams.delete('highlights');
    url.searchParams.delete('status');

    window.history.replaceState({}, '', url);
  }, []);

  if (error.code === 90) {
    return (
      <ErrorReport error={{ code: 90, isError: true, message: 'error' }} />
    );
  }

  return (
    <>
      <Helmet>
        <title>{counteragentData?.name}</title>
      </Helmet>

      <div className={css.header}>
        <div className="container">
          <Breadcrumbs type="Custom" linksCustom={links} />
          <div className={css.status}>
            <h1 className={css.title}>{counteragentData?.name}</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className={css.container}>
          <CardList>
            {isLoadingCounteragentData ? (
              <LoadParameters />
            ) : (
              <div>
                <Parameters
                  handleChangeInput={handleChangeInput}
                  formik={formik}
                  userType={userData?.partner.type}
                  mode="update"
                />
                <div className={css.actions}>
                  <Button
                    title={t('campaigns_page.create_campaign.cancel_btn')}
                    transparent
                    height={40}
                    buttonAttributes={{
                      type: 'button',
                      onClick: goBack,
                    }}
                  />
                  <Button
                    title={t('save_btn')}
                    height={40}
                    buttonAttributes={{
                      disabled: isLoading || !formik.isValid,
                      type: 'submit',
                      onClick: () => formik.handleSubmit(),
                    }}
                  />
                </div>
              </div>
            )}
          </CardList>
          <CardList>
            <div>
              {isLoadingOrdList || isLoadingCounteragentData ? (
                <LoaderInfo mode="edit" />
              ) : (
                <Info
                  edit
                  externalId={externalId}
                  formik={formik}
                  ordList={ordList}
                />
              )}
              <UploadImg disabled />
              {counteragentData && (
                <Moderation
                  status={counteragentData.status}
                  onGetCounteragentData={onGetCounteragentData}
                />
              )}
            </div>
          </CardList>
        </div>
      </div>

      <Prompt when={isPrompt} message={t('just_words.exit_page_currect')} />
    </>
  );
};

export default EditContractor;
