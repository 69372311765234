import React, { FC } from 'react';
import cn from 'classnames';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import SwitchItem from 'components/UI/SwitchItem';
import LoadingBreadcrumbs from 'components/Breadcrumbs/components/loading';
import InternalId from 'components/InternalId';
import { useSwitchNavigation } from 'hooks/useSwitchNavigation';
import { useSubstitutionOfVoids } from 'utils/SubstitutionOfVoids';
import { FiExternalLink } from 'react-icons/fi';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { everyGroups } from 'utils/statics/everyGroups';
import { useDspLinks } from 'utils/useDspLinks/useDspLinks';
import css from './styles.module.scss';
import { RGetPersonal } from '../types';
import DateRangeFilter from '../../../components/DateRangeFilter';
import { parsingDate } from '../../../utils/momentParse';
import { KeysSettings, useStateValue } from '../../../utils/context';
import { useOnScroll } from '../../../hooks/useOnScroll';
import { TDates } from '../../../types/general';
import { useUserInfo, useUserTheme } from '../../user/model/selectors';

type Props = {
  campaign?: RGetPersonal | null;
  isLoading?: boolean;
  isShowDataFilter?: boolean;
};

const HeaderCampaign: FC<Props> = ({
  campaign,
  isLoading,
  isShowDataFilter = false,
}) => {
  const { data: user } = useUserInfo();
  const {
    showNavigation,
    isLoading: isLoadingNavigation,
    prevLink,
    nextLink,
    isDisableLink,
    count,
  } = useSwitchNavigation({ mode: 'campaign' });

  const { getJsonDspLink } = useDspLinks();

  const { state, dispatch } = useStateValue();
  const settings = state.settings[KeysSettings.CAMPAIGN];

  const onSetRange = (period: TDates['period'], type: string) => {
    dispatch({
      type: 'setSettings',
      payload: {
        key: KeysSettings.CAMPAIGN,
        item: {
          period,
          type,
        },
      },
    });
  };

  const { fixed } = useOnScroll(120);

  const { getValueFromSettingForKey } = useUserTheme();
  // заполняет пустоту, когда фиксируется header и заголовок таблицы, чтобы не скакал скролл
  const { fakeHeader, fakeHeaderRef } = useSubstitutionOfVoids({ fixed });

  return (
    <div className={css.wrapper}>
      {fakeHeader}
      <div className={cn(css.header, { isFixed: fixed })} ref={fakeHeaderRef}>
        <div
          className={cn(css.container, 'container', {
            _plusDataRange: isShowDataFilter,
          })}
        >
          <div className={css.info}>
            {!isLoading && campaign && (
              <Breadcrumbs xxhash={campaign.xxhash} type="Campaign" />
            )}
            {isLoading && <LoadingBreadcrumbs />}
            <div className={css.status}>
              {isLoading && <div className={css.loader} />}
              {campaign && (
                <h1 className={css.title}>
                  {campaign && campaign.internal_id && (
                    <InternalId internal_id={campaign.internal_id} />
                  )}
                  {campaign && <span>{campaign.title}</span>}
                  {everyGroups(user, 'test') && campaign && (
                    <a
                      href={getJsonDspLink(campaign, 'camp')}
                      target="_blank"
                      rel="noreferrer"
                      className={css.dsp_link}
                    >
                      <TooltipPoratal hint="get json dsp">
                        <FiExternalLink size={16} />
                      </TooltipPoratal>
                    </a>
                  )}
                </h1>
              )}
            </div>
          </div>
          <div className={css.actions}>
            {isShowDataFilter && campaign && (
              <DateRangeFilter
                onSetDateRange={onSetRange}
                range={settings.period}
                dateStart={parsingDate(campaign.date_start.date).toDate()}
                dateEnd={parsingDate(campaign.date_end.date).toDate()}
                initialTitleDay={settings.type}
                disabled={
                  !campaign.reports.items.some((e) => e.is_enabled === true)
                }
              />
            )}
            {(getValueFromSettingForKey('HideNavigationCampaign') ||
              getValueFromSettingForKey('HideNavigationCampaign') === null) && (
              <SwitchItem
                count={count}
                showNavigation={showNavigation}
                prevLink={prevLink}
                nextLink={nextLink}
                isLoading={isLoadingNavigation}
                isDisableLink={isDisableLink}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCampaign;
