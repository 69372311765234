import { useGetFieldConfigValue } from 'domains/creative/creatives/hooks/useGetFieldConfigValue';
import {
  CreativeDetail,
  CreativeDetailCompanion,
} from 'domains/creative/types';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useSpring } from 'react-spring';
import { Field, FieldProps, Form, FormikProvider } from 'formik';
import EditCard from 'components/UI/EditCard';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Input from 'components/UI/Input/input';
import { additional_settings_switch_tabs } from 'domains/creative/model/shared';
import RadioGroup from 'components/UI/RadioGroup';
import PriceInput from 'components/UI/PriceInput';
import { FiAlertCircle } from 'react-icons/fi';
import cn from 'classnames';
import { getParamVideo, getSizes } from 'domains/uploads/helpers';
import UploadImage from 'domains/uploads/UploadImage';
import equal from 'deep-equal';
import Code from 'components/UI/Code';
import UploadZip from 'domains/uploads/UploadZip';
import { useCheckGridEridInfo } from 'domains/creative/creatives/hooks/useCheckGridEridInfo';
import EridWarning from 'components/EridWarning';
import NewSelect from 'components/UI/NewSelect';
import css from '../styles.module.scss';
import { useCompanion } from './hooks';

interface ICompanionProps {
  xxhash: CreativeDetail['xxhash'];
  data: CreativeDetailCompanion['data'];
  size: CreativeDetail['size'];
  config_values: CreativeDetail['config_values'];
}

const Companion: FC<ICompanionProps> = ({
  xxhash,
  data,
  size,
  config_values,
}) => {
  const [edit, setEdit] = useState(false);
  const erid = useGetFieldConfigValue('erid', 'COMPANION');
  const [toggle, setToggle] = useState<boolean>(false);

  const showBlock = useSpring<CSSProperties>(
    !toggle
      ? {
          height: 'auto',
          visibility: 'visible',
          opacity: 1,
          transform: 'translateY(0)',
          config: { duration: 120 },
          delay: 0,
          marginBottom: '24px',
        }
      : {
          opacity: 0,
          height: 0,
          visibility: 'hidden',
          transform: 'translateY(-50px)',
          delay: 30,
          marginBottom: '0px',
        },
  );

  const { t } = useTranslation();
  const {
    formik,
    handlerFormikReset,
    handlerFormikSubmit,
    handlerCopyHtml,
    defaultSizeOption,
    sizeOption,
    checkSizes,
    helper,
    helperError,
  } = useCompanion({
    xxhash,
    size,
    erid: (erid && config_values[erid.id]) || '',
    data,
    setEdit,
    edit,
  });

  const { isDisableSaveButton, setIsDisableSaveButton } = useCheckGridEridInfo({
    eridData: erid,
    eridVal: formik.values.erid,
  });

  useEffect(() => {
    if (formik.values.size) {
      if (defaultSizeOption(formik.values.size)) {
        if (defaultSizeOption(formik.values.size)[0].value === '0x0') {
          setToggle(false);
        } else {
          setToggle(true);
        }
      }
    } else {
      setToggle(true);
    }
  }, [formik.values.size, edit]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          noHover
          noShadow
          hasErrors={
            Object.keys(formik.errors).length > 0 || isDisableSaveButton
          }
          onResetForm={handlerFormikReset}
          onSave={handlerFormikSubmit}
          notEqual={!equal(formik.values, data)}
          setEdit={setEdit}
        >
          <div className={css.form}>
            <UploadImage
              keyUploadFile="video"
              preview={getParamVideo(formik.values.data, formik.values.size)}
              name="video_url"
              setFieldValue={formik.setFieldValue}
              validateForm={formik.validateForm}
              label={t('creative.video.video_input.label')}
              customLinkTitle={t('creative.video.video_input.custom_link')}
              hintText={
                <span>
                  {t('creative.video.video_input.hint1')}
                  <br />
                  {t('creative.video.video_input.hint2')}
                </span>
              }
              hasEdit
              isData
              edit={edit}
              fieldType="video"
              maxSizeFile={100}
              file_ext={['.mp4']}
              typeSection="video"
            />
            <UploadImage
              keyUploadFile="templateImage"
              preview={
                (formik.values.data.imgMask &&
                  formik.values.data.imgFormats &&
                  getSizes(
                    formik.values.data.imgMask,
                    formik.values.data.imgFormats,
                  )) || {
                  file_url: '',
                  client_name: '',
                  image_width: '',
                  image_height: '',
                }
              }
              name="imgMask"
              setFieldValue={formik.setFieldValue}
              validateForm={formik.validateForm}
              label={t('creative.creasmall_set.image_word')}
              customLinkTitle={t('creative.creasmall_set.linktoimage_word')}
              hintText={
                <span>
                  {t('creative.creasmall_set.html_format1')}
                  <br />
                  {t('creative.creasmall_set.html_format2')}
                </span>
              }
              hasEdit
              edit={edit}
              isData
              minResolution={100}
              file_ext={['.png', '.jpeg', '.jpg']}
              typeSection="templateImage"
              addTabLabel={t(`creative.create_creative.Add_archive`)}
              addTabContent={
                <UploadZip
                  isData
                  name="zip"
                  hintText={t('creative.create_creative.Supported_format')}
                  label={t('creative.create_creative.File')}
                  sizes={[
                    '160x600',
                    '240x400',
                    '300x250',
                    '300x600',
                    '320x480',
                    '320x50',
                    '336x280',
                    '480x320',
                    '580x400',
                    '728x90',
                    '970x250',
                    '970x90',
                    t('creative.create_creative.Set_sizes'),
                  ]}
                  setFieldValue={formik.setFieldValue}
                  validateForm={formik.validateForm}
                  inputAttributes={{
                    name: 'zip',
                    disabled: !edit,
                  }}
                  type="zip_banner"
                />
              }
            />
            <Field name="data.html_code">
              {({ form, field, meta }: FieldProps) => (
                <Code
                  handlerCopy={handlerCopyHtml}
                  label={t('creative.create_creative.HTML_Code')}
                  form={form}
                  field={field}
                  meta={meta}
                  error={!!formik.errors.data?.html_code}
                  errorText={formik.errors.data?.html_code}
                  maxCount={4096}
                  valueCount={formik.values.data.html_code?.length}
                  useFullscreen
                  disabled={!edit}
                  readonly={!edit}
                />
              )}
            </Field>
            <Tabs>
              <TabList>
                <Tab
                  className={cn('react-tabs__tab', {
                    hasError:
                      !!formik.errors?.data?.duration ||
                      !!formik.errors?.data?.skip_duration ||
                      !!formik.errors?.data?.video_bitrate,
                  })}
                >
                  <span>{t('creative.video.tabs.tab1')}</span>
                  {(!!formik.errors?.data?.duration ||
                    !!formik.errors?.data?.skip_duration ||
                    !!formik.errors?.data?.video_bitrate) && (
                    <FiAlertCircle size={16} className="alert-icon" />
                  )}
                </Tab>
                <Tab
                  className={cn('react-tabs__tab', {
                    hasError: !!formik.errors.data?.audit || helperError,
                  })}
                >
                  <span>
                    <span>{t('creative.video.tabs.tab2')}</span>
                  </span>
                  {(!!formik.errors?.data?.audit || helperError) && (
                    <FiAlertCircle size={16} className="alert-icon" />
                  )}
                </Tab>
              </TabList>
              <TabPanel>
                <div className={css.form}>
                  <div className={css.flex_row}>
                    <div className={css.flex_row_col}>
                      <PriceInput
                        error={!!formik.errors.data?.video_bitrate}
                        errortext={formik.errors.data?.video_bitrate}
                        label={t('creative.video.fields.video_bitrate')}
                        decimalScale={0}
                        onValueChange={({ floatValue }) => {
                          formik.setFieldValue(
                            'data.video_bitrate',
                            floatValue,
                          );
                        }}
                        value={formik.values.data.video_bitrate}
                        notField={!edit}
                        hasZero={false}
                        disabled
                      />
                    </div>
                    <div className={css.flex_row_col}>
                      {' '}
                      <PriceInput
                        error={!!formik.errors.data?.duration}
                        errortext={formik.errors.data?.duration}
                        notField={!edit}
                        label={t('creative.video.fields.video_duration')}
                        decimalScale={3}
                        onValueChange={(val) => {
                          formik.setFieldValue('data.duration', val.floatValue);
                        }}
                        value={formik.values.data.duration}
                        hasZero={false}
                        disabled
                      />
                    </div>
                    <div className={css.flex_row_col}>
                      <PriceInput
                        error={!!formik.errors.data?.skip_duration}
                        errortext={formik.errors.data?.skip_duration}
                        notField={!edit}
                        label={t('creative.video.fields.video_duration_pass')}
                        decimalScale={0}
                        onValueChange={(val) => {
                          formik.setFieldValue(
                            'data.skip_duration',
                            val.floatValue,
                          );
                        }}
                        value={formik.values.data.skip_duration}
                        hasZero={false}
                      />
                    </div>
                  </div>
                  <Input
                    notField={edit}
                    isTextarea
                    showValueCount
                    label={t('creative.video.fields.video_description')}
                    inputAttributes={{
                      name: 'data.description',
                      type: 'textarea',
                      placeholder: '',
                      maxLength: 128,
                      style: { height: 128 },
                      value: formik.values.data.description,
                      onChange: formik.handleChange,
                    }}
                  />
                  <Field name="size">
                    {({ form, field }: FieldProps) => (
                      <NewSelect
                        label={t('creative.create_creative.Size_px')}
                        form={form}
                        viewingOnly={!edit}
                        field={field}
                        selectAttributes={{
                          placeholder: t(
                            'creative.create_creative.Please_select',
                          ),
                          options: sizeOption,
                          value: defaultSizeOption(formik.values.size),
                          onChange: () => {
                            setToggle(() => checkSizes(formik.values.size));
                          },
                        }}
                        viewStyle="Vertical"
                      />
                    )}
                  </Field>
                  {edit && (
                    <>
                      <animated.div style={showBlock} className={css.sizes}>
                        <PriceInput
                          classname={css.rough_col}
                          hasZero
                          decimalScale={0}
                          label={`${t('creative.create_creative.Width')}, PX`}
                          placeholder={t('creative.create_creative.Width')}
                          name="width"
                          error={formik.errors.width}
                          errortext={formik.errors.width}
                          value={Number(formik.values.width)}
                          onValueChange={({ floatValue }) => {
                            if (!floatValue) {
                              formik.setFieldValue('width', null);
                              return;
                            }
                            formik.setFieldValue('width', floatValue);
                          }}
                          notField={!edit}
                        />
                        <PriceInput
                          hasZero
                          classname={css.rough_col}
                          decimalScale={0}
                          label={`${t('creative.create_creative.Height')}, PX`}
                          placeholder={t('creative.create_creative.Height')}
                          name="height"
                          error={formik.errors.height}
                          errortext={formik.errors.height}
                          value={Number(formik.values.height)}
                          onValueChange={({ floatValue }) => {
                            if (!floatValue) {
                              formik.setFieldValue('height', null);
                              return;
                            }
                            formik.setFieldValue('height', floatValue);
                          }}
                          notField={!edit}
                        />
                      </animated.div>
                    </>
                  )}
                  {erid && (
                    <>
                      <Input
                        label={erid.title}
                        notField={edit}
                        showValueCount
                        error={!!formik.errors.erid}
                        errortext={formik.errors.erid}
                        inputAttributes={{
                          name: 'erid',
                          disabled: !edit,
                          maxLength: 80,
                          value: formik.values.erid,
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                            formik.setFieldValue('erid', e.target.value.trim()),
                        }}
                      />
                      {formik.values.erid && (
                        <EridWarning
                          withAccept={edit}
                          setIsDisableSaveButton={setIsDisableSaveButton}
                          isChangeErid={
                            config_values[erid.id] !== formik.values.erid
                          }
                        />
                      )}
                    </>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className={css.form}>
                  <RadioGroup
                    name="additional_settings_switch"
                    label=""
                    className={css.switch}
                    options={[
                      {
                        title: t('creative.video.switch.all'),
                        value: 'ALL',
                      },
                      {
                        title: t('creative.video.switch.quartiles'),
                        value: 'QUARTILES',
                      },
                      {
                        title: t('creative.video.switch.show'),
                        value: 'SHOW',
                      },
                    ]}
                    setFieldValue={formik.setFieldValue}
                    checked={formik.values.additional_settings_switch}
                  />
                  {formik.values.additional_settings_switch !== 'ALL' && (
                    <>
                      {Object.keys(formik.values.data.audit).map(
                        (value) =>
                          additional_settings_switch_tabs[
                            formik.values.additional_settings_switch
                          ].includes(value) && (
                            <Input
                              notField={edit}
                              key={`data.audit.${value}`}
                              error={
                                formik.errors.data?.audit &&
                                !!formik.errors.data?.audit[value]
                              }
                              errortext={
                                formik.errors.data?.audit &&
                                formik.errors.data?.audit[value]
                              }
                              helper={helper[value]}
                              label={t(`creative.video.fields.video_${value}`)}
                              inputAttributes={{
                                name: `data.audit.${value}`,
                                placeholder: '',
                                style: { height: 48 },
                                value: formik.values.data.audit[value],
                                onChange: formik.handleChange,
                              }}
                            />
                          ),
                      )}
                    </>
                  )}
                  {formik.values.additional_settings_switch === 'ALL' && (
                    <>
                      {Object.keys(formik.values.data.audit).map((value) => (
                        <Input
                          notField={edit}
                          key={`data.audit.${value}`}
                          error={
                            formik.errors.data?.audit &&
                            !!formik.errors.data?.audit[value]
                          }
                          helper={helper[value]}
                          errortext={
                            formik.errors.data?.audit &&
                            formik.errors.data?.audit[value]
                          }
                          label={t(`creative.video.fields.video_${value}`)}
                          inputAttributes={{
                            name: `data.audit.${value}`,
                            placeholder: '',
                            style: { height: 48 },
                            value: formik.values.data.audit[value],
                            onChange: formik.handleChange,
                          }}
                        />
                      ))}
                    </>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default Companion;
