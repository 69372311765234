import React, { FC } from 'react';
import i18next from 'i18next';
import { FiXCircle } from 'react-icons/fi';
import css from '../styles.module.scss';
import TranslationInfoWrapper from '../TranslationInfoWrapper';

interface Props {
  onClickHandler: () => void;
  type?: 'CAMPAIGN' | 'CREATIVE'
}

const ErrorApiTranslation: FC<Props> = ({ onClickHandler, type }) => (
  <TranslationInfoWrapper onClickHandler={onClickHandler} type={type}>
    <div className={css.title}>
      <FiXCircle size={22} color="#FF3636" className={css.title__icon} />
      <span>{i18next.t('errors.err94')}</span>
    </div>
  </TranslationInfoWrapper>
);

export default ErrorApiTranslation;
