import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatchApp } from 'redux/rootSelectors';
import { PExperimental, RUseFieldValue, TExperimentalInfoField } from 'domains/campaign/types';
import { setCreoExperimentalAction } from 'domains/creative/model/actions';
import { setCampaignExperimentalAction } from 'domains/campaign/model/actions';


export const useFieldValues = (
  item: TExperimentalInfoField,
  check: boolean,
  setCheck: React.Dispatch<React.SetStateAction<boolean>>,
  mode: 'creo' | 'campaign',
  xxhash: string | null, 
  config_fields?: Record<number, number>,
): RUseFieldValue => {
  const dispatch = useDispatchApp();
  const { id, type, default_value } = item;
  const [success, setSuccess] = useState(false);
  const value = config_fields ? config_fields[id] : '';

  let initialValues: PExperimental = {
    value: value || '',
    id,
    xxhash: xxhash || '',
  };

  if (type === 'bool') {
    initialValues = {
      ...initialValues,
      value: value || false,
    };
  }

  if (type === 'int' || type === 'float') {
    initialValues = {
      ...initialValues,
      value: value || 0,
    };
  }

  const validationSchema =
    (type === 'int' || type === 'float') &&
    Yup.object().shape({
      value: Yup.number().max(2147483647).nullable(),
    });

  const formik = useFormik<PExperimental>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (
        formik.values.value === undefined &&
        (type === 'int' || type === 'float')
      ) {
        formik.setFieldValue('value', 0);
      }
      const params = {
        ...initialValues,
        value:
          type === 'int' || type === 'float' ? values.value || 0 : values.value,
        callbacks: {
          onSuccess: setSuccess,
        },
      };
      if (type === 'bool') {
        if(mode === 'creo') {
          dispatch(setCreoExperimentalAction(params));
        } else {
          dispatch(setCampaignExperimentalAction(params))
        }
        return;
      }
      if (values.value !== value) {
        if(mode === 'creo') {
          dispatch(setCreoExperimentalAction(params));
        } else {
          dispatch(setCampaignExperimentalAction(params))
        }
      }
    },
  });

  const onKeyHandler = (keyboardEvent: React.KeyboardEvent) => {
    const { charCode, keyCode } = keyboardEvent;
    if ((charCode || keyCode) === 13) {
      keyboardEvent.preventDefault();
      formik.handleSubmit();
    }
  };

  const setField = (name: string, val: string | number | undefined) => {
    formik.setFieldValue(name, val);
  };

  useEffect(() => {
    if (check) {
      formik.setFieldValue('value', default_value);
    }
  }, [check]);

  useEffect(() => {
    if (formik.values.value === default_value) {
      setCheck(true);
      return;
    }
    setCheck(false);
  }, [formik.values.value]);

  return {
    formik,
    onKeyHandler,
    setField,
    success,
  };
};
