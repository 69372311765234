import NavigationHeader from 'components/NavigationHeader';
import { useUserInfo } from 'domains/user/model/selectors';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { TID } from 'types/general';
import { everyGroups } from 'utils/statics/everyGroups';
import Error404 from 'domains/app/Errors/Error404';
import NoAccess from 'domains/app/Errors/noAccess';
import { checkIsAgencyNotOwn } from 'utils/checkIsAgencyNotOwn';
import AgencyReport from './ui/Reports';
import MainSettings from './ui/MainSettings';
import Settings from './ui/Settings';
import ReportAu from './ReportAu';

const Detail: FC = () => {
  const { path } = useRouteMatch<TID>();
  const location = useLocation();
  const { data: user } = useUserInfo();
  const { params } = useRouteMatch<TID>();
  const { t } = useTranslation();

  return (
    <div>
      {user && (
        <NavigationHeader
          back={{
            title: t(`campaigns_page.campaign_settings.black_menu.Back`),
            to: `/main/agencies`,
            back: true,
          }}
        >
          {!location.pathname.includes('reports') && (
            <>
              <NavLink
                to={{
                  ...location,
                  pathname: `/main/agency/${params.id}/basic_settings`,
                }}
                activeClassName="isActive"
              >
                {t(`campaigns_page.campaign_settings.black_menu.Main_settings`)}
              </NavLink>
              {everyGroups(user, 'owner') && (
                <NavLink
                  to={{
                    ...location,
                    pathname: `/main/agency/${params.id}/settings`,
                  }}
                  activeClassName="isActive"
                >
                  {t(`campaigns_page.campaign_settings.black_menu.Settings`)}
                </NavLink>
              )}
            </>
          )}
        </NavigationHeader>
      )}

      {user &&
        useMemo(
          () => (
            <Switch>
              <Route
                exact
                path={`${path}/reports`}
                component={
                  everyGroups(user, 'reports_client') &&
                  (user.partner.type === 'ORGANIZATION' ||
                    user.partner.type === 'AGENCY') &&
                  checkIsAgencyNotOwn(user, params.id)
                    ? AgencyReport
                    : NoAccess
                }
              />
              <Route
                exact
                path={`${path}/reports_au`}
                component={
                  (everyGroups(user, 'owner', 'reports_agency') &&
                    checkIsAgencyNotOwn(user, params.id)) ||
                  (everyGroups(user, 'self_user', 'reports_agency') &&
                    checkIsAgencyNotOwn(user, params.id))
                    ? ReportAu
                    : NoAccess
                }
              />
              <Route path={`${path}/basic_settings`} component={MainSettings} />
              <Route
                path={`${path}/settings`}
                component={everyGroups(user, 'owner') ? Settings : Error404}
              />
            </Switch>
          ),
          [],
        )}
    </div>
  );
};

export default Detail;
