import Tooltip from 'components/UI/Tooltip';
import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import Button from 'components/UI/Buttons/buttons';
import Input from 'components/UI/Input/input';
import {
  filterActionsOptions,
  filterObjectOptions,
  filterParametrsChangeOptions,
} from 'domains/campaigns/model/shared';
import { IHistoryFilter } from 'domains/historyChanges/types';
import LabelField from 'components/UI/LabelField';
import { HistorySelectedField, SelectField } from 'types/general';
import { useTranslateConstObject } from 'utils/useTranslateConstObject';
import { TTwoMonthsDateReturn } from 'utils/genTimePeriod';
import NewSelect from 'components/UI/NewSelect';
import { useDetectBlockScroll } from 'utils/useDetectBlockScroll';
import css from './styles.module.scss';
import { ReactComponent as CampFilterSvg } from '../../../../assets/campFilter.svg';
import { useHistoryFilter } from './hooks/useHistoryFilter';
import i18n from '../../../../i18n';

interface IHistoryFilterProps {
  isFilterDelete: boolean;
  setIsFilterDelete: React.Dispatch<React.SetStateAction<boolean>>;
  filtersValueToString: (filter: IHistoryFilter) => void;
  topPositionRef: React.RefObject<HTMLDivElement>;
  historyFilters: IHistoryFilter;
  setHistoryFilters: (value: React.SetStateAction<IHistoryFilter>) => void;
  setLastHistoryFilters: (value: React.SetStateAction<IHistoryFilter>) => void;
  applyHistoryFilters?: () => void;
  commonSearch: boolean;
  changeTopPositionParametr?: boolean;
  changeTopPositionRef?: React.RefObject<HTMLDivElement>;
  selectedFilterTotal: HistorySelectedField[];
}

export interface IDispatchHistoryParametres {
  period: TTwoMonthsDateReturn;
  search: string | undefined;
  limit: {
    from: number;
    to: number;
  };
  field?: string[];
}

const HistoryFilter: FC<IHistoryFilterProps> = ({
  isFilterDelete,
  setIsFilterDelete,
  filtersValueToString,
  topPositionRef,
  historyFilters,
  setHistoryFilters,
  setLastHistoryFilters,
  applyHistoryFilters,
  commonSearch,
  changeTopPositionParametr,
  changeTopPositionRef,
  selectedFilterTotal = [],
}) => {
  const {
    isOpenPanel,
    setIsOpenPanel,
    topPosition,
    heightPanel,
    url,
    typeSelect,
    actionSelect,
    fieldSelect,
    emailSelect,
    searchSelect,
    clearFilters,
    launchApplyFunc,
    wrapperRef,
  } = useHistoryFilter(
    topPositionRef,
    commonSearch,
    setHistoryFilters,
    setLastHistoryFilters,
    applyHistoryFilters,
    changeTopPositionParametr,
    changeTopPositionRef,
  );

  const historyParams = localStorage.getItem('localHistoryParams');
  const parsedHistoryParams: IHistoryFilter =
    historyParams && JSON.parse(historyParams);
  const isLocalHistory = localStorage.getItem('isLocalHistory');

  // Функция изменения значения в фильтре, изменяет поле на то, что приходит в data
  const onCustomChange = (field: string, data: string | string[]): void => {
    setHistoryFilters({ ...historyFilters, [field]: data });
    localStorage.setItem(
      'localHistoryParams',
      JSON.stringify({ ...parsedHistoryParams, [field]: data }),
    );
  };

  // Очищает конкретные фильтры в попапе, удаленные с помощью крестика над боксом
  const clearCertainFilter = () => {
    if (!parsedHistoryParams.item_type || !historyFilters.item_type) {
      typeSelect.current?.setValue(null);
    }
    if (!parsedHistoryParams.action || !historyFilters.action) {
      actionSelect.current?.setValue(null);
    }
    if (!parsedHistoryParams.field || !historyFilters.field) {
      fieldSelect.current?.setValue(null);
    }
  };

  const applyFIlters = () => {
    launchApplyFunc();
    if (isLocalHistory) {
      filtersValueToString(parsedHistoryParams);
      return;
    }
    filtersValueToString(historyFilters);
  };

  useEffect(() => {
    if (isFilterDelete) {
      applyFIlters();
      clearCertainFilter();
      setIsFilterDelete(false);
    }
  }, [historyFilters, parsedHistoryParams, isLocalHistory]);

  useEffect(() => {
    if (isLocalHistory) {
      filtersValueToString(parsedHistoryParams);
      return;
    }
    filtersValueToString(historyFilters);
  }, []);

  /* Нужно, чтобы при смене языка и возврате на предыдущую страницу, применялся перевод. 
  Из-за того, что объект не в компоненте, перевод делается один раз, на момент импорта для того языка, что установлен, а при смене языка ничего не меняется */
  const filterObjectOptionsTranslate: HistorySelectedField[] =
    useTranslateConstObject(filterObjectOptions);
  const filterActionsOptionsTranslate: HistorySelectedField[] =
    useTranslateConstObject(filterActionsOptions);
  const filterParametrsChangeOptionsTranslate: SelectField[] =
    useTranslateConstObject(filterParametrsChangeOptions);

  const { endScrollingBlock } = useDetectBlockScroll(true);

  return (
    <div ref={wrapperRef}>
      <div
        className={cn(css.historyFilter, {
          _isShowPanel: isOpenPanel,
          _isHighlightIcon: selectedFilterTotal.length,
        })}
      >
        {!!selectedFilterTotal.length && (
          <span>{selectedFilterTotal.length}</span>
        )}

        <Tooltip
          bottom
          title={<CampFilterSvg />}
          onClick={() => setIsOpenPanel(!isOpenPanel)}
        >
          {i18n.t('filter.customize')}
        </Tooltip>
      </div>
      <div
        className={css.historyFilter_panel}
        style={{
          top: `${topPosition + 10}px`,
          maxHeight: `${heightPanel}px`,
        }}
      >
        <div className={css.parametrs}>
          <div className={css.item}>
            <LabelField label={i18n.t(`history_changes_page.objects`)} />
            <NewSelect
              selectAttributes={{
                placeholder: i18n.t(`history_changes_page.objects`),
                options: filterObjectOptionsTranslate,
                closeMenuOnScroll:
                  !endScrollingBlock && endScrollingBlock !== null
                    ? () => true
                    : () => false,
                defaultValue: filterObjectOptionsTranslate.filter(
                  (item) =>
                    url.searchParams.get('filter') &&
                    item.value === historyFilters?.item_type,
                ),
              }}
              selectRef={typeSelect}
              onCustomChange={(e) => onCustomChange('item_type', e)}
              className={cn(css.select)}
              viewStyle="Vertical"
            />
          </div>
          <div className={css.item}>
            <LabelField label={i18n.t(`common.reports.general.actions`)} />
            <NewSelect
              selectAttributes={{
                placeholder: i18n.t(`creative.create_creative.Actions`),
                options: filterActionsOptionsTranslate,
                defaultValue: filterActionsOptionsTranslate.filter(
                  (item) =>
                    url.searchParams.get('filter') &&
                    item.value === historyFilters?.action,
                ),
                closeMenuOnScroll:
                  !endScrollingBlock && endScrollingBlock !== null
                    ? () => true
                    : () => false,
              }}
              selectRef={actionSelect}
              onCustomChange={(e) => onCustomChange('action', e)}
              className={cn(css.select)}
              viewStyle="Vertical"
            />
          </div>
          <div className={css.item}>
            <LabelField
              label={i18n.t(`history_changes_page.header_parametr`)}
            />
            <NewSelect
              selectAttributes={{
                placeholder: i18n.t(`history_changes_page.header_parametr`),
                options: filterParametrsChangeOptionsTranslate,
                defaultValue: filterParametrsChangeOptionsTranslate.filter(
                  (item) =>
                    (isLocalHistory
                      ? parsedHistoryParams?.field
                      : historyFilters?.field) &&
                    (isLocalHistory
                      ? item.value === parsedHistoryParams?.field?.[0]
                      : item.value === historyFilters?.field?.[0]),
                ),
                closeMenuOnScroll:
                  !endScrollingBlock && endScrollingBlock !== null
                    ? () => true
                    : () => false,
              }}
              selectRef={fieldSelect}
              onCustomChange={(e) => onCustomChange('field', [e])}
              className={cn(css.select)}
              viewStyle="Vertical"
            />
          </div>
          <div className={css.item}>
            <LabelField label="Email" />
            <Input
              innerRef={emailSelect}
              value={
                isLocalHistory && parsedHistoryParams
                  ? parsedHistoryParams.user_email
                  : historyFilters?.user_email
              }
              classname={css.filterInput}
              onCustomChange={(e) =>
                onCustomChange('user_email', e.target.value)
              }
              inputAttributes={{
                placeholder: 'Email',
              }}
            />
          </div>
          <div className={css.item}>
            <LabelField label={i18n.t(`history_changes_page.inter_id_hash`)} />
            <Input
              innerRef={searchSelect}
              value={
                isLocalHistory && parsedHistoryParams
                  ? parsedHistoryParams.search
                  : historyFilters?.search
              }
              classname={css.filterInput}
              onCustomChange={(e) => onCustomChange('search', e.target.value)}
              inputAttributes={{
                placeholder: i18n.t(`history_changes_page.inter_id_hash`),
              }}
            />
          </div>
        </div>
        <div className={css.actions}>
          <Button
            lightgrey
            title={i18n.t(`clean_btn`)}
            height={40}
            buttonAttributes={{
              onClick: clearFilters,
              style: {
                color: '#222222',
              },
            }}
          />
          <Button
            title={i18n.t('apply_btn')}
            height={40}
            buttonAttributes={{
              className: css.apply,
              onClick: applyFIlters,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default HistoryFilter;
