import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { containerReducer } from 'domains/containers/reducer';
import { containerDetailReducer } from 'domains/containerDetail/reducer';
import createSagaMiddleware from 'redux-saga';
import { historyReducer } from 'domains/historyChanges/reducer';
import { creativeReducer } from 'domains/creative/reduser';
import { campaignsReducer } from 'domains/campaigns/reducer';
import { targetReducer } from 'domains/target/reduser';
import { userReducer } from 'domains/user/reduser';
import { campaignReducer } from 'domains/campaign/reduser';
import { managementUsersReducer } from 'domains/users/model/reducer';
import { partnersReducer } from 'domains/partners/reducer';
import { projectReducer } from 'domains/project/reducer';
import { clientsReducer } from 'domains/clients/reducer';
import { agenciesReducer } from 'domains/agencies/reducer';
import { clientReducer } from 'domains/client/reduser';
import { agencyReducer } from 'domains/agency/reducer';
import { bwListUpdateReducer } from 'domains/bw_lists_update/reducer';
import { notificationReducer } from 'domains/notification/reducer';
import { searchReducer } from 'domains/search/model/reducer';
import { uploadReducer } from 'domains/uploads/reducer';
import { MlinkToImageR } from 'domains/modals/linkToImage/reduser';
import { wssReducer } from 'domains/wss/model/reducer';
import { storageReducer } from 'domains/storage/model/reducer';
import { commentReducer } from 'domains/comment/model/reducer';
import { analysisReducer } from 'domains/Analysis/reducer';
import { APIUsersReducer } from 'domains/usersAPI/model/reducer';
import { offersReducer } from 'domains/offers/model/reducer';
import { offerReducer } from 'domains/offer/model/reducer';
import { counteragentsReducer } from 'domains/counteragents/model/reducer'
import { counteragentReducer } from 'domains/counteragent/model/reducer'
import { contractsReducer } from 'domains/contracts/model/reducer'
import { contractReducer } from 'domains/contract/model/reducer'
import { blReducer } from 'domains/blackLists/reducer';
import { blDomainsReducer } from 'domains/blackList/reducer';
import { sideMenuReducer } from 'components/SideMenu/reducer';
import { resetStore } from './middleware/resetStore';

const RootReducer = combineReducers({
  storageReducer,
  wssReducer,
  MlinkToImageR,
  bwListUpdateReducer,
  searchReducer,
  uploadReducer,
  containers: containerReducer,
  containerDetail: containerDetailReducer,
  notificationReducer,
  projectReducer,
  userReducer,
  managementUsersReducer,
  partnersReducer,
  agenciesReducer,
  agencyReducer,
  clientsReducer,
  сlientReducer: clientReducer,
  campaignsReducer,
  campaignReducer,
  creativeReducer,
  targetReducer,
  commentReducer,
  historyReducer,
  analysisReducer,
  APIUsersReducer,
  offersReducer,
  offerReducer,
  counteragentsReducer,
  counteragentReducer,
  contractsReducer,
  contractReducer,
  blReducer,
  blDomainsReducer,
  sideMenuReducer
});

export const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
  }),
  sagaMiddleware,
];

export const store = configureStore({
  reducer: RootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: (defaultEnhancers) => [resetStore(), ...defaultEnhancers],
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default RootReducer;
