import React, { CSSProperties, FC } from 'react';
import Checkbox from 'components/UI/Checkbox/checkbox';
import cn from 'classnames';
import i18next from 'i18next';
import { ILimits } from 'domains/target/types';
import ItemActions from 'domains/target/grid/ItemActions';
import { limitsTreeTargets } from 'domains/target/model/shared';
import css from './styles.module.scss';
import ArrowSvg from '../icons/arrow.svg';
import { TargetKey, TGeoSectionNameAll, Tree } from '../../../model/types';

type PItem = {
  item: Tree;
  handleToggle: (p: Tree) => void;
  verifyCheckbox: (p: Tree) => boolean;
  verifyPartiallyChecked: (p: Tree) => boolean;
  handleChangeCheckbox: (p: Tree, isInherit: boolean) => void;
  search: string;
  type: TargetKey;
  editing: boolean;
  customStyle: CSSProperties;
  checkItemLimits: (limitsObj: ILimits | undefined) => boolean;
  limitsClickHandler: (
    currentKey: string | number,
    currentTitle: string,
  ) => void;
  index: number;
  disabled?: boolean;
};
const paddingCheckbox: Record<TGeoSectionNameAll, number> = {
  continents: 0,
  countries: 1,
  subdivisions: 2,
  cites: 3,
  village: 4,
};

const Item: FC<PItem> = ({
  item,
  handleToggle,
  verifyCheckbox,
  handleChangeCheckbox,
  search,
  customStyle,
  editing,
  type,
  verifyPartiallyChecked,
  checkItemLimits,
  limitsClickHandler,
  index,
  disabled,
}) => {
  const currentItemName =
    i18next.language === 'ru'
      ? item.name_ru || item.name_en
      : item.name_en || item.name_ru;
  return (
    <div style={customStyle}>
      <div
        className={cn(css.item)}
        style={{
          paddingLeft: `${paddingCheckbox[item.type] * 25 + 20}px`,
          cursor:
            item.type === 'continents' && type === 'geo_id'
              ? 'pointer'
              : 'default',
          background: index % 2 === 0 ? '#F9F9F9' : '',
        }}
      >
        {item.children && !!item.children.length && (
          <div
            className={css.arrow}
            onClick={() => {
              handleToggle(item);
            }}
          >
            <img
              className={cn(css.img, { active: item.isToggle })}
              src={ArrowSvg}
              alt="ArrowSvg"
            />
          </div>
        )}
        <div
          className={cn(css.checkboxWrap, {
            end:
              item.type === 'village' ||
              (item.type === 'cites' && !item.children),
            toggleNone: item.children && !item.children.length,
          })}
        >
          {' '}
          {item.type !== 'continents' || type !== 'geo_id' ? (
            <Checkbox
              isPartiallyChecked={verifyPartiallyChecked(item)}
              search={search}
              label={currentItemName}
              inputAttributes={{
                type: 'checkbox',
                checked: verifyCheckbox(item),
                onChange: () =>
                  handleChangeCheckbox(item, verifyCheckbox(item)),
              }}
              disabled={!editing || item.geoname_id === -1 || disabled}
            />
          ) : (
            <div
              onClick={() => {
                handleToggle(item);
              }}
            >
              {currentItemName}
            </div>
          )}
        </div>
        {checkItemLimits(item.limits) && (
          <div
            className={cn(css.circle, 'limits_circle', {
              isDisabled: false,
            })}
          />
        )}
        {limitsTreeTargets.includes(type) &&
          !(
            item.type === 'continents' ||
            item.type === 'countries' ||
            item.geoname_id === -1
          ) && (
            <ItemActions
              className={css.actions}
              onRemove={null}
              limitsClickHandler={limitsClickHandler}
              currentItemKey={item.geoname_id}
              currentItemTitle={currentItemName}
              disabled={disabled}
            />
          )}
      </div>
    </div>
  );
};

export default Item;
