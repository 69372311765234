import React from 'react';
import { FiX } from 'react-icons/fi';
import { components } from 'react-select';

export const ClearIndicator: typeof components['ClearIndicator'] = ({
  selectProps,
  ...props
}) => (
  <components.ClearIndicator selectProps={selectProps} {...props}>
    <FiX
      size={18}
      style={{
        width: '12px',
        marginRight: '4px',
      }}
    />
  </components.ClearIndicator>
);
