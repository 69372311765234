import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TFieldStatistics } from 'domains/campaign/types';
import { CellBid } from 'domains/target/item/CellBid';
import { RGetSettings, TCellTypes, TColumns, TargetKey, Tdata } from '../types';
import { arTargetTree } from '../shared';

type UseCreateColumnsArgs = {
  settings: RGetSettings | null;
  isLoading: boolean;
  isLimitsView: boolean;
  isEmpty: boolean;
  isSourceLike: boolean;
  isUnlockBidEdit: boolean;
  type: TargetKey;
  use_inherit_settingsState: boolean;
  statisticsIsGroup: boolean;
  onChangeValHandler: (
    k: string,
    e: React.ChangeEvent<HTMLInputElement>,
    valType: TCellTypes,
  ) => void;
  updateItem?: (id: string, val: string, name: string) => void;
  isLocked?: boolean;
};

type UseCreateColumnsReturn = {
  columns: TColumns;
  keysFields: TFieldStatistics[];
};

type TUseCreateColumns = (args: UseCreateColumnsArgs) => UseCreateColumnsReturn;

type TLimitsFields = {
  key: 'limits';
  path: string;
  title: string;
  isLengthTitle: number;
  order?: number;
}[];

export const useCreateColumns: TUseCreateColumns = ({
  isLoading,
  isSourceLike,
  isUnlockBidEdit,
  settings,
  type,
  isEmpty,
  use_inherit_settingsState,
  isLimitsView,
  statisticsIsGroup,
  onChangeValHandler,
  updateItem,
  isLocked,
}) => {
  const { t } = useTranslation();

  const limitsFields: TLimitsFields = [
    /*     {
          key: 'limits',
          path: 'limits.show_per_user.day',
          title: 'частота, день',
          isLengthTitle: 'частота, день'.length,
          order: 1,
        },
        {
          key: 'limits',
          path: 'limits.show_per_user.total',
          title: 'частота, всего',
          isLengthTitle: 'частота, всего'.length,
          order: 2,
        }, */
    {
      key: 'limits',
      path: 'limits.show.total',
      title: `${t(`campaigns_page.create_campaign.shows`)},${t(
        'campaigns_page.create_campaign.all',
      )}`,
      isLengthTitle: `${t(`campaigns_page.create_campaign.shows`)},${t(
        'campaigns_page.create_campaign.all',
      )}`.length,
    },
    {
      key: 'limits',
      path: 'limits.show.day',
      title: `${t(`campaigns_page.create_campaign.shows`)},${t(
        'campaigns_page.create_campaign.day',
      )}`,
      isLengthTitle: `${t(`campaigns_page.create_campaign.shows`)},${t(
        'campaigns_page.create_campaign.day',
      )}`.length,
    },
  ];

  const keysFields = useMemo<TFieldStatistics[]>(() => {
    const list: TFieldStatistics[] = [];
    if (settings) {
      Object.values(settings.fields).forEach(({ key, title, order }) => {
        if (key === 'show') {
          list.push({
            key,
            order,
            title: t('creative.Impressions'),
            isLengthTitle: t('creative.Impressions').length,
          });
        } else if (key === 'click') {
          list.push({
            key,
            order,
            title: t('creative.Clicks'),
            isLengthTitle: t('creative.Clicks').length,
          });
        } else if (key === 'spent') {
          list.push({
            key,
            order,
            title: `${t('creative.Amount_spent')}`,
            isLengthTitle: `${t('creative.Amount_spent')}`.length,
          });
        } else {
          list.push({ key, order, title, isLengthTitle: title.length });
        }
      });
    }
    return list.sort((a, b) => (Number(a?.order) > Number(b?.order) ? 1 : -1));
  }, [settings]);

  const createCellProps = (row: Tdata, isCellKey: TCellTypes) => ({
    row,
    isCellKey,
    isSourceLike,
    statisticsIsGroup,
    isUnlockBidEdit,
    use_inherit_settingsState,
    onChangeValHandler,
  });

  const onChangeValHandlerGeo = (
    k: string,
    e: React.ChangeEvent<HTMLInputElement>,
    valType: TCellTypes,
  ) => {
    const val = e.target.value;
    const rex = /^(\d+\.?\d{0,3}|\.\d{0,3})$/;
    if (
      (val.length && !rex.test(val)) ||
      Number(val.replace('.', ',')) >= 100000
    ) {
      return;
    }
    updateItem?.(k, val, valType);
  };

  const createCellPropsGeo = (
    row: Tdata,
    isCellKey: TCellTypes,
    isMainCurrent?: boolean,
  ) => ({
    row,
    isCellKey,
    isSourceLike,
    statisticsIsGroup,
    isUnlockBidEdit,
    use_inherit_settingsState,
    onChangeValHandler: onChangeValHandlerGeo,
    // eslint-disable-next-line
    disable: row.key == '-1' || isMainCurrent,
  });

  const columns: TColumns = useMemo(() => {
    if (isLoading) return [];
    let col: TColumns = [];

    keysFields.map(({ title: titleKey, key }) => {
      col = [
        ...col,
        {
          title: titleKey,
          dataIndex: key,
        },
      ];
      return col;
    });

    if (isEmpty) {
      col = [];
    }

    if (isLimitsView) {
      limitsFields.forEach(({ title: titleKey, key, path }) => {
        col.unshift({
          title: titleKey,
          dataIndex: key,
          path,
        });
      });
    }

    if (type === 'au' || type === 'au_shop' || type === 'au_shops') {
      col.unshift({
        title: t('common.targeting_key.hour'),
        dataIndex: 'hour',
        cell: (row: Tdata) => <CellBid {...createCellProps(row, 'hour')} disable={isLocked}/>,
        notSort: true,
      });
    }
    if (!arTargetTree.includes(type)) {
      col.unshift({
        title: 'BIDRATE',
        dataIndex: 'bid_rate',
        cell: (row: Tdata) => <CellBid {...createCellProps(row, 'bid_rate')} disable={isLocked}/>,
        notSort: true,
      });
    }

    if (type === 'geo_id') {
      col.unshift({
        title: 'BIDRATE',
        dataIndex: 'bid_rate',
        cell: (row: Tdata, isMainCurrent?: boolean) => (
          <CellBid {...createCellPropsGeo(row, 'bid_rate', isMainCurrent)} disable={isLocked}/>
        ),
        notSort: true,
      });
    }

    if (isSourceLike) {
      col.unshift({
        title: 'MAXBID',
        dataIndex: 'maxbid',
        cell: (row: Tdata) => <CellBid {...createCellProps(row, 'maxbid')} disable={isLocked}/>,
        notSort: true,
      });
      col.unshift({
        title: 'BID',
        dataIndex: 'bid_start',
        cell: (row: Tdata) => (
          <CellBid {...createCellProps(row, 'bid_start')} disable={isLocked}/>
        ),
        notSort: true,
      });
    }
    return col;
  }, [type, isEmpty, isLoading, use_inherit_settingsState, isLimitsView]);

  return {
    keysFields,
    columns,
  };
};
