import React, { FC, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import CalendarNavbar from 'components/UI/CalendarNavbar';
import MomentLocaleUtils from 'react-day-picker/moment';
import { AfterModifier, BeforeModifier } from 'react-day-picker';
import moment from 'moment';
import 'moment/locale/ru';
import { FieldProps } from 'formik';
import css from './styles.module.scss';
import { useUserInfo } from '../../../domains/user/model/selectors';
import ErrorText from '../ErrorText';

type DateInputProps = {
  /** заголовок инпута */
  label?: string;
  /** Иконка инпута */
  icon?: JSX.Element;
  /** ошибка */
  error?: boolean;
  /** текст ошибки */
  errorText?: string;
  link?: string;
  field: FieldProps['field'];
  form: FieldProps['form'];
  /** блокировать дни до или после */
  disabledDays: BeforeModifier | AfterModifier;
  /** скрывать поле */
  notField?: boolean;
  /** горизонтальный стиль */
  theme?: 'horizontal';
  autoSubmit?: boolean;
  isSaved?: boolean;
  isValidateField?: boolean;
};

const DateInput: FC<DateInputProps> = ({
  label,
  icon,
  error,
  errorText,
  link,
  field: { name, value },
  form: { setFieldValue, isValid, handleSubmit, validateField },
  disabledDays,
  notField,
  theme,
  autoSubmit,
  isSaved,
  isValidateField,
}) => {
  const { data: user } = useUserInfo();
  const dayPickerRef = useRef<DayPickerInput>(null);
  const inputRef = useRef<HTMLInputElement>();
  const defaultValue = useRef<string | Date>();

  const watcherFocusOut = useCallback(() => {
    if (defaultValue.current && autoSubmit) {
      setFieldValue(name, defaultValue.current);
    }
  }, [defaultValue.current]);

  const watcherEnter = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (dayPickerRef.current && isValid) {
          defaultValue.current = value;
          if (autoSubmit) {
            handleSubmit();
          }
          inputRef.current?.blur();
        }
      }
    },
    [dayPickerRef, isValid],
  );

  const setFieldValueHandler = (fieldName: string, valueDay: Date | string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFieldValue(fieldName, valueDay).then(() => {
      if (isValidateField) {
        validateField(fieldName);
      }
    });
  };

  useEffect(() => {
    if (autoSubmit && defaultValue) {
      inputRef.current?.addEventListener('focusout', watcherFocusOut);
    }
    return () => {
      if (autoSubmit && defaultValue) {
        inputRef.current?.removeEventListener('focusout', watcherFocusOut);
      }
    };
  }, [defaultValue.current]);

  useEffect(() => {
    if (theme === 'horizontal') {
      defaultValue.current = value;
      if (dayPickerRef.current) {
        inputRef.current = dayPickerRef.current.getInput() as HTMLInputElement;
      }
      if (inputRef.current) {
        inputRef.current?.addEventListener('keyup', watcherEnter);
      }
    }
    return () => {
      if (theme === 'horizontal') {
        if (inputRef.current) {
          inputRef.current?.removeEventListener('keyup', watcherEnter);
        }
      }
    };
  }, []);

  return (
    <div
      className={cn(css['form-group'], 'DateInput', {
        error,
        horizontal: theme === 'horizontal',
      })}
    >
      {label && <label htmlFor="#">{label}</label>}
      {notField ? (
        <div className={cn(css.title, { isSaved })}>
          {moment(value).format('DD/MM/YYYY')}
        </div>
      ) : (
        <div className={cn(css.body, 'DateInput_body', { isSaved })}>
          <DayPickerInput
            ref={dayPickerRef}
            format="DD/MM/YYYY"
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            placeholder="DD/MM/YYYY"
            value={value}
            dayPickerProps={{
              locale: user?.lang || 'ru',
              localeUtils: MomentLocaleUtils,
              disabledDays,
              navbarElement: <CalendarNavbar />,
            }}
            onDayChange={(val) => {
              const valueDay = val || null;
              setFieldValueHandler(name, valueDay)
            }}
          />
          {icon}
        </div>
      )}

      {!!errorText && error && <ErrorText text={errorText} />}

      {link && <div className={css['form-group__link']}>{link}</div>}
    </div>
  );
};

export default DateInput;
