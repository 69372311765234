import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from 'redux/fetchedData';

export interface SideMenuState {
  uploadDSPData: FetchedData<any>;
}

const initialState: SideMenuState = {
  uploadDSPData: genFetchedData(null),
};

const SideMenuSlice = createSlice({
  name: 'SideMenu',
  initialState,
  reducers: {
    setUploadDspData(
      state,
      action: PayloadAction<SideMenuState['uploadDSPData']>,
    ) {
      state.uploadDSPData = action.payload;
    },
  },
});

export const fetchUploadDsp = createAction('SideMenu/fetchUploadDsp');

export const { setUploadDspData } = SideMenuSlice.actions;

export const sideMenuReducer = SideMenuSlice.reducer;
