import React, { FC } from 'react';
import { FiAlertCircle, FiXCircle } from 'react-icons/fi';
import Dropdown from 'domains/creative/creatives/CreativeEdit/Moderation/Dropdown';
import css from '../styles.module.scss';
import i18n from '../../../../i18n';
import { IReturnTranslationData } from '../../hooks/useIsTranslation';
import TranslationInfoWrapper from '../TranslationInfoWrapper';

type IBroadcastData = Omit<IReturnTranslationData, 'isTranslation' | 'isCreo'>

interface Props {
  isCreo: boolean | null;
  isTranslation: boolean | null;
  broadcastData: IBroadcastData;
  getModerationChecklistIcon: (condition: boolean) => JSX.Element;
  onClickHandler: () => void;
  time: string | boolean;
  type?: 'CAMPAIGN' | 'CREATIVE'
}

const ApiTranslationOff : FC<Props> = ({
  broadcastData: {
    isOrganizationStatus,
    isAgencyStatus,
    isBalance,
    isCampaignCosts,
    isCreativeStatus,
    isModerationStatus,
    isCampaignPeriod,
    isCampaignStatus,
    isClientStatus,
    isCreativeData,
    isTargetGeo,
    isTargetSource,
  },
  isCreo,
  isTranslation,
  getModerationChecklistIcon,
  onClickHandler,
  time,
  type
}) => (
  <TranslationInfoWrapper onClickHandler={onClickHandler} type={type}>
    <Dropdown
      title={
        <div className={css.title}>
          {isTranslation ? (
            <FiAlertCircle
              size={20}
              color="#FF9C08"
              className={css.title__icon}
            />
          ) : (
            <FiXCircle size={22} color="#FF3636" className={css.title__icon} />
          )}
          <span>
            {isCreo && isTranslation && i18n.t(`translations.ad_is_run`)}
            {isCreo && !isTranslation && i18n.t(`translations.adv_not_uploaded`)}
            {!isCreo && isTranslation && i18n.t(`translations.camp_is_run`)}
            {!isCreo && !isTranslation && i18n.t(`translations.campaign_no_ready`)}
          </span>
        </div>
      }
    >
      <div className={css.not_uploaded}>
        <div className={css.date}>{time}</div>
        <div className={css.checklist}>
          {!isOrganizationStatus && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong> {i18n.t(`translations.status_paused`)}.</strong>
                <span>{i18n.t(`translations.contact_adnim_toggle`)}</span>
              </div>
            </div>
          )}
          {!isAgencyStatus && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t(`translations.agency_no_start`)}.</strong>
                <span>
                  {i18n.t(`translations.check_toggle`)}{' '}
                  {i18n.t(`translations.check_toggle_need_help`)}
                </span>
              </div>
            </div>
          )}
          {!isClientStatus && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t(`translations.client_not_start`)}.</strong>
                <span>
                  {i18n.t(`translations.check_toggle`)}{' '}
                  {i18n.t(`translations.check_toggle_need_help`)}
                </span>
              </div>
            </div>
          )}
          {!isCampaignStatus && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t(`translations.campaign_stoppen`)}.</strong>
                <span>{i18n.t(`translations.check_toggle`)}</span>
              </div>
            </div>
          )}
          {!isCreativeStatus && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t(`translations.ad_stopped`)}.</strong>
                <span>{i18n.t(`translations.check_toggle`)}</span>
              </div>
            </div>
          )}
          {!isModerationStatus && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong> {i18n.t(`translations.ad_not_pass`)}</strong>
                <span>
                  {i18n.t(`translations.check_toggle_check_settings`)}.
                </span>
              </div>
            </div>
          )}
          {!isCampaignPeriod && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t(`translations.outdated_period`)}.</strong>
                <span>
                  {i18n.t(`translations.check_date`)}
                  <a
                    target="_blank"
                    href="https://terratraf.readme.io/docs/td-general-campaigns#%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8"
                    rel="noreferrer"
                  >
                    {i18n.t(`translations.check_date_link`)}
                  </a>
                </span>
              </div>
            </div>
          )}
          {!isCampaignCosts && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong> {i18n.t(`translations.zero_target`)}.</strong>
                <span>
                  {i18n.t(`translations.check_price`)}
                  <a
                    target="_blank"
                    href="https://terratraf.readme.io/docs/td-general-campaigns#%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D0%BA%D0%B0-%D0%BA%D0%B0%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D0%B8"
                    rel="noreferrer"
                  >
                    {i18n.t(`translations.check_price_link`)}
                  </a>
                </span>
              </div>
            </div>
          )}
          {!isBalance && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t(`translations.balance_exhausted`)}.</strong>
                <span>{i18n.t(`translations.no_funds`)}.</span>
              </div>
            </div>
          )}
          {!isCreativeData && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t(`translations.error_creo`)}.</strong>
                <span>{i18n.t(`translations.contact_adnim`)}</span>
              </div>
            </div>
          )}
          {!isTargetGeo && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t(`translations.geo_target_empty`)}.</strong>
                <span>
                  {i18n.t(`translations.set_local`)}{' '}
                  <a
                    target="_blank"
                    href="https://terratraf.readme.io/docs/td-target-types#%D0%B3%D0%B5%D0%BE-%D1%81%D1%82%D1%80%D0%B0%D0%BD%D1%8B"
                    rel="noreferrer"
                  >
                    {i18n.t(`translations.set_local_link`)}
                  </a>
                </span>
              </div>
            </div>
          )}
          {!isTargetSource && (
            <div className={css.item}>
              <div className={css.icon}>
                {getModerationChecklistIcon(false)}
              </div>
              <div className={css.text}>
                <strong>{i18n.t(`translations.source_target_empty`)}</strong>
                <span>
                  {i18n.t(`translations.select_ssp`)}
                  <a
                    target="_blank"
                    href="https://terratraf.readme.io/docs/td-target-types#%D0%B8%D1%81%D1%82%D0%BE%D1%87%D0%BD%D0%B8%D0%BA%D0%B8"
                    rel="noreferrer"
                  >
                    {i18n.t(`translations.select_ssp_link`)}
                  </a>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </Dropdown>
  </TranslationInfoWrapper>
);

export default ApiTranslationOff 
