import request from '../../../lib/helpers/requestApi';
import {
  ContractItem,
  CreateContractParams,
  GetContractParams,
  getStatusResponse,
  TCampaignOrd,
  UpdateContractParams,
} from './types';

export const getContractAPI = request<ContractItem, GetContractParams>({
  method: 'contract.get',
});

export const createContractAPI = request<boolean, CreateContractParams>({
  method: 'contract.create',
});

export const updateContractAPI = request<boolean, UpdateContractParams>({
  method: 'contract.update',
});

export const sendErirAPI = request<boolean, GetContractParams>({
  method: 'contract.sendErir',
});

export const checkErirStatusAPI = request<getStatusResponse, GetContractParams>({
  method: 'contract.getStatus',
});

export const createEmptyCampaignAPI = request<boolean, { uuid: string }>({
  method: 'contract.createEmptyCampaign',
});

export const getEmptyCampaignAPI = request<TCampaignOrd, { uuid: string }>({
  method: 'contract.getEmptyCampaign',
});

