/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { FC, ReactNode, useRef } from 'react';
import cn from 'classnames';
import Portal from 'components/Portal';
import useDropDown from 'hooks/useDropDown';
import css from './styles.module.scss';
import { useTooltipPoratal } from './hooks/useTooltipPoratal';

interface ITooltipPoratalProps {
  /** Доплнительный класс */
  classname?: string;
  /** Основной элемент для наведения */
  children: ReactNode;
  /** Всплывающая подсказка */
  hint: ReactNode | string;
  /** Скрыть подсказку по условию */
  hideHint?: boolean;
  /** Белая подсказка */
  white?: boolean;
  /** Ширина подсказки */
  hintWidth?: number;
  onClick?: ((e?: React.MouseEvent) => void) | undefined;
  /* Показывать постоянно на странице */
  showConstantly?: boolean;
  /** Отображение слева от иконки */
  left?: boolean;
  /** Состояние disable */
  isDisabled?: boolean;
}

/** Tooltip вне DOM дерева. С выходом за рамки ограничений видимости */
const TooltipPoratal: FC<ITooltipPoratalProps> = ({
  classname,
  children,
  hint,
  white,
  hideHint = false,
  hintWidth,
  showConstantly = false,
  onClick,
  left,
  isDisabled,
}) => {
  const hintRef = useRef<HTMLDivElement | null>(null);
  const mainRef = useRef<HTMLDivElement | null>(null);

  const {
    toggle: toggleON,
    isOpened: _isOpen,
    toggleOff,
  } = useDropDown(hintRef, false);

  const { positionLeft, correctLeft, positionTop, correctTop } =
    useTooltipPoratal({
      _isOpen,
      hintRef,
      mainRef,
      showFirst: showConstantly,
      left,
    });

  return (
    <div
      ref={mainRef}
      className={cn(css.tooltipPoratal, 'TooltipPoratal', classname, {
        _isDisabled: isDisabled,
      })}
      onClick={onClick}
    >
      <div
        className={css.body}
        onMouseEnter={toggleON}
        onMouseLeave={toggleOff}
      >
        {children}
      </div>
      {(_isOpen || showConstantly) && !hideHint && (
        <Portal className="TooltipPoratal">
          <div
            ref={hintRef}
            className={cn(
              css.hint,
              correctLeft !== 0 ? `_isCorrectLeft-${correctLeft}` : '',
              {
                _isOpen: _isOpen || showConstantly,
                _isWhite: white,
                _isCorrectTop: correctTop,
                _isLeft: left,
              },
            )}
            style={{
              left: `${positionLeft}px`,
              top: `${positionTop - 12}px`,
              maxWidth: `${hintWidth}px`,
            }}
          >
            {hint}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default TooltipPoratal;
